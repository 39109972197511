import { fromEvent, timer, merge, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  share,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

const windowBlur$ = fromEvent(window, 'blur').pipe(
  map(() => false),
  share(),
);

function targetIsInputField(event: KeyboardEvent) {
  return (
    event.target instanceof HTMLTextAreaElement ||
    (event.target instanceof HTMLInputElement &&
      (!event.target.type ||
        event.target.type === 'text' ||
        event.target.type === 'number'))
  );
}

/**
 * Gives a stream of pressed state (boolean) for a given keyCode.
 * If window loses focus, we set the state to false, because otherwise we
 * might miss the keyup event while the window was not focused and the key
 * would stay pressed.
 */
export function keyPressed(keyCode: string) {
  const keydown$ = fromEvent<KeyboardEvent>(document, 'keydown').pipe(
    filter((event) => event.code === keyCode),
    filter((event) => !targetIsInputField(event)),
    tap((event) => event.preventDefault()),
    map(() => true),
  );

  const keyup$ = fromEvent<KeyboardEvent>(document, 'keyup').pipe(
    filter((event) => event.code === keyCode),
    map(() => false),
  );

  return merge(keydown$, keyup$, windowBlur$).pipe(
    startWith(false),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );
}

/**
 * Stream of 'void' while the key is pressed repeating at intervalMs.
 */
export function keyPressedRepeat(keyCode: string, intervalMs: number) {
  return keyPressed(keyCode).pipe(
    switchMap((pressed) =>
      pressed ? timer(0, intervalMs).pipe(map(() => true)) : of(false),
    ),
  );
}
