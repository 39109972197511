import { inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import type { User } from './user';
import { environment } from '../../environments/environment';
import { setUser } from '@sentry/angular';
import Keycloak from 'keycloak-js';

import * as v from 'valibot';

const keycloakProfile = v.object({
  username: v.optional(v.string()),
  name: v.string(),
  email: v.string(),
  picture: v.optional(v.string()),
});

@Injectable()
export class AuthService {
  private readonly keycloak = inject(Keycloak);
  private _accessToken$ = new ReplaySubject<string | undefined>(1);
  private _user$ = new ReplaySubject<User | undefined>(1);
  private readonly authUrl = environment.backendUrl + '/v1/auth';
  readonly instanceUuid = `_${Date.now()}`;

  accessToken$ = this._accessToken$.asObservable();
  user$ = this._user$.asObservable();

  constructor() {
    this.refreshAccessToken();
  }

  private refreshTokenAfterSignIn(signInPopUp: Window | null) {
    if (signInPopUp) {
      signInPopUp.addEventListener(
        'pagehide',
        () => {
          this.refreshAccessToken();
        },
        { once: true },
      );
    } else {
      this.refreshAccessToken();
    }
  }

  async signInWithKeycloak() {
    await this.keycloak.login();
    await this.refreshAccessToken();
  }

  signInWithGoogle() {
    const signInPopUp = window.open(
      this.authUrl + '/google',
      'Sign in with Google',
      'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=500,height=600',
    );
    this.refreshTokenAfterSignIn(signInPopUp);
  }

  signInWithMicrosoft() {
    const signInPopUp = window.open(
      this.authUrl + '/azure',
      'Sign in with Microsoft',
      'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=500,height=600',
    );
    this.refreshTokenAfterSignIn(signInPopUp);
  }

  signInWithSso(email: string) {
    const signInPopUp = window.open(
      this.authUrl + '/sso?email=' + email,
      'Sign in with SSO',
      'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=500,height=600',
    );
    this.refreshTokenAfterSignIn(signInPopUp);
  }

  async signIn(username: string, password: string): Promise<boolean> {
    try {
      await fetch(this.authUrl + '/login', {
        method: 'post',
        body: JSON.stringify({ username, password }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      return await this.refreshAccessToken();
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async refreshAccessToken(): Promise<boolean> {
    // Keycloak case
    if (this.keycloak.token) {
      try {
        await this.keycloak.updateToken();
        const accessToken = this.keycloak.token;
        this._accessToken$.next(accessToken);

        // Force creation of user in NodeJS backend by callback endpoint.
        // Required to get the ID created by MongoDB for new Keycloak users
        const response = await (
          await fetch(this.authUrl + '/keycloak/callback', {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
        ).json();

        // can't use validation service because it is not available in this context
        const profile = v.parse(
          keycloakProfile,
          await this.keycloak.loadUserInfo(),
        );

        this._user$.next({
          _id: response.id,
          uid: response.id,
          id: response.id + this.instanceUuid,
          username: profile.username ?? '',
          displayName: profile.name,
          pictureUrl: profile.picture,
          email: profile.email,
          roles: response.roles,
          landingPage: undefined, // TODO
          accessGroups: response.accessGroups,
          instanceUuid: this.instanceUuid,
        });

        setUser({
          email: profile.email,
          accessGroups: response.accessGroups,
          roles: response.roles,
        });

        return true;
      } catch (e) {
        console.error(e);
        this._accessToken$.next(undefined);
        this._user$.next(undefined);
        return false;
      }
    } else {
      try {
        const response = await (
          await fetch(this.authUrl + '/refresh', { credentials: 'include' })
        ).json();
        if (response.user && response.user._id) {
          this._accessToken$.next(response.accessToken);
          const { user } = response;
          this._user$.next({
            _id: user._id,
            uid: user._id,
            id: user._id + this.instanceUuid,
            instanceUuid: this.instanceUuid,
            username: user.username,
            displayName: user.displayName,
            pictureUrl: user.pictureUrl,
            email: user.email,
            roles: user.roles,
            landingPage: user.landingPage,
            accessGroups: user.accessGroups,
            session: user.session,
            clockedInAt: user.clockedInAt,
          });

          setUser({
            email: user.email,
            accessGroups: user.accessGroups,
            roles: user.roles,
          });
        } else {
          this._accessToken$.next(undefined);
          this._user$.next(undefined);
          setUser(null);
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        this._accessToken$.next(undefined);
        this._user$.next(undefined);
        return false;
      }
    }
  }

  async logout() {
    if (this.keycloak.authenticated) {
      await this.keycloak.logout();
      this.keycloak.clearToken();
    } else {
      try {
        await (
          await fetch(this.authUrl + '/logout', {
            method: 'post',
            credentials: 'include',
          })
        ).json();
      } catch (e) {
        console.log(e);
      }
    }
    await this.refreshAccessToken();
  }
}
