import { ControlState, NormalizedManualRobotControl } from './control-state';
import { takeUntil } from 'rxjs/operators';
import { DriverConditionSupervisor } from './driver-condition-supervisor';
import { fromEvent } from 'rxjs';
import { ManualRobotControl } from '@/app/core/robots-service/webrtc/types';
import { RobotCommunication } from '@/app/core/robots-service/robot-communication';

export type ControlPriority = 0 | 3 | 5 | 10 | 15;

const MAX_SPEED = 1.6;
const MAX_REVERSE_SPEED = 0.5;
const LOW_SPEED_MAX_TURN_RATE = 1;
const HIGH_SPEED_MAX_TURN_RATE = 0.5;
const REVERSE_SPEED_TURN_RATE = 0.5;

const THRESHOLD_IDLE_MS = 500;

export class RobotControlManager {
  private lastCommandPriority?: ControlPriority;

  private readonly controlState = new ControlState(
    MAX_SPEED,
    MAX_REVERSE_SPEED,
    LOW_SPEED_MAX_TURN_RATE,
    HIGH_SPEED_MAX_TURN_RATE,
    REVERSE_SPEED_TURN_RATE,
    THRESHOLD_IDLE_MS,
  );

  constructor(
    private readonly robotCommunication: RobotCommunication,
    private readonly drivingConditionControl: DriverConditionSupervisor,
  ) {
    fromEvent(window, 'blur')
      .pipe(takeUntil(this.robotCommunication.finalized$))
      .subscribe(() => {
        const lastControlCommand = this.controlState.get();
        const isMoving = !this.controlState.isStanding();
        const isControlPermitted =
          this.drivingConditionControl.isDrivingAllowed(lastControlCommand);
        if (isControlPermitted && isMoving) {
          this.clear();
        }
      });
  }

  private sendDriveCommand(controlCommand: ManualRobotControl) {
    if (
      this.drivingConditionControl.isDrivingAllowed(controlCommand) &&
      document.hasFocus()
    ) {
      this.robotCommunication.controlManually(controlCommand);
    } else {
      this.clear();
    }
  }

  private updatePriority(priority: ControlPriority) {
    const priorityIsHigher =
      this.lastCommandPriority === undefined ||
      priority > this.lastCommandPriority;
    const robotIsIdle = this.controlState.isIdle();

    if (robotIsIdle || priorityIsHigher) {
      this.lastCommandPriority = priority;
    }
  }

  moveRobot(
    normalizedCommand: NormalizedManualRobotControl,
    priority: ControlPriority,
  ) {
    this.updatePriority(priority);
    if (this.lastCommandPriority && this.lastCommandPriority > priority) {
      return;
    }
    this.controlState.setDesiredNormalizedState(normalizedCommand);
    const controlCommand = this.controlState.get();
    this.sendDriveCommand(controlCommand);
  }

  clear() {
    this.controlState.setDesiredNormalizedState({
      normalizedSpeed: 0,
      normalizedTurnRate: 0,
    });
    this.lastCommandPriority = 15;
  }
}
