import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '@/app/core/auth.service';
import { UserSessionService } from '@/app/core/user-session/user-session.service';
import { BackendService } from '@/app/core/backend.service';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-supervision-settings-dialog',
  templateUrl: './supervision-settings.component.html',
  styleUrl: './supervision-settings.component.sass',
  imports: [MatFormField, MatLabel, MatSelect, FormsModule, MatOption],
})
export class SupervisionSettingsDialogComponent {
  accessGroups: string[] = [];
  selectedAccessGroups: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<void, void>,
    private authService: AuthService,
    private userSessionService: UserSessionService,
    private backendService: BackendService,
  ) {
    this.initUserState();
  }

  async initUserState() {
    const authUser = await firstValueFrom(this.authService.user$);
    const user = await firstValueFrom(
      this.backendService.get(`/users/${authUser?.uid}`),
    );
    if (user === undefined) {
      return;
    }

    this.accessGroups = user.accessGroups;

    this.selectedAccessGroups = user.session?.selectedAccessGroups ?? [];
  }

  selectAccessGroup() {
    this.userSessionService.selectAccessGroups(this.selectedAccessGroups);
  }
}
