import { Component, Input } from '@angular/core';
import {
  CommunicationDeliveryStatus,
  CommunicationLogEntry,
  CommunicationLogEntryType,
  CommunicationMedium,
  Order,
  PhoneCallStatus,
} from '../../core/order/order';

import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-communication-log-overlay',
  templateUrl: './communication-log-overlay.component.html',
  styleUrl: './communication-log-overlay.component.sass',
  imports: [MatIcon],
})
export class CommunicationLogOverlayComponent {
  @Input() order: Order | undefined;

  getIconName(logEntry: CommunicationLogEntry) {
    if (logEntry.communicationMedium === CommunicationMedium.SMS) {
      switch (logEntry.messageDeliveryStatus) {
        case CommunicationDeliveryStatus.FAILED:
          return 'sms_failed';
        case CommunicationDeliveryStatus.SENDING:
          return 'sms';
        case CommunicationDeliveryStatus.SENT:
        case CommunicationDeliveryStatus.DELIVERED:
          return 'chat';
      }

      switch (logEntry.type) {
        case CommunicationLogEntryType.INBOUND:
          return 'comment';
        case CommunicationLogEntryType.SEND_FAILURE:
          return 'sms_failed';
        default:
          return 'chat';
      }
    }

    if (logEntry.communicationMedium === CommunicationMedium.PHONE_CALL) {
      if (logEntry.phoneCallStatus === PhoneCallStatus.FAILED) {
        return 'call_quality';
      }
      return 'call';
    }

    if (logEntry.communicationMedium === CommunicationMedium.EMAIL) {
      if (
        logEntry.messageDeliveryStatus === CommunicationDeliveryStatus.FAILED
      ) {
        return 'cancel_schedule_send';
      }
      return 'send';
    }

    return '';
  }
}
