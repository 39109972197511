import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import {
  Compartment,
  CompartmentState,
} from '../robots-service/backend/robot.dto';
import { RobotsService } from '../robots-service/robots.service';
import { RobotCommunication } from '../robots-service/robot-communication';
import { AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

export interface CompartmentsDialogData {
  robotId: string;
}

@Component({
  selector: 'app-compartments-dialog',
  templateUrl: './compartments-dialog.component.html',
  styleUrl: './compartments-dialog.component.sass',
  imports: [
    MatIcon,
    MatTooltip,
    MatSlideToggle,
    FormsModule,
    MatProgressSpinner,
    AsyncPipe,
  ],
})
export class CompartmentsDialogComponent implements OnDestroy, OnInit {
  private robotsService = inject(RobotsService);

  waitingForUpdate = false;

  private destroyed$ = new Subject<void>();

  private robotCommunication!: RobotCommunication;
  compartments$!: Observable<Compartment[]>;

  constructor(
    public dialogRef: MatDialogRef<CompartmentsDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) private data: CompartmentsDialogData,
  ) {}

  async ngOnInit(): Promise<void> {
    [this.robotCommunication] = await this.robotsService.getRobotCommunications(
      [this.data.robotId],
    );
    this.compartments$ = this.robotCommunication.robotState$.pipe(
      takeUntil(this.destroyed$),
      map((robotState) => robotState.compartments ?? []),
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(undefined);
  }

  isLocked(compartment: Compartment): boolean {
    return compartment.state === CompartmentState.CLOSED_AND_LOCKED;
  }

  setRobotCompartmentRequest(compartment: Compartment, checked: boolean) {
    if (checked === true) {
      this.robotCommunication?.closeCompartment(compartment.id);
    } else {
      this.robotCommunication?.openCompartment(compartment.id);
    }
    this.waitingForUpdate = true;
    setTimeout(() => (this.waitingForUpdate = false), 2000);
  }

  compartmentStateToIconName(compartment: Compartment) {
    if (
      compartment.state === CompartmentState.OPEN ||
      compartment.state === CompartmentState.OPEN_AND_PRIMED
    ) {
      return 'arrow_upward';
    }

    if (compartment.state === CompartmentState.CLOSED_AND_UNLOCKED) {
      return 'lock_open';
    }

    if (compartment.state === CompartmentState.CLOSED_AND_LOCKED) {
      return 'lock';
    }
    return 'no_encryption';
  }
}
