<section class="leaflet-map-container">
  <main
    (dblclick)="emitDoubleClick($event)"
    (mousedown)="emitPanning()"
    (scroll)="emitPanning()"
    class="leaflet-map-canvas"
    #mapCanvas
  ></main>
  @if (shouldShowMapProviderSelection()) {
    <aside class="leaflet-map-providers">
      <mat-form-field appearance="fill" class="leaflet-map-select">
        <mat-select
          [value]="currentProvider"
          (selectionChange)="switchProvider($event)"
        >
          @for (provider of mapProviders; track provider) {
            <mat-option [value]="provider">
              {{ provider }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </aside>
  }
</section>
