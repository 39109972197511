<div class="schedule-dialog">
  <div class="schedule-dialog-content">
    <h1 mat-dialog-title>Schedule Collection</h1>
    <div class="schedule-items-list">
      @for (item of schedules(); let i = $index; track item.name) {
        <div class="schedule-item">
          @if (i === editIndex()) {
            <form (submit)="finishEditing()">
              <mat-form-field>
                <input
                  type="text"
                  [value]="item.name"
                  (change)="inputChange($event)"
                  (blur)="finishEditing()"
                  autofocus
                  matInput
                />
              </mat-form-field>
            </form>
          } @else {
            <span class="schedule-title" (click)="editSchedule(i)"
              >{{ item.name }}
              @if (item.id) {
                <span class="schedule-id">id: {{ item.id }}</span>
              }
            </span>
          }
          <div class="schedule-actions">
            <button mat-fab (click)="editScheduleTimetable(i)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-fab (click)="removeSchedule(i)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      }
      <div class="add-button-row">
        <button mat-fab (click)="appendSchedule()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="schedule-dialog-actions">
    <button mat-button (click)="closeDialog()">Cancel</button>
    <button mat-flat-button (click)="save()">Save</button>
  </div>
</div>
