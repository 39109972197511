import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { GlobalPose } from '@/app/core/robots-service/webrtc/types';
import { BackendService } from '@/app/core/backend.service';
import { MapOverlay } from '../common/map-overlay';
import { LatLng } from 'spherical-geometry-js';
import { RoutesService } from '@/app/core/route-service';
import { RouteDto } from '@/app/core/robots-service/backend/types';
import { MapComponent } from '@/app/core/map/map.component';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { MatButton } from '@angular/material/button';
import { ValidationService } from '@/app/core/validation.service';

function parseLatLonString(latLonString: string): LatLng | undefined {
  const latLon = latLonString.split(/, ?/);
  if (latLon.length !== 2) {
    return undefined;
  }
  // override check for undefined, since array length is checked
  const lat = parseFloat(latLon[0]!);
  const lon = parseFloat(latLon[1]!);
  if (isNaN(lat) || isNaN(lon)) {
    return undefined;
  }
  return new LatLng(lat, lon);
}

@Component({
  selector: 'app-directions',
  templateUrl: './directions.component.html',
  styleUrl: './directions.component.sass',
  imports: [MapComponent, MatFormField, MatInput, MatButton],
})
export class DirectionsComponent implements AfterViewInit {
  @ViewChild('fromLocation') fromLocationElement!: ElementRef;
  @ViewChild('toLocation') toLocationElement!: ElementRef;
  @Output()
  newDirections = new EventEmitter<RouteDto | undefined>();

  @Input() currentRoute?: RouteDto;
  @Input() currentLocation?: GlobalPose;
  @Input() currentGoal?: GlobalPose;
  private map!: google.maps.Map;
  private mapOverlay?: MapOverlay;
  private fromLocationMarker = new google.maps.Marker({
    label: 'A',
    draggable: true,
  });
  private toLocationMarker = new google.maps.Marker({
    label: 'B',
    draggable: true,
  });
  routeInfo?: string;

  readonly mapOptions: google.maps.MapOptions = {
    center: new google.maps.LatLng(0, 0),
    zoom: 1,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    fullscreenControl: false,
    gestureHandling: 'greedy',
  };

  constructor(
    private snackBar: MatSnackBar,
    private backendService: BackendService,
    private routesService: RoutesService,
    private validationService: ValidationService,
  ) {}

  ngAfterViewInit() {
    const options = {
      // bounds: defaultBounds,
      // types: ['address']
    };

    const fromAutocomplete = new google.maps.places.Autocomplete(
      this.fromLocationElement.nativeElement,
      options,
    );
    const toAutocomplete = new google.maps.places.Autocomplete(
      this.toLocationElement.nativeElement,
      options,
    );
    this.fromLocationMarker.addListener('dragend', () => {
      const position = this.fromLocationMarker.getPosition();

      if (position) {
        this.fromLocationElement.nativeElement.value = `${position.lat()}, ${position.lng()}`;
        this.computeRoute();
      }
    });

    this.toLocationMarker.addListener('dragend', () => {
      const position = this.toLocationMarker.getPosition();

      if (position) {
        this.toLocationElement.nativeElement.value = `${position.lat()}, ${position.lng()}`;
        this.computeRoute();
      }
    });
    fromAutocomplete.addListener('place_changed', () => {
      this.fromLocationChanged(fromAutocomplete.getPlace());
    });
    toAutocomplete.addListener('place_changed', () => {
      this.toLocationChanged(toAutocomplete.getPlace());
    });
  }

  onMap(map: google.maps.Map) {
    this.mapOverlay = new MapOverlay(
      map,
      this.backendService,
      true /*showMapLayer*/,
      this.validationService,
    );
    this.map = map;
    this.fromLocationMarker.setMap(this.map);
    this.toLocationMarker.setMap(this.map);
    if (this.currentLocation) {
      this.mapOverlay.setRobotGlobalPose(this.currentLocation);
      this.map.setZoom(15);
      this.fromLocationChanged({ name: '' });
    }

    if (!this.currentRoute || !this.currentRoute.geometry?.length) {
      return;
    }

    this.mapOverlay.setRoute(this.currentRoute);
    if (this.currentRoute.geometry.length >= 2) {
      const from = this.currentRoute.geometry[0];
      const to =
        this.currentRoute.geometry[this.currentRoute.geometry.length - 1];

      if (from === undefined || to === undefined) {
        console.error('Route geometry is wrong', from, to);
        return;
      }

      this.fromLocationMarker.setPosition({
        lat: from.latitude,
        lng: from.longitude,
      });
      this.fromLocationElement.nativeElement.value = `${from.latitude}, ${from.longitude}`;
      this.fromLocationMarker.setVisible(true);
      this.toLocationMarker.setPosition({
        lat: to.latitude,
        lng: to.longitude,
      });
      this.toLocationElement.nativeElement.value = `${to.latitude}, ${to.longitude}`;
      this.toLocationMarker.setVisible(true);
    }
    this.mapOverlay.fitBoundsToRoute();
  }

  private fromLocationChanged(place: google.maps.places.PlaceResult) {
    if (!this.map) {
      return;
    }
    this.fromLocationMarker.setVisible(false);
    let location = place.geometry?.location;
    if (!location) {
      if (
        this.fromLocationElement.nativeElement.value === '' &&
        this.currentLocation
      ) {
        this.fromLocationElement.nativeElement.value = `${this.currentLocation.latitude}, ${this.currentLocation.longitude}`;
      }
      const latLon = parseLatLonString(
        this.fromLocationElement.nativeElement.value,
      );
      if (latLon) {
        location = new google.maps.LatLng(latLon.lat(), latLon.lng());
      } else {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        console.warn(`No details available for input: '${place.name}'`);
        return;
      }
    }

    this.fromLocationMarker.setPosition(location);
    this.fromLocationMarker.setVisible(true);
    if (this.toLocationMarker.getVisible()) {
      this.computeRoute();
    } else {
      this.map.setCenter(location);
      this.map.setZoom(17); // Why 17? Because it looks good.
    }
  }

  private toLocationChanged(place: google.maps.places.PlaceResult) {
    if (!this.map) {
      return;
    }
    let location: google.maps.LatLng | undefined = place.geometry?.location;
    this.toLocationMarker.setVisible(false);
    if (!location) {
      const latLon = parseLatLonString(
        this.toLocationElement.nativeElement.value,
      );
      if (latLon) {
        location = new google.maps.LatLng(latLon.lat(), latLon.lng());
      } else {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        console.warn(`No details available for input: '${place.name}'`);
        return;
      }
    }

    this.toLocationMarker.setPosition(location);
    this.toLocationMarker.setVisible(true);
    if (this.fromLocationMarker.getVisible()) {
      this.computeRoute();
    } else {
      this.map.setCenter(location);
      this.map.setZoom(17); // Why 17? Because it looks good.
    }
  }

  onGlobalPoseAsStart() {
    if (this.currentLocation === undefined) {
      return;
    }
    const currentLocation = new google.maps.LatLng(
      this.currentLocation.latitude,
      this.currentLocation.longitude,
    );
    this.fromLocationMarker.setPosition(currentLocation);
    this.fromLocationMarker.setVisible(true);
    if (this.toLocationMarker.getVisible()) {
      this.computeRoute();
    } else {
      this.map.setCenter(currentLocation);
      this.map.setZoom(16);
    }
  }

  computeRoute() {
    if (
      !this.fromLocationMarker.getVisible() ||
      !this.toLocationMarker.getVisible()
    ) {
      return;
    }
    const from = this.fromLocationMarker.getPosition();
    const to = this.toLocationMarker.getPosition();
    if (!this.map || !from || !to) {
      return;
    }
    this.routesService
      .getRoute([from.lng(), from.lat()], [to.lng(), to.lat()])
      .subscribe((routeDto) => {
        if (this.mapOverlay) {
          this.mapOverlay.setRoute(routeDto);
          this.mapOverlay.fitBoundsToRoute();
        }
        if (!routeDto) {
          this.snackBar.open(`Could not find route`, undefined, {
            duration: 3000,
          });
          this.routeInfo = 'Routing failed';
        } else {
          let distance = (routeDto.distance / 1000).toFixed(1) + 'km';
          if (distance === '0.0km') {
            distance = routeDto.distance.toFixed(0) + 'm';
          }
          let duration = (routeDto.duration / 60).toFixed(0) + 'min';
          if (duration === '0min') {
            duration = routeDto.duration.toFixed(0) + 'sec';
          }
          this.routeInfo = `Distance: ${distance}, Duration: ${duration}`;
        }
        this.newDirections.emit(routeDto);
      });
  }
}
