import { BehaviorSubject, Observable } from 'rxjs';
import { RobotConnectionStats } from './types';
import { VideoStream } from './video-stream';
import { Finalizable } from '@/utils/finalizable';
import { completeAll } from '@/utils/complete-all';
import { takeUntil } from 'rxjs/operators';

export class RtcStats extends Finalizable {
  private readonly _stats$ = new BehaviorSubject<RobotConnectionStats>(
    new RobotConnectionStats(),
  );
  readonly stats$ = this._stats$.asObservable();

  constructor(
    private videoStream: VideoStream,
    stats$: Observable<RTCStatsReport>,
  ) {
    super();
    stats$
      .pipe(takeUntil(this.finalized$))
      .subscribe((stat) => this.updateStats(stat));
  }

  private updateStats(statsReport: RTCStatsReport) {
    let selectedCandidatePairId = '';
    statsReport.forEach((statsEntry: RTCStats) => {
      const trcTransportStatsEntry = statsEntry as RTCTransportStats;
      if (
        statsEntry.type === 'transport' &&
        trcTransportStatsEntry.selectedCandidatePairId
      ) {
        selectedCandidatePairId =
          trcTransportStatsEntry.selectedCandidatePairId;
      }
    });

    let selectedIceCandidatePairStats: RTCIceCandidatePairStats | undefined;
    statsReport.forEach((statsEntry: RTCStats) => {
      const statsEntryAsAny = statsEntry as any; // typings are not up-to-date :(
      if (
        statsEntryAsAny.id === selectedCandidatePairId ||
        (statsEntryAsAny.type === 'candidate-pair' &&
          statsEntryAsAny.selected === true)
      ) {
        selectedIceCandidatePairStats = statsEntryAsAny;
      }
    });

    let inboundRtpStats: any; // typings are not up-to-date :(
    statsReport.forEach((statsEntry: RTCStats) => {
      const statsEntryAsAny = statsEntry as any; // typings are not up-to-date :(
      if (
        statsEntryAsAny.type === 'inbound-rtp' &&
        statsEntryAsAny.kind === 'video'
      ) {
        inboundRtpStats = statsEntryAsAny;
      }
    });
    const stats = new RobotConnectionStats();
    const lastStats = this._stats$.value;

    let deltaTime = 1;
    if (inboundRtpStats) {
      stats.timestampMs = inboundRtpStats.timestamp;
      if (lastStats.timestampMs) {
        deltaTime = (stats.timestampMs - lastStats.timestampMs) / 1000;
      }
      stats.totalFrames = inboundRtpStats.framesDecoded;
      stats.totalReceivedVideoBytes = inboundRtpStats.bytesReceived;
      stats.fps = (stats.totalFrames - lastStats.totalFrames) / deltaTime;
      stats.receivedVideoBitsPerSecond =
        ((stats.totalReceivedVideoBytes - lastStats.totalReceivedVideoBytes) *
          8) /
        deltaTime;
    }

    if (selectedIceCandidatePairStats) {
      stats.totalReceivedBytes =
        selectedIceCandidatePairStats.bytesReceived ?? 0;
      stats.totalSentBytes = selectedIceCandidatePairStats.bytesSent ?? 0;
      stats.receivedBitsPerSecond =
        ((stats.totalReceivedBytes - lastStats.totalReceivedBytes) * 8) /
        deltaTime;
      stats.sentBitsPerSecond =
        ((stats.totalSentBytes - lastStats.totalSentBytes) * 8) / deltaTime;
    }

    if (this.videoStream.videoElement) {
      stats.frameWidth = this.videoStream.videoElement.videoWidth;
      stats.frameHeight = this.videoStream.videoElement.videoHeight;
    }
    const videoConfig = this.videoStream.getConfiguration();
    stats.requestedFps = videoConfig.fps;
    stats.requestedMaxBitrate = videoConfig.maxBitrate;
    stats.requestedMaxPixelCount = videoConfig.maxPixelCount;
    this._stats$.next(stats);

    this.checkConnectionQuality(stats);
  }

  private badConnectionCount = 0;

  private checkConnectionQuality(stats: RobotConnectionStats) {
    if (stats.fps < stats.requestedFps / 2) {
      ++this.badConnectionCount;
    } else {
      this.badConnectionCount = 0;
    }
    if (this.badConnectionCount > 3) {
      this.badConnectionCount = 0;
      // FIXME
    }
  }

  protected async onFinalize(): Promise<void> {
    completeAll(this._stats$);
  }
}
