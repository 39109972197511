import { environment } from './environments/environment';

/** Gets the build hash from `<script src="main.<hash>.js">` tag. */
export function getBuildHash() {
  if (!environment.production) {
    return 'dev';
  }
  const mainScript = document.querySelector<HTMLScriptElement>(
    'script[src^="main"]',
  );
  const match = mainScript?.src.match(/main-(?<hash>.*)\.js$/);
  if (!match) {
    return undefined;
  }
  const [_, hash] = match;
  return hash;
}
