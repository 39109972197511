<mat-tree
  [dataSource]="treeDataSource"
  [treeControl]="treeControl"
  class="trigger-service-config-tree"
>
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <li class="mat-tree-node">
      <button mat-icon-button disabled></button>

      @if (node.type === 'number') {
        <mat-form-field appearance="fill">
          <mat-label>{{ node.name }}</mat-label>
          <input
            matInput
            type="number"
            (input)="onChangeNumberField($any($event.target).value, node)"
            value="{{ node.val }}"
          />
        </mat-form-field>
      }

      @if (node.type === 'string') {
        <mat-form-field appearance="fill">
          <mat-label>{{ node.name }}</mat-label>
          <input
            matInput
            type="string"
            (input)="onChangeStringField($any($event.target).value, node)"
            value="{{ node.val }}"
          />
        </mat-form-field>
      }

      @if (node.type === 'boolean') {
        <mat-slide-toggle
          style="margin-left: 16px"
          color="warn"
          [checked]="node.val"
          (change)="onChangeBooleanField($event.checked, node)"
          >{{ node.name }}</mat-slide-toggle
        >
      }

      @if (node.type === 'display_only') {
        <p>{{ node.name }} : {{ stringify(node.val) }}</p>
      }
    </li>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <li>
      <div class="mat-tree-node">
        <button
          mat-icon-button
          matTreeNodeToggle
          (click)="toggleExpansion(node)"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        {{ node.name }}
      </div>
      <ul
        [class.trigger-service-config-tree-invisible]="
          !treeControl.isExpanded(node)
        "
      >
        <ng-container matTreeNodeOutlet />
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
