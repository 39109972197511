<div>
  <app-map
    class="custom-gps-map"
    [mapOptions]="mapOptions"
    (mapReady)="onMap($event)"
  />
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button
    mat-button
    [mat-dialog-close]="locationToSend"
    [disabled]="!locationToSend"
    cdkFocusInitial
  >
    Send Location
  </button>
</div>
