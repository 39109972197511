@let turnIndicatorState_ = turnIndicatorState()?.state;
@let tooltipMessage =
  active() ? undefined : 'You need to be in control to use turn signals.';

@if (hazardLightsActive()) {
  <button class="turn-signal left hazard-lights-active"></button>
  <button class="turn-signal right hazard-lights-active"></button>
} @else if (turnIndicatorState_) {
  <button
    class="turn-signal left"
    [class.active]="turnIndicatorState_ === left()"
    [class.desired]="desiredTurnDirection() === left()"
    [class.operator-initiated]="operatorInitiated()"
    [disabled]="!active()"
    (click)="emitDirection(left())"
    [matTooltip]="tooltipMessage"
  ></button>
  <button
    class="turn-signal right"
    [class.active]="turnIndicatorState_ === right()"
    [class.desired]="desiredTurnDirection() === right()"
    [class.operator-initiated]="operatorInitiated()"
    [disabled]="!active()"
    (click)="emitDirection(right())"
    [matTooltip]="tooltipMessage"
  ></button>
}
