import { inject, Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { AuthService } from './auth.service';
import { lastValueFrom } from 'rxjs';

function getTrace(error?: Error) {
  if (error) {
    return error.stack?.split('\n').slice(1).join('\n');
  }
  const dummyError = new Error('');
  return dummyError.stack?.split('\n').slice(2).join('\n');
}

@Injectable({
  providedIn: 'root',
})
export class ErrorReportingService {
  backendService = inject(BackendService);
  authService = inject(AuthService);

  logError(
    message: string,
    payload: Record<string, string | number> = {},
    error?: Error,
  ) {
    const trace = getTrace(error);

    lastValueFrom(
      this.backendService.post('/frontend-error', {
        message: message,
        stackTrace: trace ?? '',
        payload: payload,
        errorMessage: error?.message,
      }),
    ).catch((err) => {
      console.error('Error reporting failed', err);
    });
    console.error(message, payload, error);
  }
}
