import { sum } from 'ramda';

const { max, PI } = Math;

// Copied from @deck.gl/layers/dist/icon-layer/icon-manager
type IconDef = {
  /** Width of the icon */
  width: number;
  /** Height of the icon */
  height: number;
  /** Horizontal position of icon anchor. Default: half width. */
  anchorX?: number;
  /** Vertical position of icon anchor. Default: half height. */
  anchorY?: number;
  /**
   * Whether the icon is treated as a transparency mask.
   * If `true`, color defined by `getColor` is applied.
   * If `false`, pixel color from the icon image is applied.
   * @default false
   */
  mask?: boolean;
};

type PrepackedIcon = {
  /** Left position of the icon on the atlas */
  x: number;
  /** Top position of the icon on the atlas */
  y: number;
} & IconDef;

export type IconMapping = Record<string, PrepackedIcon>;

type Icon = {
  mapping: IconMapping[string];
  draw(ctx: OffscreenCanvasRenderingContext2D): void;
};

const chevron = (): Icon => {
  const width = 108;
  const height = 64;
  return {
    mapping: {
      x: 0,
      y: 0,
      width,
      height,
      anchorX: width / 2,
      anchorY: height / 3,
      mask: true,
    },
    draw(ctx) {
      const lineWidth = 10;
      ctx.save();
      // upward arrow, ^
      ctx.beginPath();
      ctx.moveTo(lineWidth, height - lineWidth);
      ctx.lineTo(width / 2, lineWidth);
      ctx.lineTo(width - lineWidth, height - lineWidth);
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = 'white';
      ctx.stroke();
      ctx.restore();
    },
  };
};

const forbiddenDirection = (): Icon => {
  const R = 32;
  return {
    mapping: {
      x: 0,
      y: 0,
      width: 2 * R,
      height: 2 * R,
      anchorX: R,
      anchorY: R,
      mask: false,
    },
    draw(ctx) {
      const inset = 10;
      const lineWidth = 10;
      ctx.save();
      ctx.beginPath();
      ctx.ellipse(R, R, R, R, 0, 0, 2 * PI);
      ctx.fillStyle = 'red';
      ctx.fill();
      ctx.beginPath();
      ctx.moveTo(inset, R);
      ctx.lineTo(2 * R - inset, R);
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = 'white';
      ctx.stroke();
      ctx.restore();
    },
  };
};

const icons = {
  chevron: chevron(),
  forbiddenDirection: forbiddenDirection(),
} satisfies Record<string, Icon>;

export function createIconAtlas() {
  const iconsList = Object.values(icons);
  const height = max(...iconsList.map((i) => i.mapping.height));
  const width = sum(iconsList.map((i) => i.mapping.width));
  const canvas = new OffscreenCanvas(width, height);
  const ctx = canvas.getContext('2d')!;
  const iconMapping: IconMapping = {};
  let startX = 0;
  for (const [iconName, icon] of Object.entries(icons)) {
    ctx.translate(startX, 0);
    icon.draw(ctx);
    iconMapping[iconName] = {
      ...icon.mapping,
      x: startX,
    };
    startX += icon.mapping.width;
  }
  return {
    iconAtlas: canvas.transferToImageBitmap(),
    iconMapping,
  };
}
