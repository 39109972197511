import {
  TurnIndicatorState,
  TurnIndicatorDirection,
} from '@/app/core/robots-service/webrtc/types';
import { keyboardShortcuts } from '@/utils/keyboard-shortcuts';
import { Component, computed, input, output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-lights-dash',
  templateUrl: './lights-dash.component.html',
  styleUrl: './lights-dash.component.sass',
  imports: [MatTooltip],
})
export class LightsDashComponent {
  active = input.required<boolean>();
  hazardLightsActive = input.required<boolean>();
  turnIndicatorState = input.required<TurnIndicatorState | undefined>();
  desiredTurnDirection = input.required<TurnIndicatorDirection>();
  desiredTurnDirectionChange = output<TurnIndicatorDirection>();

  // flips button placement
  isReversing = input.required<boolean>();
  left = () => (this.isReversing() ? 'right' : 'left');
  right = () => (this.isReversing() ? 'left' : 'right');

  readonly operatorInitiated = computed(
    () =>
      this.desiredTurnDirection() !== 'off' ||
      this.turnIndicatorState()?.source === 'operator',
  );

  constructor() {
    keyboardShortcuts({
      ArrowLeft: {
        down: (ev) => {
          const handled = this.emitDirection(this.left());
          if (handled) {
            ev.stopImmediatePropagation();
            ev.preventDefault();
          }
        },
      },
      ArrowRight: {
        down: (ev) => {
          const handled = this.emitDirection(this.right());
          if (handled) {
            ev.stopImmediatePropagation();
            ev.preventDefault();
          }
        },
      },
    });
  }

  emitDirection(direction: TurnIndicatorDirection) {
    if (!this.active()) {
      return false;
    }
    if (this.desiredTurnDirection() === direction) {
      this.desiredTurnDirectionChange.emit('off');
    } else {
      this.desiredTurnDirectionChange.emit(direction);
    }
    return true;
  }
}
