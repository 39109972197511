import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataItem } from '../../data-viewer/data-viewer.service';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { AddFramesToCollectionComponent } from './add-frames-to-collection.component';
import { AddFrameMetadataComponent } from './add-frame-metadata.component';

export interface MassActionFrameDialogData {
  frames: DataItem[];
}

@Component({
  templateUrl: './mass-action-frame-dialog.component.html',
  styleUrl: './mass-action-frame-dialog.component.sass',
  imports: [
    MatTabGroup,
    MatTab,
    AddFramesToCollectionComponent,
    AddFrameMetadataComponent,
  ],
})
export class MassActionFrameDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<MassActionFrameDialogData, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: MassActionFrameDialogData,
  ) {}
}
