import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TackingViewStateService } from '../tracking-view/tracking-view-state.service';
import Leaflet from 'leaflet';
import { computeDistanceBetween } from 'spherical-geometry-js';
import { LeafletMapComponent } from '../../core/leaflet-map/leaflet-map.component';
import { MatButton } from '@angular/material/button';

const POSITION_ICON = Leaflet.icon({
  iconUrl: 'assets/flag.svg',
  iconSize: [40, 40],
  iconAnchor: [9, 36],
});

export interface RefinementDialogData {
  trackingCode: string;
  originalLatitude: number;
  originalLongitude: number;
  refinementRadius: number;
  latitude: number;
  longitude: number;
}

@Component({
  selector: 'app-refinement-dialog',
  templateUrl: './refinement-dialog.component.html',
  styleUrl: './refinement-dialog.component.sass',
  imports: [LeafletMapComponent, MatButton],
})
export class RefinementDialogComponent implements OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<RefinementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RefinementDialogData,
    private trackingViewStateService: TackingViewStateService,
  ) {}

  private mapCanvas?: Leaflet.Map;

  private flagMarker?: Leaflet.Marker;
  private refinementAreaBoundary?: Leaflet.Circle;

  desiredLocation?: Leaflet.LatLng;

  onConfirmClick() {
    this.trackingViewStateService.confirmOrderDropoff(this.data.trackingCode);
    this.dialogRef.close(this.desiredLocation);
  }

  onMap(map: Leaflet.Map) {
    this.mapCanvas = map;
    const originalPositions = {
      lat: this.data.originalLatitude,
      lng: this.data.originalLongitude,
    };
    const currentPositionTuple: Leaflet.LatLngExpression = [
      this.data.latitude,
      this.data.longitude,
    ];
    const originalPositionTuple: Leaflet.LatLngExpression = [
      this.data.originalLatitude,
      this.data.originalLongitude,
    ];

    const flagMarker = Leaflet.marker(currentPositionTuple, {
      icon: POSITION_ICON,
      zIndexOffset: 50,
      rotation: 0,
      draggable: true,
    });
    this.flagMarker = flagMarker;

    this.refinementAreaBoundary = Leaflet.circle(originalPositionTuple, {
      radius: this.data.refinementRadius,
      color: '#51429f',
      stroke: true,
      fill: false,
      weight: 10,
    });

    this.mapCanvas?.addLayer(this.refinementAreaBoundary);
    this.mapCanvas?.addLayer(flagMarker);

    map.setView([this.data.originalLatitude, this.data.originalLongitude], 16);

    flagMarker.on('dragend', () => {
      const position = flagMarker.getLatLng();
      const distanceFromOrigin = computeDistanceBetween(
        originalPositions,
        position,
      );
      if (distanceFromOrigin > this.data.refinementRadius) {
        this.flagMarker?.setLatLng(
          this.desiredLocation ?? originalPositionTuple,
        );
        return;
      }

      this.desiredLocation = position;
    });
  }

  ngOnDestroy(): void {
    this.flagMarker?.remove();
    this.refinementAreaBoundary?.remove();
  }
}
