<app-toolbar> </app-toolbar>
<div class="container">
  @if (googleMap !== undefined) {
    <mat-tab-group class="sidebar">
      <mat-tab label="Recordings">
        <ng-template matTabContent>
          <app-map-recordings
            class="recordings-list"
            (showTrajectory)="showGeometry($event)"
          />
        </ng-template>
      </mat-tab>
      <mat-tab label="Robots">
        <ng-template matTabContent>
          <app-mapping-robots
            class="recordings-list"
            (showTrajectory)="showGeometry($event)"
            (setOverlay)="showOverlay($event)"
          />
        </ng-template>
      </mat-tab>
      <mat-tab label="Automation">
        <ng-template matTabContent>
          <app-mapping-automation
            class="recordings-list"
            (showGeometry)="showGeometry($event)"
          />
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  }
  <mat-divider vertical />
  <app-map [mapOptions]="googleMapOptions" (mapReady)="onGoogleMap($event)" />
</div>
