<div class="error-message">{{ errorMessage }}</div>
<div class="filter-selector" [class.show-error]="errorMessage">
  @for (token of tokens; track token) {
    <div class="token-item">
      {{ token }}
    </div>
  }
  <input
    class="filter-input"
    type="text"
    [placeholder]="placeholder"
    aria-label="Number"
    matInput
    [matAutocomplete]="auto"
    (input)="onInput($any($event.target).value)"
    (keyup.enter)="applyInput()"
    (keyup.backspace)="removeLastToken()"
    (keyup.space)="applyInput()"
    (blur)="applyInput()"
    (click)="onInput('')"
    [value]="currentInput"
    autofocus
  />
  <mat-autocomplete
    class="filter-input"
    #auto="matAutocomplete"
    (optionSelected)="onInput($event.option.value); applyInput()"
  >
    @for (autoCompleteOption of autocompleteOptions; track autoCompleteOption) {
      <mat-option [value]="autoCompleteOption">
        {{ autoCompleteOption }}
      </mat-option>
    }
  </mat-autocomplete>
</div>
