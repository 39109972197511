import {
  Component,
  ElementRef,
  AfterViewInit,
  signal,
  effect,
  inject,
  Injector,
  runInInjectionContext,
  viewChild,
  computed,
  ViewContainerRef,
  untracked,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MapElementManager } from './map-elements/map-element-manager';
import { firstValueFrom, debounceTime, lastValueFrom } from 'rxjs';
import { MapService } from '@/app/core/map.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectOperationDialogComponent } from '@/app/core/select-operation-dialog/select-operation-dialog.component';
import {
  ElementType,
  MapChangeset,
  CreateOrReplaceMapChangeset,
  MapElement,
} from '@cartken/map-types';
import { ModeManager } from './modes/mode-manager';

import { MatIcon } from '@angular/material/icon';
import { computeCachedRoadEdges } from './map-elements/computeCachedRoadEdges';
import { OperationsService } from '@/app/core/operations-service';
import { Operation } from '@/app/operations/operation';
import { RoutesService } from '@/app/core/route-service';
import { boundsFromCoordinates } from './map-elements/bounding-box-helpers';
import {
  VisibilityDialogComponent,
  VisibilityDialogInput,
  VisibilityEntry,
} from './dialogs/visibility-dialog.component';
import { ConfirmationDialogComponent } from '@/app/core/confirmation-dialog/confirmation-dialog.component';
import { ChangeMapVersionDialogComponent } from './dialogs/change-map-version-dialog.component';
import { LoadChangesetDialogComponent } from './dialogs/load-changeset-dialog.component';
import { SaveChangesetDialogComponent } from './dialogs/save-changeset-dialog.component';
import { RebaseMode } from './modes/rebase-mode';
import { ViewLocationDialogComponent } from './dialogs/view-location-dialog.component';
import { VisualizationManager } from './visualization/visualization-manager';
import { MapStyle } from './visualization/base-map';
import { LatLngBounds } from 'spherical-geometry-js';
import { hasAtLeastOneElement } from '@/utils/typeGuards';
import { ToolbarComponent } from '@/app/core/toolbar/toolbar.component';
import { MatMenuItem } from '@angular/material/menu';
import { MatDivider } from '@angular/material/divider';
import {
  MatDrawerContainer,
  MatDrawerContent,
  MatDrawer,
} from '@angular/material/sidenav';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSuffix } from '@angular/material/form-field';
import {
  MatButtonToggleGroup,
  MatButtonToggle,
} from '@angular/material/button-toggle';
import { MatTooltip } from '@angular/material/tooltip';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSlider, MatSliderRangeThumb } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import {
  MatButton,
  MatIconButton,
  MatMiniFabButton,
} from '@angular/material/button';
import { CAsyncPipe } from '@/utils/c-async-pipe';
import * as v from 'valibot';
import { loadTextFile } from '@/utils/load-text-file';
import { saveJSONFile } from '@/utils/save-json-file';
import { boundingPolygonFromBounds } from '@/utils/geo-tools';
import { keyboardShortcuts } from '@/utils/keyboard-shortcuts';
import { asyncSignal } from '@/utils/async-signal';
import { InteractiveMode } from './visualization/interactive-mode';
import { mergeAndValidateChanges } from './map-elements/merge-and-validate-changes';
import { CanDeactivateFn } from '@angular/router';
import { ValidationService } from '../core/validation.service';

const confirmationMessages = {
  leave: 'You have unsaved changes. Are you sure you want to leave?',
  'close-changeset':
    'You have unsaved changes. Are you sure you want to close the changeset?',
};

@Component({
  selector: 'app-map-editor',
  templateUrl: './map-editor.component.html',
  styleUrl: './map-editor.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CAsyncPipe,
    FormsModule,
    MatIconButton,
    MatButton,
    MatButtonToggle,
    MatButtonToggleGroup,
    MatDivider,
    MatDrawer,
    MatDrawerContainer,
    MatDrawerContent,
    MatIcon,
    MatMenuItem,
    MatMiniFabButton,
    MatProgressSpinner,
    MatSlideToggle,
    MatSlider,
    MatSliderRangeThumb,
    MatSuffix,
    MatTooltip,
    ToolbarComponent,
  ],
})
export class MapEditorComponent implements AfterViewInit {
  private injector = inject(Injector);
  private mapService = inject(MapService);
  private operationsService = inject(OperationsService);
  private routesService = inject(RoutesService);
  private dialog = inject(MatDialog);
  private snackBar = inject(MatSnackBar);
  private validationService = inject(ValidationService);

  private mapContainerElement =
    viewChild<ElementRef<HTMLElement>>('mapContainer');
  private sidebarContent = viewChild('sidebarContent', {
    read: ViewContainerRef,
  });

  mapElementManager: MapElementManager | undefined;
  visualizationManager: VisualizationManager | undefined;
  modeManager: ModeManager | undefined;
  rebaseMode: RebaseMode | undefined;

  cachedRoadEdgeProgress = 100;
  currentChangeset = signal<MapChangeset | undefined>(undefined);

  latestMapVersion = signal<number>(0);
  deployedMapVersion = signal<number | undefined>(undefined);

  private async getMapVersions() {
    const { latestVersion, deployedVersion } =
      await this.mapService.getMapVersions();
    this.latestMapVersion.set(latestVersion ?? 0);
    this.deployedMapVersion.set(deployedVersion);
    return { latestVersion, deployedVersion };
  }

  showBaseMap = signal(false);
  minDisplayAltitude = signal(0);
  maxDisplayAltitude = signal(100);
  operations = asyncSignal(() =>
    firstValueFrom(this.operationsService.getOperations()),
  );

  mapElementVisibility = signal<VisibilityEntry[]>([
    {
      displayName: 'Cached Road Edges',
      elementTypes: [ElementType.CACHED_ROAD_EDGE],
      visible: false,
    },
  ]);

  private hiddenElementTypes = computed(
    () =>
      new Set(
        this.mapElementVisibility()
          .filter((entry) => !entry.visible)
          .map((entry) => entry.elementTypes)
          .flat(),
      ),
  );

  private bounds = signal(new LatLngBounds());

  async ngAfterViewInit() {
    const {
      bounds,
      currentChangeset,
      hiddenElementTypes,
      injector,
      mapContainerElement,
      mapService,
      maxDisplayAltitude,
      minDisplayAltitude,
      routesService,
      showBaseMap,
    } = this;
    const mapHTMLElement = mapContainerElement()?.nativeElement;
    if (!mapHTMLElement) {
      // this should not happen
      throw new Error(
        `Map container element was not ready even after ngAfterViewInit.`,
      );
    }
    await this.getMapVersions();
    runInInjectionContext(injector, () => {
      const mapElementManager = new MapElementManager(
        this.latestMapVersion,
        bounds,
        showBaseMap,
        currentChangeset,
        hiddenElementTypes,
        async (bounds, requestedMapVersion) => {
          const mapElementsList = await mapService.loadMapElements(
            boundingPolygonFromBounds(bounds),
            requestedMapVersion,
          );
          return {
            mapVersion: requestedMapVersion,
            mapElements: new Map(mapElementsList.map((el) => [el.id, el])),
            bounds,
          };
        },
      );
      const visualizationManager = new VisualizationManager(
        mapElementManager.hoveredElementId,
        mapElementManager.selectedMapElementId,
        (region) => this.navigateToOperationRegion(region),
        () => this.modeManager?.currentMode() ?? new InteractiveMode(),
        mapHTMLElement,
      );
      visualizationManager.boundsChanged$
        .pipe(debounceTime(200))
        .subscribe((newBounds) => {
          bounds.set(newBounds);
        });

      effect(
        () => {
          const { min, max } = mapElementManager.altitudeRange();
          // TODO: use linkedSignal when angular v19
          // altitudeRange changes when a basemap or changeset is loaded
          // user will probably want to see the whole thing at first.
          minDisplayAltitude.set(min);
          maxDisplayAltitude.set(max);
        },
        { allowSignalWrites: true },
      );

      effect(() => {
        visualizationManager.setOperationRegions(this.operations().value ?? []);
      });

      effect(() => {
        modeManager.currentModeId();
        mapElementManager.hoveredElementId();
        mapElementManager.selectedMapElementId();
        visualizationManager.rerenderMapElements();
      });

      effect(() => {
        visualizationManager.setMapElements(
          mapElementManager.displayedMapElements(),
        );
      });

      effect(() => {
        this.updateDisplayAltitudes();
      });

      const modeManager = new ModeManager(
        visualizationManager,
        mapElementManager,
        routesService,
      );

      keyboardShortcuts({
        KeyE: {
          down: () => modeManager.setMapEditorMode('createEdge'),
        },
        KeyS: {
          down: () => modeManager.setMapEditorMode('splitEdge'),
        },
        KeyH: {
          down: () => modeManager.setMapEditorMode('createHandoverLocation'),
        },
        KeyR: {
          down: () => modeManager.setMapEditorMode('createOperationRegion'),
        },
        KeyM: {
          down: () => modeManager.setMapEditorMode('createMutex'),
        },
        Escape: {
          down: () => modeManager.setMapEditorMode('select'),
        },
        KeyD: {
          down: () => modeManager.setMapEditorMode('delete'),
        },
        KeyZ: {
          down: (event) => {
            if (event.ctrlKey && !this.rebaseMode) {
              if (event.shiftKey) {
                mapElementManager.history.redo();
              } else {
                mapElementManager.history.undo();
              }
            }
          },
        },
        KeyB: {
          down: () => this.showBaseMap.set(true),
          up: () => this.showBaseMap.set(false),
        },
      });

      effect((onCleanup) => {
        const sidebarRef = this.sidebarContent();
        if (!sidebarRef) {
          return;
        }
        const currentMode = modeManager.currentMode();
        sidebarRef.clear();
        const componentRef = untracked(() =>
          currentMode.renderSidebar(sidebarRef, onCleanup),
        );
        onCleanup(() => {
          componentRef?.destroy();
        });
      });

      // check for rebase
      effect((onCleanup) => {
        const changeset = currentChangeset();
        if (!changeset) {
          return;
        }
        this.checkForRebase(changeset);
        const intervalId = setInterval(
          () => this.checkForRebase(changeset),
          5 * 60 * 1000,
        );
        onCleanup(() => clearInterval(intervalId));
      });

      // prevent leaving the page if there are unsaved changes
      effect((onCleanup) => {
        const unloadHandler = (event: BeforeUnloadEvent): void => {
          if (this.mapElementManager?.history.hasChanges()) {
            event.preventDefault();
          }
        };

        window.addEventListener('beforeunload', unloadHandler);
        onCleanup(() => {
          window.removeEventListener('beforeunload', unloadHandler);
        });
      });

      this.modeManager = modeManager;
      this.visualizationManager = visualizationManager;
      this.mapElementManager = mapElementManager;
    });
  }

  async checkForRebase(changeset: MapChangeset) {
    const { latestVersion } = await this.getMapVersions();
    if (latestVersion === undefined) {
      return;
    }
    if ((changeset.basedOnVersion ?? 0) < latestVersion) {
      this.snackBar
        .open('Changeset is based on an outdated map version', 'Rebase')
        .onAction()
        .subscribe(() => this.rebaseChangeset());
    }
  }

  onVisibilityClick() {
    const { visualizationManager } = this;
    if (!visualizationManager) {
      return;
    }

    const dialogRef = this.dialog.open(VisibilityDialogComponent, {
      width: '400px',
      data: {
        visibilityEntries: this.mapElementVisibility(),
        slippyTilesOpacities: visualizationManager.getLayerOpacities(),
      },
    });

    dialogRef.afterClosed().subscribe((visibility?: VisibilityDialogInput) => {
      if (!visibility) {
        return;
      }
      this.mapElementVisibility.set(visibility.visibilityEntries);
      visualizationManager.setLayerOpacities(visibility.slippyTilesOpacities);
    });
  }

  onGoogleMapsClick() {
    const { visualizationManager } = this;
    if (!visualizationManager) {
      return;
    }

    const zoom = Math.min(21, Math.round(visualizationManager.getZoom() + 1));
    const center = visualizationManager.getLatLngBounds().getCenter();
    const baseUrl = 'https://www.google.com/maps/@?api=1&map_action=map&';
    const params = `center=${center.lat()},${center.lng()}&zoom=${zoom}`;
    window.open(baseUrl + params, '_blank')?.focus();
  }

  enableTerrain(enable: boolean) {
    this.visualizationManager?.enableTerrain(enable);
  }

  enableAltitudeFlattening(enable: boolean) {
    this.visualizationManager?.enableAltitudeFlattening(enable);
  }

  updateDisplayAltitudes() {
    this.visualizationManager?.setDisplayAltitudeRange(
      this.minDisplayAltitude(),
      this.maxDisplayAltitude(),
    );
  }

  setMapStyle(mapStyle: MapStyle) {
    this.visualizationManager?.setMapStyle(mapStyle);
  }

  async computeCachedRoadEdges() {
    if (!this.mapElementManager) {
      return;
    }
    computeCachedRoadEdges(this.mapElementManager, (progress: number) => {
      this.cachedRoadEdgeProgress = progress;
    });
  }

  exportAllCurrentMapElements() {
    if (!this.mapElementManager) {
      return;
    }
    this.exportMapElements([
      ...this.mapElementManager.getMapElements().values(),
    ]);
  }

  exportChangedMapElements() {
    if (!this.mapElementManager) {
      return;
    }
    this.exportMapElements([
      ...this.mapElementManager.history.currentChanges().values(),
    ]);
  }

  exportMapElements(mapElements: MapElement[]) {
    saveJSONFile('map.json', mapElements);
  }

  async importMapElements(createNewMapElements: boolean) {
    const result = await loadTextFile();
    if (result.status === 'no-file-selected' || !this.mapElementManager) {
      return;
    }
    const mapElements = this.validationService.validate(
      v.array(MapElement),
      JSON.parse(result.text),
    );
    this.mapElementManager.importMapElements(mapElements, createNewMapElements);
  }

  async viewOperationRegion() {
    const operations = this.operations().value ?? [];
    const dialogRef = this.dialog.open(SelectOperationDialogComponent, {
      width: '40rem',
      data: { operations },
    });
    const operation: Operation = await lastValueFrom(dialogRef.afterClosed());
    this.navigateToOperationRegion(operation);
  }

  private navigateToOperationRegion(operation: Operation | undefined) {
    const bounds = boundsFromCoordinates(
      operation?.operationRegion?.coordinates[0],
    );
    if (bounds) {
      this.visualizationManager?.fitBounds(bounds);
      if (this.modeManager) {
        this.modeManager.modes.routing.limitToOperationId.set(operation?.id);
      }
    }
  }

  async viewLocation() {
    if (!this.mapElementManager) {
      return;
    }
    const nearbyQueueLocations = [
      ...this.mapElementManager.getMapElements().values(),
    ].filter(
      (el) => !el.deleted && el.elementType === ElementType.ROBOT_QUEUE_EDGE,
    );

    const latLng: google.maps.LatLng = await lastValueFrom(
      this.dialog
        .open(ViewLocationDialogComponent, {
          data: {
            currentMapVersion: this.mapElementManager.baseMapElementsVersion(),
            nearbyQueueLocations,
          },
        })
        .afterClosed(),
    );

    if (latLng) {
      const bounds = new LatLngBounds(latLng, latLng);
      this.visualizationManager?.fitBounds(bounds);
    }
  }

  async changeMapVersion() {
    const { latestVersion, deployedVersion } = await this.getMapVersions();
    if (!this.mapElementManager) {
      return;
    }
    const currentVersion = this.mapElementManager.baseMapElementsVersion();
    const changesets = await this.mapService.loadMapChangesetInfos();
    const newMapVersion = await lastValueFrom(
      this.dialog
        .open(ChangeMapVersionDialogComponent, {
          data: {
            currentVersion,
            latestVersion,
            deployedVersion,
            changesets,
          },
          minWidth: '500px',
          maxWidth: '80vw',
          maxHeight: '90vh',
        })
        .afterClosed(),
    );
    if (newMapVersion === undefined) {
      return;
    }
    this.mapElementManager.mapVersionIfNoChangeset.set(newMapVersion);
    this.snackBar.open(`Map version changed to ${newMapVersion}.`, 'OK', {
      duration: 5000,
    });
  }

  async deployCurrentMapVersion() {
    if (this.mapElementManager?.history.hasChanges()) {
      this.snackBar.open(
        `You have unsaved changes. Please save or undo them first.`,
        'OK',
      );
      return;
    }
    if (this.currentChangeset()) {
      this.snackBar.open(
        `You have a changeset open. Please commit or close the changeset first.`,
        'OK',
      );
      return;
    }
    const mapVersion = this.mapElementManager?.baseMapElementsVersion();
    if (mapVersion === undefined) {
      this.snackBar.open(
        `Map version unknown. Please try again when it is loaded.`,
        'OK',
      );
      return;
    }
    const confirmed = await lastValueFrom(
      this.dialog
        .open(ConfirmationDialogComponent, {
          data: {
            message: `Really deploy map version ${mapVersion}?`,
          },
        })
        .afterClosed(),
    );
    if (!confirmed) {
      return;
    }
    const startTime = Date.now();
    await this.mapService.deployMapVersion(mapVersion);
    this.deployedMapVersion.set(mapVersion);
    this.snackBar.open(
      `Deployed version ${mapVersion} in ${Date.now() - startTime}ms`,
      'OK',
      { duration: 10000 },
    );
  }

  async loadChangeset() {
    if (!this.mapElementManager) {
      return;
    }
    const changesets = await this.mapService.loadMapChangesetInfos();
    const changesetId = await lastValueFrom(
      this.dialog
        .open(LoadChangesetDialogComponent, {
          data: { changesets },
          minWidth: '500px',
          maxWidth: '80vw',
          maxHeight: '90vh',
          autoFocus: false,
        })
        .afterClosed(),
    );
    if (changesetId === undefined) {
      return;
    }
    const currentChangeset =
      await this.mapService.loadMapChangeset(changesetId);
    this.currentChangeset.set(currentChangeset);
  }

  async saveChangeset() {
    const { mapElementManager } = this;
    if (!mapElementManager) {
      return;
    }
    const currentChangeset = this.currentChangeset();
    const changedMapElements =
      this.mapElementManager?.allChangesVsBaseMap() ?? [];
    if (!changedMapElements || !hasAtLeastOneElement(changedMapElements)) {
      return;
    }

    const changesetInfo: { title: string; description: string } | undefined =
      await lastValueFrom(
        this.dialog
          .open(SaveChangesetDialogComponent, {
            data: {
              title: currentChangeset?.title,
              description: currentChangeset?.description,
            },
            minWidth: '500px',
            maxWidth: '80vw',
            maxHeight: '90vh',
          })
          .afterClosed(),
      );
    if (!changesetInfo) {
      return;
    }

    if (currentChangeset) {
      if (changedMapElements.length === 0) {
        return;
      }
      const updatedChangeset: CreateOrReplaceMapChangeset = {
        ...changesetInfo,
        changedMapElements,
        basedOnVersion:
          currentChangeset?.basedOnVersion ??
          this.mapElementManager?.baseMapElementsVersion(),
      };
      const changeset = await this.mapService.replaceMapChangeset(
        currentChangeset.id,
        updatedChangeset,
      );
      this.currentChangeset.set(changeset);
    } else {
      const createdChangeset: CreateOrReplaceMapChangeset = {
        ...changesetInfo,
        changedMapElements,
        basedOnVersion: this.mapElementManager?.baseMapElementsVersion(),
      };
      const changeset =
        await this.mapService.createMapChangeset(createdChangeset);
      this.currentChangeset.set(changeset);
    }
  }

  async rebaseChangeset() {
    const currentChangeset = this.currentChangeset();
    if (!currentChangeset) {
      this.snackBar.open(`No changeset is loaded.`, 'OK');
      return;
    }

    const { latestVersion } = await this.getMapVersions();
    if (!latestVersion) {
      this.snackBar.open(
        `Can't rebase because there is no latest version.`,
        'OK',
      );
      return;
    }

    const conflicts = await this.mapService.getMapChangesetConflicts(
      currentChangeset.id,
    );
    if (conflicts.length === 0) {
      const savedChangeset = await this.mapService.replaceMapChangeset(
        currentChangeset.id,
        {
          ...currentChangeset,
          basedOnVersion: latestVersion,
        },
      );
      this.currentChangeset.set(savedChangeset);
      this.snackBar.open(`Rebase finished, there were no conflicts.`, 'OK', {
        duration: 5000,
      });
      return;
    }

    if (!this.modeManager || !this.mapElementManager) {
      return;
    }

    this.modeManager.setMapEditorMode('rebase');
    this.modeManager.enableMapEditorModeChanges(false);
    this.rebaseMode = this.modeManager.modes.rebase;
    this.mapElementManager.rebaseTargetVersion.set(latestVersion);
    this.rebaseMode.setConflicts(conflicts);
  }

  exitRebaseMode() {
    if (!this.rebaseMode || !this.modeManager || !this.mapElementManager) {
      return;
    }
    // undoes what rebaseChangeset() did
    this.rebaseMode.setConflicts([]);
    this.mapElementManager.rebaseTargetVersion.set(undefined);
    this.rebaseMode = undefined;
    this.modeManager.enableMapEditorModeChanges(true);
    this.modeManager.setMapEditorMode('select');
  }

  async saveRebaseToChangesetAndExit() {
    const { mapElementManager, rebaseMode } = this;
    const changeset = this.currentChangeset();
    const rebaseTargetVersion = this.mapElementManager?.rebaseTargetVersion();
    if (
      !changeset ||
      !rebaseTargetVersion ||
      !mapElementManager ||
      !rebaseMode?.allConflictsResolved()
    ) {
      return;
    }
    const resolvedConflicts = rebaseMode.getResolvedConflicts();
    const changedMapElements = mergeAndValidateChanges(
      changeset.changedMapElements,
      resolvedConflicts,
    );
    if (!hasAtLeastOneElement(changedMapElements)) {
      // TODO: what should happen when we try to save
      // a changeset with no changes?
      return;
    }
    const rebasedChangeset = await this.mapService.replaceMapChangeset(
      changeset.id,
      {
        ...changeset,
        changedMapElements,
        basedOnVersion: rebaseTargetVersion,
      },
    );
    this.currentChangeset.set(rebasedChangeset);
    this.exitRebaseMode();
  }

  async commitChangeset() {
    const { latestVersion } = await this.getMapVersions();
    const changeset = this.currentChangeset();
    if (!changeset) {
      this.snackBar.open(`No changeset loaded.`, 'OK', { duration: 5000 });
      return;
    }
    if (changeset.basedOnVersion !== latestVersion) {
      this.snackBar.open(
        `Your changeset is built on top of outdated map (changest version: ${changeset.basedOnVersion}, latest version: ${latestVersion}). Please rebase then try again.`,
        'OK',
        { duration: 10000 },
      );
    }
    if (this.mapElementManager?.history.hasChanges()) {
      this.snackBar.open(
        `You have unsaved changes, please save them before committing.`,
        'OK',
        { duration: 5000 },
      );
      return;
    }

    const { committedAsVersion } = await this.mapService.commitMapChangeset(
      changeset.id,
    );

    if (committedAsVersion !== undefined) {
      this.latestMapVersion.set(committedAsVersion);
      this.closeChangeset();
    }
  }

  closeChangeset() {
    if (!this.confirmHasChanges('close-changeset')) {
      return;
    }
    this.mapElementManager?.mapVersionIfNoChangeset.set(undefined);
    this.currentChangeset.set(undefined);
    this.snackBar.dismiss();
  }

  async deleteChangeset() {
    const changeset = this.currentChangeset();
    if (!changeset) {
      return;
    }
    const confirmed = await lastValueFrom(
      this.dialog
        .open(ConfirmationDialogComponent, {
          data: {
            message: `Really delete current change set '${changeset.title}'?`,
          },
        })
        .afterClosed(),
    );

    if (
      !confirmed ||
      !changeset ||
      !(await this.mapService.deleteChangeset(changeset.id))
    ) {
      return;
    }

    this.currentChangeset.set(undefined);
    this.snackBar.dismiss();
  }

  startShowingBaseMap(event: PointerEvent) {
    if (event.target instanceof HTMLElement) {
      event.target.setPointerCapture(event.pointerId);
    }
    this.showBaseMap.set(true);
  }

  stopShowingBaseMap() {
    this.showBaseMap.set(false);
  }

  confirmHasChanges(action: keyof typeof confirmationMessages) {
    return this.mapElementManager?.history.hasChanges()
      ? window.confirm(confirmationMessages[action])
      : true;
  }
}

export const canDeactivateMapEditorGuard: CanDeactivateFn<
  MapEditorComponent
> = (component) => {
  return component.confirmHasChanges('leave');
};
