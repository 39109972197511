import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BackendService } from './backend.service';
import { Operation, CreateOperationData } from '../operations/operation';
import { v } from '@/valibot';
import { UpdateOperation } from '../operations/update-operation';
import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root',
})
export class OperationsService {
  constructor(
    private backendService: BackendService,
    private validationService: ValidationService,
  ) {}

  getOperations(): Observable<Operation[]> {
    return this.backendService
      .get('/operations')
      .pipe(
        map((operationsRaw) =>
          this.validationService.validate(v.array(Operation), operationsRaw),
        ),
      );
  }

  getOperationById(id: string): Observable<Operation> {
    return this.backendService
      .get(`/operations/${id}`)
      .pipe(
        map((operationRaw) =>
          this.validationService.validate(Operation, operationRaw),
        ),
      );
  }

  createOperation({ id, ...data }: CreateOperationData): Observable<Operation> {
    return this.backendService
      .put(`/operations/${id}`, data)
      .pipe(
        map((operationRaw) =>
          this.validationService.validate(Operation, operationRaw),
        ),
      );
  }

  updateOperation(id: string, update: UpdateOperation): Observable<Operation> {
    return this.backendService
      .patch(`/operations/${id}`, update)
      .pipe(
        map((operationRaw) =>
          this.validationService.validate(Operation, operationRaw),
        ),
      );
  }

  getAccessGroups(): Observable<string[]> {
    return this.backendService
      .get('/operations/access-groups')
      .pipe(
        map((accessGroupsRaw) =>
          this.validationService.validate(v.array(v.string()), accessGroupsRaw),
        ),
      );
  }
}
