import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnDestroy } from '@angular/core';
import {
  MatTreeNestedDataSource,
  MatTree,
  MatTreeNodeDef,
  MatTreeNode,
  MatTreeNodeToggle,
  MatNestedTreeNode,
  MatTreeNodeOutlet,
} from '@angular/material/tree';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RobotCommunication } from '../../../core/robots-service/robot-communication';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

export interface StatusTreeNode {
  name: string;
  message: string;
  type: string;
  subStatus?: StatusTreeNode[];
}

/**
 *  This component is adapted from one of the official Mat-Tree examples:
 *  https://stackblitz.com/angular/rlqadrarmkvb?file=app%2Ftree-nested-overview-example.ts
 */
@Component({
  selector: 'app-status-tree',
  templateUrl: './status-tree.component.html',
  styleUrl: './status-tree.component.css',
  imports: [
    MatTree,
    MatTreeNodeDef,
    MatTreeNode,
    MatTreeNodeToggle,
    MatIconButton,
    MatNestedTreeNode,
    MatIcon,
    MatTreeNodeOutlet,
  ],
})
export class StatusTreeComponent implements OnDestroy {
  @Input()
  set robotCommunication(robotCommunication: RobotCommunication | undefined) {
    this.updateRobot(robotCommunication);
  }
  get robotCommunication(): RobotCommunication | undefined {
    return this._robot;
  }
  private _robot?: RobotCommunication;
  private readonly unsubscribeRobot$ = new Subject<void>();
  private expansionMap = new Map<string, boolean>();

  treeControl = new NestedTreeControl<StatusTreeNode>((node) => node.subStatus);
  treeDataSource = new MatTreeNestedDataSource<StatusTreeNode>();

  ngOnDestroy() {
    this.updateRobot(undefined);
  }

  hasChild = (_: number, node: StatusTreeNode) => {
    return !!node.subStatus && node.subStatus.length > 0;
  };

  toggleExpansion(node: StatusTreeNode) {
    if (this.expansionMap.has(node.name)) {
      this.expansionMap.set(node.name, !this.expansionMap.get(node.name));
    } else {
      this.expansionMap.set(node.name, true);
    }
  }

  private updateRobot(robotCommunication?: RobotCommunication) {
    if (robotCommunication === this.robotCommunication) {
      return;
    }
    this.treeControl.dataNodes = [];
    this.treeDataSource.data = [];
    this.unsubscribeRobot$.next(undefined);
    this._robot = robotCommunication;
    if (!this.robotCommunication) {
      return;
    }

    this.robotCommunication.robotStatusTree$
      .pipe(takeUntil(this.unsubscribeRobot$))
      .subscribe((message: any) => {
        if (Array.isArray(message)) {
          this.treeControl.dataNodes = message;
          this.treeDataSource.data = message;
        } else {
          this.treeControl.dataNodes = [message];
          this.treeDataSource.data = [message];
        }
        for (const node of this.treeControl.dataNodes) {
          this.expandNode(node);
        }
      });
  }

  private expandNode(node: StatusTreeNode) {
    if (this.expansionMap.has(node.name) && this.expansionMap.get(node.name)) {
      this.treeControl.expand(node);
      if (node.subStatus) {
        for (const subNode of node.subStatus) {
          this.expandNode(subNode);
        }
      }
    }
  }
}
