import { BehaviorSubject, Observable } from 'rxjs';
import { NetworkInterfaceStats } from './signaling-server-messages';
import { Finalizable } from '@/utils/finalizable';
import { completeAll } from '@/utils/complete-all';

export class RtcNetworkInterfaces extends Finalizable {
  private readonly _selectedNetworkInterface$ = new BehaviorSubject<
    string | undefined
  >(undefined);
  readonly selectedNetworkInterface$ =
    this._selectedNetworkInterface$.asObservable();

  private readonly _connectedNetworkInterface$ = new BehaviorSubject<
    string | undefined
  >(undefined);
  readonly connectedNetworkInterface$ =
    this._connectedNetworkInterface$.asObservable();

  private readonly _availableNetworkInterfaces$ = new BehaviorSubject<
    NetworkInterfaceStats[]
  >([]);
  readonly availableNetworkInterfaces$ =
    this._availableNetworkInterfaces$.asObservable();

  constructor(networkInterfaceStats$: Observable<NetworkInterfaceStats[]>) {
    super();
    networkInterfaceStats$.subscribe((networkInterfaceStats) => {
      this.processNetworkInterfaces(networkInterfaceStats);
    });
  }

  private processNetworkInterfaces(networkInterfaces: NetworkInterfaceStats[]) {
    this._availableNetworkInterfaces$.next(networkInterfaces);
    const selectedNetworkInterface = networkInterfaces.find(
      (aI) => aI.isSelected,
    );
    this._selectedNetworkInterface$.next(
      selectedNetworkInterface?.interfaceName,
    );
    const connectedInterface = this._availableNetworkInterfaces$.value.find(
      (aI) => aI.isConnected,
    );
    this._connectedNetworkInterface$.next(
      connectedInterface?.interfaceDisplayName ??
        connectedInterface?.interfaceName,
    );
  }

  protected async onFinalize(): Promise<void> {
    completeAll(
      this._selectedNetworkInterface$,
      this._connectedNetworkInterface$,
      this._availableNetworkInterfaces$,
    );
  }
}
