type Named = { name: string };

function formatNewScheduleName(index: number) {
  return `New Schedule ${index}`;
}

export function nextScheduleInitialName(items: Named[]) {
  let index = 1;
  while (items.some((item) => item.name === formatNewScheduleName(index))) {
    index++;
  }
  return formatNewScheduleName(index);
}
