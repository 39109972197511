@let selectedDataViewerTab = dataViewerService.selectedDataViewerTab$ | async;
<div class="data-viewer-menu">
  <mat-form-field>
    <mat-select
      [value]="selectedDataViewerTab"
      (selectionChange)="dataViewerService.selectDataViewerTab($event.value)"
    >
      <mat-option class="tab-option" value="frames">Frames</mat-option>
      <mat-option class="tab-option" value="snippets">Snippets</mat-option>
    </mat-select>
  </mat-form-field>
  <app-filter-selector class="data-viewer-filter" />
</div>
<div class="action-area">
  <div class="pick-options">
    @if (selectedDataViewerTab === 'frames') {
      <app-pick-label
        label="pick all"
        [isPicked]="true"
        (clicked)="dataViewerService.pickAllItems()"
      />
      <app-pick-label
        label="unpick all"
        [isPicked]="false"
        (clicked)="dataViewerService.unpickAllItems()"
      />
      <button
        mat-flat-button
        color="accent"
        (click)="openMassActionFramesDialog()"
        [disabled]="(dataViewerService.isAnyItemsPicked$ | async) !== true"
      >
        Action
      </button>
    }
  </div>

  <app-sort-control
    [sortOptions]="sortingOptions"
    (sortOptionUpdate)="updateSortingOrder($event)"
  />
</div>

<mat-divider />

@if (dataViewerService.items$ | async; as items) {
  <app-data-grid
    [items]="items"
    [selectedItemId]="dataViewerService.selectedItemId$ | async"
    (itemClick)="selectItem($event)"
    [pickable]="true"
    (pickItem)="pickItem($event)"
    (nextPage)="nextPage()"
    [canFetchMore]="dataViewerService.canFetchMore$ | async"
  />
}
