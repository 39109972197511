import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';
import { GeoPoint, LineStringGeometry } from '@cartken/map-types';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { toGoogleLatLng } from '@/utils/geo-tools';
import { visiblePageTimer } from '@/utils/page-visibility';
import { BackendService } from '@/app/core/backend.service';
import {
  LocalizationDataRecordingState,
  Location,
  RecordingStatus,
  Robot,
} from '@/app/core/robots-service/backend/robot.dto';
import { isOnline } from '@/app/robots/robot-operation/robot-operator-view/robot-quick-add-dialog.component';
import type { ColoredMarker, Geometry, Overlay } from './mapping.component';
import { RouteDto } from '@/app/core/robots-service/backend/types';
import { RobotsBackendService } from '@/app/core/robots-service/robots-backend.service';
import { hasAtLeastTwoElements } from '@/utils/typeGuards';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { DecimalPipe, DatePipe } from '@angular/common';
import { MatMiniFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { OperationsService } from '../core/operations-service';

function compare(
  a: number | string | Date,
  b: number | string | Date,
  isAsc: boolean,
) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

enum ActionStatus {
  READY_TO_RECORD = 'ReadyToRecord',
  TRANSITION = 'Transition',
  STOP_RECORDING = 'StopRecording',
  ACKNOWLEDGE = 'Acknowledge',
}

const actionStatusPriority = [
  ActionStatus.READY_TO_RECORD,
  ActionStatus.STOP_RECORDING,
  ActionStatus.ACKNOWLEDGE,
  ActionStatus.TRANSITION,
] as const;

function compareActions(
  action1: ActionStatus,
  action2: ActionStatus,
  isAsc: boolean,
) {
  const priority1 = actionStatusPriority.indexOf(action1);
  const priority2 = actionStatusPriority.indexOf(action2);

  return isAsc ? priority1 - priority2 : priority2 - priority1;
}

const PAGE_UPDATE_INTERVAL_MILLIS = 5000;
const SNACK_BAR_INFO_DURATION_MILLIS = 3000;
const DEFAULT_ROBOT_IMG_URL = 'assets/robot/model-c-profile-picture.png';

const RECORDING_TIMEOUT_MILLIS = 1000 * 60 * 90;
const RECORDING_TARGET_DISTANCE = 2000;
const RECORDING_MIN_DISTANCE = 1000;

type Action = {
  status: ActionStatus;
  icon: string;
  tooltip: string;
};

interface RobotData {
  id: string;
  robotName: string;
  robotPictureUrl: string;
  operationId: string;
  isReady?: boolean;
  warning?: string;
  start?: Date;
  distanceLeft?: number;
  location: Location;
  heading: number;
  action: Action;
  localizationAction?: Action;
  localizationDataRecordingState?: LocalizationDataRecordingState;
}

function computeWarning(robot: Robot): string | undefined {
  if (robot.jetsonUptime !== undefined && robot.jetsonUptime < 3600) {
    return 'uptime is very low';
  }
  if (
    robot.gpsCovariance !== undefined &&
    robot.gpsCovariance !== null &&
    robot.gpsCovariance > 25
  ) {
    return 'GPS accuracy is low';
  }
  return;
}

function computeDistanceLeft(robot: Robot) {
  const targetDistanceMeters = robot?.mappingStatus?.targetDistanceMeters;
  const startDistance = robot?.mappingStatus?.startDistance;
  const robotDistance = robot.absoluteTraveledDistance;

  return targetDistanceMeters && startDistance && robotDistance
    ? targetDistanceMeters - (robotDistance - startDistance)
    : undefined;
}

function getPolyline(route: RouteDto): LineStringGeometry | undefined {
  const geometry = route.geometry;
  const coordinates = geometry?.map(
    ({ latitude, longitude }): GeoPoint => [longitude, latitude],
  );

  return coordinates && hasAtLeastTwoElements(coordinates)
    ? {
        type: 'LineString',
        coordinates,
      }
    : undefined;
}

const recordingStatusToAction: Record<RecordingStatus, Action> = {
  Failed: {
    status: ActionStatus.ACKNOWLEDGE,
    icon: 'report_problem',
    tooltip: 'Acknowledge failed recording',
  },
  Idle: {
    status: ActionStatus.READY_TO_RECORD,
    icon: 'play_arrow',
    tooltip: 'Start recording',
  },
  PreparedToStart: {
    status: ActionStatus.TRANSITION,
    icon: 'hourglass_empty',
    tooltip: 'Starting recording',
  },
  Recording: {
    status: ActionStatus.STOP_RECORDING,
    icon: 'stop',
    tooltip: 'Stop recording',
  },
  Starting: {
    status: ActionStatus.TRANSITION,
    icon: 'hourglass_empty',
    tooltip: 'Starting recording',
  },
  Stopping: {
    status: ActionStatus.TRANSITION,
    icon: 'hourglass_empty',
    tooltip: 'Stopping recording',
  },
  Succeeded: {
    status: ActionStatus.ACKNOWLEDGE,
    icon: 'done',
    tooltip: 'Acknowledge success',
  },
};

function robotToRecordingAction(robot: Robot): Action {
  return recordingStatusToAction[
    robot?.mappingStatus?.recordingStatus ?? RecordingStatus.IDLE
  ];
}

function robotToLocalizationRecordingAction(
  localizationDataRecordingState: LocalizationDataRecordingState | undefined,
): Action | undefined {
  if (!localizationDataRecordingState) {
    return;
  }
  if (localizationDataRecordingState === LocalizationDataRecordingState.READY) {
    return {
      status: ActionStatus.READY_TO_RECORD,
      icon: 'play_arrow',
      tooltip: 'Start recording',
    };
  }

  if (
    localizationDataRecordingState === LocalizationDataRecordingState.RECORDING
  ) {
    return {
      status: ActionStatus.STOP_RECORDING,
      icon: 'stop',
      tooltip: 'Stop recording',
    };
  }
  return undefined;
}

@Component({
  selector: 'app-mapping-robots',
  templateUrl: './mapping-robots.component.html',
  styleUrl: './mapping-robots.component.sass',
  imports: [
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatMiniFabButton,
    MatIcon,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatTooltip,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    DecimalPipe,
    DatePipe,
  ],
})
export class MappingRobotsComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;

  private sortConfig?: Sort;

  robotsData: RobotData[] = [];

  displayedColumns: string[] = [
    'robot',
    'start',
    'distance',
    'warnings',
    'actions',
    'localization-actions',
  ];
  dataSource = new MatTableDataSource<RobotData>([]);

  operationIds: string[] = [];
  selectedOperationFilter?: string;
  operationRegions = new Map<
    string,
    Promise<google.maps.LatLngBounds | undefined>
  >();

  coverageStatus?: string;

  @Output()
  showTrajectory = new EventEmitter<Geometry>();

  @Output()
  setOverlay = new EventEmitter<Overlay>();

  private destroy$ = new Subject();

  constructor(
    private backendService: BackendService,
    private operationsService: OperationsService,
    private robotBackendService: RobotsBackendService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    visiblePageTimer(0, PAGE_UPDATE_INTERVAL_MILLIS)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loadMetaDataFiles().catch((e) => console.warn(e));
      });
  }

  async loadMetaDataFiles(): Promise<void> {
    const robots = await firstValueFrom(this.robotBackendService.getRobots());
    this.robotsData = robots
      .filter((robot) => !robot.isVirtualRobot)
      .filter((robot) => {
        const now = Date.now();
        return (
          isOnline(now)(robot) ||
          (robot.mappingStatus?.recordingStatus ?? RecordingStatus.IDLE) !==
            RecordingStatus.IDLE
        );
      })
      .map((robot): RobotData => {
        const startTimeString = robot?.mappingStatus?.startTime;

        return {
          id: robot.id,
          robotName: `Cart ${robot.serialNumber}`,
          robotPictureUrl: robot.pictureUrl ?? DEFAULT_ROBOT_IMG_URL,
          operationId: robot.assignedOperationId || 'unassigned',
          isReady: robot.readyForOrders,
          warning: computeWarning(robot),
          start: startTimeString ? new Date(startTimeString) : undefined,
          distanceLeft: computeDistanceLeft(robot),
          location: robot.location,
          heading: robot.heading,
          action: robotToRecordingAction(robot),
          localizationDataRecordingState: robot.localizationDataRecordingState,
          localizationAction: robotToLocalizationRecordingAction(
            robot.localizationDataRecordingState,
          ),
        };
      });
    this.operationIds = Array.from(
      new Set(
        this.robotsData.map((robotData): string => {
          return robotData.operationId;
        }),
      ),
    ).sort();

    this.dataSource.data = this.robotsData;
    this.doSortData();
    this.hideTrajectory();
  }

  async getBoundsForOperation(
    operationId: string,
  ): Promise<google.maps.LatLngBounds | undefined> {
    if (operationId === 'unassigned') {
      return undefined;
    }
    if (!this.operationRegions.has(operationId)) {
      this.operationRegions.set(
        operationId,
        this.fetchBoundsForOperation(operationId),
      );
    }
    return this.operationRegions.get(operationId);
  }

  async fetchBoundsForOperation(
    operationId: string,
  ): Promise<google.maps.LatLngBounds> {
    const operation = await firstValueFrom(
      this.operationsService.getOperationById(operationId),
    );
    const bounds = new google.maps.LatLngBounds();
    operation.operationRegion?.coordinates.forEach((polygon: GeoPoint[]) => {
      polygon.forEach((point: GeoPoint) => {
        const latLon = toGoogleLatLng(point);
        if (latLon !== undefined) {
          bounds.extend(latLon);
        }
      });
    });
    return bounds;
  }

  async handleAction(robotData: RobotData): Promise<void> {
    switch (robotData.action.status) {
      case ActionStatus.ACKNOWLEDGE:
        await this.backendService
          .post(`/robots/${robotData.id}/acknowledge-mapping-result`, {})
          .toPromise();
        this.snackBar.open('Mapping result is acknowledged', undefined, {
          duration: SNACK_BAR_INFO_DURATION_MILLIS,
        });
        break;
      case ActionStatus.READY_TO_RECORD:
        await this.backendService
          .post(`/robots/${robotData.id}/start-mapping`, {
            minDistanceMeters: RECORDING_MIN_DISTANCE,
            targetDistanceMeters: RECORDING_TARGET_DISTANCE,
            timeoutMillis: RECORDING_TIMEOUT_MILLIS,
          })
          .toPromise();
        this.snackBar.open('Mapping starts', undefined, {
          duration: SNACK_BAR_INFO_DURATION_MILLIS,
        });
        break;
      case ActionStatus.STOP_RECORDING:
        await this.backendService
          .post(`/robots/${robotData.id}/stop-mapping`, {})
          .toPromise();
        this.snackBar.open('Mapping stops', undefined, {
          duration: SNACK_BAR_INFO_DURATION_MILLIS,
        });
        break;
      case ActionStatus.TRANSITION:
        this.snackBar.open(
          'Please, wait for a robot to finish transition',
          undefined,
          {
            duration: SNACK_BAR_INFO_DURATION_MILLIS,
          },
        );
        break;
    }
  }

  async handleLocAction(robotData: RobotData): Promise<void> {
    switch (robotData.localizationAction?.status) {
      case ActionStatus.READY_TO_RECORD:
        await this.backendService
          .post(`/robots/${robotData.id}/start-loc-data-recording`, {})
          .toPromise();
        this.snackBar.open('Loc data recording starts', undefined, {
          duration: SNACK_BAR_INFO_DURATION_MILLIS,
        });
        break;
      case ActionStatus.STOP_RECORDING:
        await this.backendService
          .post(`/robots/${robotData.id}/stop-loc-data-recording`, {})
          .toPromise();
        this.snackBar.open('Loc data recording stops', undefined, {
          duration: SNACK_BAR_INFO_DURATION_MILLIS,
        });
        break;
    }
  }

  doSortData(): number | undefined {
    const data = this.robotsData.slice();
    const sortConfig = this.sortConfig;
    if (
      sortConfig === undefined ||
      !sortConfig.active ||
      sortConfig.direction === ''
    ) {
      this.dataSource.data = data;
      return;
    }
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sortConfig.direction === 'asc';
      switch (sortConfig.active) {
        case 'robot':
          return compare(a.robotName, b.robotName, isAsc);
        case 'start':
          return compare(a.start ?? '', b.start ?? '', isAsc);
        case 'distance':
          return compare(a.distanceLeft ?? 0, b.distanceLeft ?? 0, isAsc);
        case 'actions':
          return compareActions(a.action.status, b.action.status, isAsc);
        default:
          return 0;
      }
    });
    return;
  }

  sortData(sort: Sort): number | undefined {
    this.sortConfig = sort;
    return this.doSortData();
  }

  hideTrajectory() {
    this.showTrajectory.emit({});
  }

  async emitShowTrajectory(robot: RobotData): Promise<void> {
    const route = await firstValueFrom(
      this.robotBackendService.getRobotRoute(robot.id),
    );

    const polyline = getPolyline(route);
    if (polyline === undefined) {
      this.hideTrajectory();
      return;
    }
    const markers: ColoredMarker[] = [];
    const lastTrajectoryPoint =
      polyline.coordinates[polyline.coordinates.length - 1];
    if (lastTrajectoryPoint !== undefined) {
      const position = toGoogleLatLng(lastTrajectoryPoint);
      if (position !== undefined) {
        markers.push({
          position: position,
          color: '#fff',
        });
      }
    }
    markers.push({
      position: new google.maps.LatLng(
        robot.location.latitude,
        robot.location.longitude,
      ),
      color: '#6ab',
      orientation: robot.heading,
    });
    this.showTrajectory.emit({
      polylines: [
        {
          polyline: polyline,
          color: 'black',
        },
      ],
      markers: markers,
      bounds: await this.getBoundsForOperation(robot.operationId),
    });
  }

  applyOperationFilter() {
    this.dataSource.filterPredicate = (data: RobotData, filter: string) => {
      return data.operationId === filter;
    };
    this.dataSource.filter = this.selectedOperationFilter ?? '';
  }

  async displayAttachedCoverage(event: any) {
    const metadata = await this.getCoverageMetadata(event.target.files);
    if (metadata === undefined) {
      return;
    }
    const extent: {
      east_lon?: number;
      north_lat?: number;
      south_lat?: number;
      west_lon?: number;
    } = JSON.parse(metadata)?.extent;
    if (
      extent.east_lon === undefined ||
      extent.north_lat === undefined ||
      extent.south_lat === undefined ||
      extent.west_lon === undefined
    ) {
      return;
    }
    const bounds: google.maps.LatLngBoundsLiteral = {
      east: extent.east_lon,
      north: extent.north_lat,
      south: extent.south_lat,
      west: extent.west_lon,
    };
    const imageFile = this.getCoverageImageFile(event.target.files);
    if (imageFile === undefined) {
      return;
    }
    this.setOverlay.emit({
      imageUrl: URL.createObjectURL(imageFile),
      bounds: bounds,
    });
    this.coverageStatus = JSON.stringify(bounds, null, 2);
  }

  async getCoverageMetadata(files: FileList): Promise<string | undefined> {
    for (const i in files) {
      const file = files[i];
      if (file === undefined || file.name !== 'meta.json') {
        continue;
      }
      const buffer = await file.arrayBuffer();
      return String.fromCharCode.apply(
        null,
        Array.from(new Uint8Array(buffer)),
      );
    }
    return;
  }

  getCoverageImageFile(files: FileList): File | undefined {
    for (const i in files) {
      const file = files[i];
      if (file === undefined || file.name !== 'debug_coverage_map.png') {
        continue;
      }
      return file;
    }
    return;
  }
}
