<div mat-dialog-content>
  <div class="dialog-contianer">
    @if (!isLoaded) {
      <div class="robot-loading-spinner">
        <mat-spinner />
      </div>
    }

    <app-quick-add-remove-dialog
      [class.hidden]="!isLoaded"
      [items]="robots | async"
      (close)="close()"
      (update)="addSelectedOperations($event)"
    />
  </div>
</div>
