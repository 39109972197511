import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ActivatedRoute, Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

import { VideoChannel } from '@/app/core/robots-service/webrtc/types';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  merge,
  Observable,
  Subject,
  timer,
} from 'rxjs';
import {
  take,
  takeUntil,
  timeInterval,
  timeout,
  finalize,
  map,
} from 'rxjs/operators';
import { AuthService } from '@/app/core/auth.service';
import { Role } from '@/app/core/user';
import { RobotsService } from '@/app/core/robots-service/robots.service';
import { RobotCommunication } from '@/app/core/robots-service/robot-communication';
import { MatDialog } from '@angular/material/dialog';
import { DataCollectionConfigurationDialogComponent } from '../data-collection-configuration-dialog/data-collection-configuration-dialog.component';
import {
  Location,
  AsyncPipe,
  DecimalPipe,
  KeyValuePipe,
} from '@angular/common';
import {
  RobotQuickAddDialogComponent,
  RobotQuickAddDialogData,
} from './robot-quick-add-dialog.component';
import { WarningDialogComponent } from '@/app/core/warning-dialog/warning-dialog.component';
import { UserSessionService } from '@/app/core/user-session/user-session.service';
import { ViewName } from '@/app/core/user-session/user-session.utils';
import { UserSessionEventTrackingService } from '@/app/core/user-session/user-session-event-tracking.service';
import { UserSessionInteractionEventName } from '@/app/core/user-session/user-session-interaction-events';
import { RobotOperatorControlComponent } from './robot-operator-control.component';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
  MatExpansionPanelContent,
} from '@angular/material/expansion';
import { MiniMapComponent } from '@/app/core/mini-map/mini-map.component';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { StatusTreeComponent } from '../status-tree/status-tree.component';
import { JoystickComponent } from '../joystick/joystick.component';
import { MatIcon } from '@angular/material/icon';
import { hasAtLeastOneElement } from '@/utils/typeGuards';

@Component({
  selector: 'app-robot-operator-view',
  templateUrl: './robot-operator-view.component.html',
  styleUrl: './robot-operator-view.component.sass',
  animations: [
    trigger('connectionState', [
      state(
        'active',
        style({
          gridRowStart: 1,
          gridRowEnd: 2,
          gridColumnStart: 1,
          gridColumnEnd: 13,
        }),
      ),
      state('inactive', style({ gridColumn: 'span 4', gridRow: 'span 1' })),
      state(
        'inactiveMouseOver',
        style({
          transform: 'translateY(-1.2%) scale(1.03)',
          gridColumn: 'span 4',
          gridRow: 'span 1',
        }),
      ),
      state('allInactive', style({ gridColumn: 'span 6', gridRow: 'span 1' })),
      state(
        'allInactiveMouseOver',
        style({
          transform: 'scale(1.03)',
          gridColumn: 'span 6',
          gridRow: 'span 1',
        }),
      ),
      transition('inactive => active', [
        animate(
          '500ms ease-in-out',
          style({ transform: 'translateY(-200%) scale(3)' }),
        ),
      ]),
      transition('inactiveMouseOver => active', [
        animate(
          '500ms ease-in-out',
          style({ transform: 'translateY(-200%) scale(3)' }),
        ),
      ]),
      transition('allInactive => active', [
        animate(
          '500ms ease-in-out',
          style({ transform: 'translateX(50%) translateY(50%) scale(2)' }),
        ),
      ]),
      transition('allInactiveMouseOver => active', [
        animate(
          '500ms ease-in-out',
          style({ transform: 'translateX(50%) translateY(50%) scale(2)' }),
        ),
      ]),
      transition('active => inactive', [
        animate(
          '500ms ease-in-out',
          style({ transform: 'translateY(66%) scale(0.33)' }),
        ),
      ]),
      transition('allInactive => inactive', [
        animate('500ms ease-in-out', style({ transform: 'scale(0.66)' })),
      ]),
      transition('active => allInactive', [
        animate('500ms ease-in-out', style({ transform: 'scale(0.5)' })),
      ]),
      transition('inactive => allInactive', [
        animate('500ms ease-in-out', style({ transform: 'scale(1.5)' })),
      ]),
      transition('* => *', animate('300ms linear')),
    ]),
  ],
  imports: [
    RobotOperatorControlComponent,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatExpansionPanelContent,
    MiniMapComponent,
    MatButton,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatSlideToggle,
    FormsModule,
    StatusTreeComponent,
    JoystickComponent,
    MatIconButton,
    MatIcon,
    AsyncPipe,
    DecimalPipe,
    KeyValuePipe,
  ],
})
export class RobotOperatorViewComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  private readonly disconnected$ = new Subject<void>();
  private readonly enforceFullscreen = false;
  private readonly maxDurationOutOfFocusBeforePause = 20;
  private readonly maxDurationOutOfFocusBeforeDisconnect = 5 * 60;
  robotCommunications: RobotCommunication[] = [];
  activeConnectionIndex: number | undefined;
  mouseOverConnectionIndex: number | undefined;
  routingPanelOpen = false;
  statusPanelOpen = false;
  joystickPanelOpen = false;
  dataCollectionPanelOpen = false;
  inhibitActive = false;
  timeEventOutOfFocus = new Date();
  streamsPaused = false;
  videoChannels = VideoChannel;

  isUserAdmin$ = new BehaviorSubject<boolean>(false);
  isAdmin$!: Observable<boolean>;

  activeRobotCommunication() {
    return this.activeConnectionIndex !== undefined
      ? this.robotCommunications[this.activeConnectionIndex]
      : undefined;
  }

  constructor(
    private robotService: RobotsService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private location: Location,
    private dialog: MatDialog,
    private userSessionService: UserSessionService,
    private userSessionInteractionTrackingService: UserSessionEventTrackingService,
  ) {
    if (this.enforceFullscreen) {
      // Switch to fullscreen mode and exit robot control whenever fullscreen is
      // exited.
      document.onfullscreenchange = () => {
        if (document.fullscreenElement === null) {
          this.snackBar.open(
            'Exited fullscreen while controlling robot! Returned to main page.',
            undefined,
            { duration: 5000 },
          );
          this.router.navigate(['/']);
        }
      };
      document.documentElement.requestFullscreen();
    }
  }

  setVideoChannel(videoChannel: VideoChannel) {
    const activeRobotCommunication = this.activeRobotCommunication();

    if (!activeRobotCommunication) {
      return;
    }

    this.userSessionInteractionTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.VIDEO_CHANNEL_UPDATE,
      {
        robotId: activeRobotCommunication.robotId,
        videoChannel,
      },
    );

    activeRobotCommunication.sendVideoConfiguration({
      channelName: videoChannel,
      maxPixelCount: 800 * 600,
      fps: 10,
      maxBitrate: 3000000,
    });
  }

  async ngOnInit() {
    combineLatest([
      this.route.paramMap,
      this.route.queryParams,
      this.authService.user$,
    ])
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe(async ([params, query, user]) => {
        const robotIds = (params.get('robotIds') || '').split(',');
        await this.updateRobotConnections(robotIds);

        const activeRobotIndex = robotIds.findIndex(
          (robotId) => robotId === query['active'],
        );
        if (activeRobotIndex >= 0) {
          this.setActiveConnection(activeRobotIndex);
        }

        fromEvent<KeyboardEvent>(window, 'keydown')
          .pipe(takeUntil(this.destroyed$))
          .subscribe((event) => {
            const isInputField =
              event.target instanceof HTMLInputElement &&
              (!event.target.type || event.target.type === 'text');
            if (
              !isInputField &&
              !this.inhibitActive &&
              this.handleKeyDown(event)
            ) {
              event.preventDefault();
            }
          });

        this.isUserAdmin$.next(!!user?.roles?.includes(Role.ADMIN));
        if (user?.roles?.includes(Role.ADMIN)) {
          return;
        }

        if (this.activeConnectionIndex === undefined) {
          const continueSupervising = await this.dialog
            .open(WarningDialogComponent, {
              data: {
                textLine1:
                  'The page shall ONLY be used for a fleet maintenance.',
                textLine2:
                  'Please, otherwise, use automated robot supervision UI!',
                confirmText: 'Continue maintenance',
                dismissText: 'Start robot supervision',
              },
            })
            .afterClosed()
            .toPromise();

          if (
            continueSupervising === false ||
            continueSupervising === undefined
          ) {
            this.router.navigateByUrl('/supervise-robots');
          }
        }

        this.startTabFocusSupervision();
      });

    this.isAdmin$ = this.authService.user$.pipe(
      map((user) => user?.roles.includes(Role.ADMIN) ?? false),
    );

    await this.userSessionService.goOnline();
    this.userSessionService.setViewName(ViewName.CLASSIC_SUPERVISION);
    this.userSessionService.sessionCollision$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async () => {
        this.userSessionService.goOffline();
        await this.updateRobotConnections([]);
      });
  }

  ngOnDestroy() {
    this.activeConnectionIndex = undefined;
    this.robotCommunications.forEach((robotCommunication) => {
      robotCommunication.finalize();
    });
    this.destroyed$.next(undefined);
    if (this.enforceFullscreen && document.fullscreenElement !== null) {
      document.exitFullscreen();
    }

    this.userSessionService.goOffline();
  }

  setActiveConnection(activeConnectionIndex: number | undefined) {
    if (
      activeConnectionIndex !== undefined &&
      activeConnectionIndex >= this.robotCommunications.length
    ) {
      this.activeConnectionIndex = undefined;
      return;
    }
    this.activeConnectionIndex = activeConnectionIndex;
    this.updateRobotUrl();
  }

  getConnectionState(connectionIndex: number, mouseOver: boolean) {
    if (connectionIndex === this.activeConnectionIndex) {
      return 'active';
    }
    if (this.activeConnectionIndex === undefined) {
      return mouseOver ? 'allInactiveMouseOver' : 'allInactive';
    }
    return mouseOver ? 'inactiveMouseOver' : 'inactive';
  }

  private startTabFocusSupervision() {
    fromEvent(window, 'blur')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.timeEventOutOfFocus = new Date();
        this.snackBar.open(
          'Please keep window in focus, unfocused time is logged!',
          `I'm back`,
          { verticalPosition: 'top' },
        );
        timer(0, 10000)
          .pipe(
            takeUntil(this.destroyed$),
            takeUntil(fromEvent(window, 'focus')),
            finalize(() => {
              for (const robotCommunication of this.robotCommunications) {
                robotCommunication.startStreams();
              }
              this.streamsPaused = false;
            }),
          )
          .subscribe({
            next: async () => {
              const durationOutOfFocus =
                (Date.now().valueOf() - this.timeEventOutOfFocus.valueOf()) /
                1000.0;
              if (
                durationOutOfFocus > this.maxDurationOutOfFocusBeforePause &&
                !this.streamsPaused
              ) {
                for (const robotCommunication of this.robotCommunications) {
                  robotCommunication.stopStreams();
                }
                this.streamsPaused = true;

                this.snackBar.open(
                  `More than ${this.maxDurationOutOfFocusBeforePause} seconds out of focus. All streams paused.`,
                  `I'm back`,
                  { verticalPosition: 'top' },
                );
                return;
              }
              if (
                durationOutOfFocus > this.maxDurationOutOfFocusBeforeDisconnect
              ) {
                this.robotCommunications.forEach((robotCommunication) =>
                  robotCommunication.finalize(),
                );
                this.robotCommunications = [];
                this.disconnected$.next(undefined);
                const ref = this.snackBar.open(
                  `More than ${(
                    this.maxDurationOutOfFocusBeforeDisconnect / 60
                  ).toFixed(
                    1,
                  )} minutes out of focus. Client was disconnected. Page refresh required.`,
                  'Refresh Page',
                  { verticalPosition: 'top' },
                );
                ref.afterDismissed().subscribe(() => {
                  window.location.reload();
                });
              }
            },
          });
      });

    fromEvent(window, 'focus')
      .pipe(takeUntil(this.destroyed$), takeUntil(this.disconnected$))
      .subscribe(() => {
        this.snackBar.open('Welcome back!', undefined, {
          verticalPosition: 'top',
          duration: 600,
        });
        this.startActivityMonitor();
      });

    this.startActivityMonitor();
  }

  private async updateRobotConnections(robotIds: string[]) {
    const robotIdSet = new Set(robotIds);
    this.robotCommunications
      .filter(
        (robotCommunication) => !robotIdSet.has(robotCommunication.robotId),
      )
      .forEach((robotCommunication) => {
        robotCommunication.finalize();
      });

    if (!hasAtLeastOneElement(robotIds)) {
      this.router.navigateByUrl('/robots');
      return;
    }

    const robots = await this.robotService.getRobotCommunications(robotIds);
    this.robotCommunications = robots;
    this.updateRobotUrl();
  }

  private startActivityMonitor() {
    const manualControlEvents = this.robotCommunications.map(
      (robotCommunication) => robotCommunication.manualControl$,
    );
    merge(
      fromEvent(document, 'mousedown'),
      fromEvent(window, 'keydown'),
      ...manualControlEvents,
    )
      .pipe(
        takeUntil(this.destroyed$),
        takeUntil(this.disconnected$),
        takeUntil(fromEvent(window, 'blur')),
        timeInterval(),
        timeout(20000),
      )
      .subscribe({
        error: async () => {
          this.snackBar.open(
            'Just checking in, if everything is looking fine',
            `Yes!`,
            { verticalPosition: 'top' },
          );
          this.startActivityMonitor();
        },
      });
  }

  calibrateEndstop() {
    const activeRobotCommunication = this.activeRobotCommunication();

    if (!activeRobotCommunication) {
      return;
    }

    activeRobotCommunication.sendCalibrateEndstopCommand();
    this.userSessionInteractionTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.CALIBRATE_END_STOP_TRIGGERED,
      {
        robotId: activeRobotCommunication.robotId,
      },
    );
  }

  private handleKeyDown(event: KeyboardEvent): boolean {
    const keyCode = event.code;
    switch (keyCode) {
      case 'KeyN': {
        const newIndex = (this.activeConnectionIndex ?? 0) + 1;
        this.setActiveConnection(
          newIndex >= this.robotCommunications.length ? 0 : newIndex,
        );
        break;
      }
      case 'Escape':
        this.setActiveConnection(undefined);
        break;
      case 'Space':
        if (this.activeConnectionIndex === undefined) {
          for (const robotCommunication of this.robotCommunications) {
            robotCommunication.controlManually({
              speed: 0,
              turnRate: 0,
            });
            this.userSessionInteractionTrackingService.trackInteractionEvent(
              UserSessionInteractionEventName.BRAKE_ROBOT,
              {
                robotId: robotCommunication.robotId,
              },
            );
          }
        }
        break;
      case 'KeyR':
        if (event.metaKey) {
          // allow refresh on mac
          return false;
        }
        if (this.activeConnectionIndex === undefined) {
          for (const robotCommunication of this.robotCommunications) {
            robotCommunication.claimRobotControl(false);
          }
        }
        break;
      case 'KeyC':
        if (this.activeConnectionIndex === undefined) {
          for (const robotCommunication of this.robotCommunications) {
            robotCommunication.claimRobotControl(true);
          }
        }
        break;
      default:
        return false;
    }
    return true;
  }

  toGB(diskSpace: number) {
    return diskSpace / 2 ** 30;
  }

  onDialogStateChange(open: boolean) {
    this.inhibitActive = open;
  }

  openDataCollectionConfigurationDialog(
    robotCommunication: RobotCommunication,
  ) {
    const handle = this.dialog.open(
      DataCollectionConfigurationDialogComponent,
      {
        data: robotCommunication,
      },
    );
    this.onDialogStateChange(true);
    handle.afterClosed().subscribe(() => {
      this.onDialogStateChange(false);
    });
  }

  private getActiveRobotId(): string | undefined {
    return this.activeConnectionIndex !== undefined
      ? this.robotCommunications[this.activeConnectionIndex]?.robotId
      : undefined;
  }

  private updateRobotUrl() {
    if (this.robotCommunications.length === 0) {
      this.router.navigateByUrl('/robots');
    } else {
      const urlPrefix = '/robots/supervise/';
      const robotIdsString = this.robotCommunications
        .map(({ robotId }) => robotId)
        .join(',');

      const newUrl = `${urlPrefix}${robotIdsString}`;

      const activeRobot =
        this.activeConnectionIndex !== undefined
          ? this.robotCommunications[this.activeConnectionIndex]
          : undefined;
      if (activeRobot) {
        this.location.replaceState(`${newUrl}?active=${activeRobot.robotId}`);
      } else {
        this.location.replaceState(newUrl);
      }
    }
  }

  openAddRobotsDialog() {
    const selectedRobotIds = this.robotCommunications.map(
      ({ robotId }) => robotId,
    );
    this.dialog
      .open<RobotQuickAddDialogComponent, RobotQuickAddDialogData, string[]>(
        RobotQuickAddDialogComponent,
        {
          data: {
            selectedRobotIds,
          },
        },
      )
      .afterClosed()
      .subscribe(async (newlySelectedRobotIds) => {
        if (newlySelectedRobotIds) {
          const activeRobotId = this.getActiveRobotId();
          if (activeRobotId && !newlySelectedRobotIds.includes(activeRobotId)) {
            this.activeConnectionIndex = undefined;
          }
          this.updateRobotConnections(newlySelectedRobotIds);
          this.updateRobotUrl();
        }
      });
  }

  isAnyRobotActive(): boolean {
    return this.activeConnectionIndex !== undefined;
  }

  powerCycle() {
    const currentRobotCommunication = this.activeRobotCommunication();

    if (!currentRobotCommunication) {
      return;
    }

    this.userSessionInteractionTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.REMOTE_POWER_CIRCLE,
      {
        robotId: currentRobotCommunication.robotId,
      },
    );
    currentRobotCommunication.sendHubReset();
    currentRobotCommunication.sendPowerCycle();
  }
}
