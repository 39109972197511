import { Finalizable } from '@/utils/finalizable';
import { RtcNetworkInterfaces } from './rtc-network-interfaces';
import { NetworkInterfaceStats } from './signaling-server-messages';
import { takeUntil } from 'rxjs/operators';
import { TtlSet } from '@/utils/set-ttl';

const NETWORK_CONNECTION_BAN_DURATION_MILLIS = 1000 * 60 * 5;

export class RtcNetworkSelectionManager extends Finalizable {
  private latestNetworkInterfaces: NetworkInterfaceStats[] = [];
  private selectedNetworkInterface = '';

  private bannedInterfaces = new TtlSet<string>(
    NETWORK_CONNECTION_BAN_DURATION_MILLIS,
  );

  constructor(rtcNetworkInterfaces: RtcNetworkInterfaces) {
    super();
    rtcNetworkInterfaces.availableNetworkInterfaces$
      .pipe(takeUntil(this.finalized$))
      .subscribe((newNetworkInterfaces) => {
        this.latestNetworkInterfaces = newNetworkInterfaces;
      });
    rtcNetworkInterfaces.selectedNetworkInterface$
      .pipe(takeUntil(this.finalized$))
      .subscribe((selectedNetworkInterface) => {
        this.selectedNetworkInterface = selectedNetworkInterface ?? '';
      });
  }

  banSelectedInterface(): string {
    this.bannedInterfaces.add(this.selectedNetworkInterface);
    return this.selectedNetworkInterface;
  }

  getBestInterface(): string {
    const sortedLatestNetworkInterfaces = this.latestNetworkInterfaces
      .filter(
        (
          networkInterfaces,
        ): networkInterfaces is NetworkInterfaceStats & {
          maxPing: NonNullable<NetworkInterfaceStats['maxPing']>;
        } =>
          networkInterfaces.isConnected &&
          networkInterfaces.maxPing !== undefined,
      )
      .sort((a, b) => a.maxPing - b.maxPing);

    const bestInterfaceName = sortedLatestNetworkInterfaces.find(
      (networkInterface) =>
        !this.bannedInterfaces.has(networkInterface.interfaceName),
    );

    if (bestInterfaceName) {
      return bestInterfaceName.interfaceName;
    }

    this.bannedInterfaces.clear();

    return '';
  }

  protected async onFinalize(): Promise<void> {
    // do nothing?
  }
}
