import { distinctUntilChanged, map } from 'rxjs/operators';
import { BackendStatePolling } from './backend-state-polling';
import {
  HazardLightsState,
  PowerMode,
  UnsupervisedAutonomyState,
} from './robot.dto';
import { combineLatest, Observable } from 'rxjs';

const THRESHOLD_OF_DEATH_MS = 1000 * 60;

export class RobotDerivedState {
  readonly isAlive$: Observable<boolean>;
  readonly isServingOrder$: Observable<boolean>;
  readonly unsupervisedAutonomyState$: Observable<
    UnsupervisedAutonomyState | undefined
  >;
  readonly isPowerSaving$: Observable<boolean>;
  readonly isAutomaticPowerSaving$: Observable<boolean>;
  readonly hazardLightsState$: Observable<HazardLightsState>;
  readonly desiredOperatorHazardLightsState$: Observable<HazardLightsState>;
  readonly readyForOrders$: Observable<boolean>;
  readonly routeToEndDistance$: Observable<number | null>;

  constructor(
    readonly robotId: string,
    private readonly backendStatePolling: BackendStatePolling,
  ) {
    this.isAlive$ = this.backendStatePolling.robotState$.pipe(
      map((robotState) => {
        const updateTime = robotState.updatedAt.getTime();
        const now = Date.now();
        return now - updateTime < THRESHOLD_OF_DEATH_MS;
      }),
      distinctUntilChanged(),
    );
    this.isServingOrder$ = this.backendStatePolling.robotState$.pipe(
      map((robotState) => {
        const orderLength = robotState.scheduledStops?.[0]?.orders.length ?? 0;
        return orderLength > 0;
      }),
      distinctUntilChanged(),
    );
    this.unsupervisedAutonomyState$ = this.backendStatePolling.robotState$.pipe(
      map((robotState) => {
        return robotState.unsupervisedAutonomyState;
      }),
    );
    this.isPowerSaving$ = this.backendStatePolling.robotState$.pipe(
      map((robotState) => robotState.powerMode === PowerMode.SAVING),
      distinctUntilChanged(),
    );
    this.isAutomaticPowerSaving$ = this.backendStatePolling.robotState$.pipe(
      map((robotState) => robotState.automaticPowerSaving === true),
      distinctUntilChanged(),
    );
    this.hazardLightsState$ = this.backendStatePolling.robotState$.pipe(
      map((robot) => robot.hazardLightsState ?? HazardLightsState.AUTO),
      distinctUntilChanged(),
    );
    this.desiredOperatorHazardLightsState$ =
      this.backendStatePolling.robotState$.pipe(
        map(
          (robot) =>
            robot.desiredOperatorHazardLightsState ?? HazardLightsState.AUTO,
        ),
        distinctUntilChanged(),
      );
    this.readyForOrders$ = this.backendStatePolling.robotState$.pipe(
      map((robot) => !!robot.readyForOrders),
      distinctUntilChanged(),
    );
    this.routeToEndDistance$ = combineLatest([
      this.backendStatePolling.robotRoute$,
      this.backendStatePolling.robotState$,
    ]).pipe(
      map(([route, robotState]) => {
        const routeLength = route?.distance ?? 0;
        const distanceAlongRoute =
          robotState.routeMatch?.distanceAlongRoute ?? 0;
        return robotState.arrivedAtStop === true
          ? null
          : routeLength - distanceAlongRoute;
      }),
    );
  }
}
