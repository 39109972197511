import { inject, Injectable } from '@angular/core';
import { ErrorReportingService } from './error-reporting.service';
import { environment } from '../../environments/environment';

import { v } from '@/valibot';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  errorReportingService = inject(ErrorReportingService);

  validate<
    TSchema extends v.BaseSchema<unknown, unknown, v.BaseIssue<unknown>>,
  >(
    schema: TSchema,
    input: unknown,
    config?: v.Config<v.InferIssue<TSchema>>,
  ): v.InferOutput<TSchema> {
    const result = v.safeParse(schema, input, config);
    if (!result.success) {
      const validationIssue = v.flatten(result.issues);
      console.error(validationIssue);
      const error = new Error(`Validation failed, see details above.`);
      if (environment.validateStrictly) {
        throw error;
      } else {
        this.errorReportingService.logError(
          'Validation failed',
          {
            validationIssue: JSON.stringify(validationIssue).slice(0, 1000),
            input: JSON.stringify(input).slice(0, 1000),
          },
          error,
        );
      }
    }
    return result.output as v.InferOutput<TSchema>;
  }
}
