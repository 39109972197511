import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WeeklySchedule } from '../operation';
import { FormsModule } from '@angular/forms';
import { WeeklyScheduleEditorComponent } from './weekly-schedule-editor.component';
import { MatButton } from '@angular/material/button';

type EditWeeklyScheduleDialogData = {
  timeZone: string;
  maxRobotsInOps: number;
  weeklySchedule?: WeeklySchedule;
};

export const DEFAULT_SCHEDULE: WeeklySchedule = {
  sunday: [],
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
};

@Component({
  selector: 'app-weekly-schedule-editor-dialog',
  templateUrl: './weekly-schedule-editor-dialog.component.html',
  styleUrl: './weekly-schedule-editor-dialog.component.sass',
  imports: [FormsModule, WeeklyScheduleEditorComponent, MatButton],
})
export class WeeklyScheduleEditorDialogComponent {
  timeZone: string;
  maxRobotsInOps: number;
  weeklySchedule: WeeklySchedule;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditWeeklyScheduleDialogData,
    private readonly dialogRef: MatDialogRef<WeeklyScheduleEditorDialogComponent>,
  ) {
    this.timeZone = data.timeZone;
    this.maxRobotsInOps = data.maxRobotsInOps;
    this.weeklySchedule =
      structuredClone(data.weeklySchedule) ?? DEFAULT_SCHEDULE;
  }

  save() {
    this.dialogRef.close({
      schedule: this.weeklySchedule,
    });
  }

  close() {
    this.dialogRef.close();
  }
}
