export enum UserSessionSystemEventName {
  // WebRTC connection to a robot is started
  WEB_RTC_CONNECTION_STARTED = 'WebRtcConnectionStarted',
  // Successful connection to a robot via WebRTC
  WEB_RTC_CONNECTED = 'WebRtcConnected',
  // Failed connection to a robot via WebRTC
  WEB_RTC_CONNECTION_FAILED = 'WebRtcConnectionFailed',
  // Heartbeat watchdog detected high latency via WebRTC
  LATENCY_WATCHDOG_TRIGGERED = 'LatencyWatchdogTriggered',
  // User was not active for too long, reaction assessment is triggered
  DEADMAN_SWITCH_TRIGGERED = 'DeadmanSwitchTriggered',
  // User failed to pass reaction assessment
  DEADMAN_SWITCH_EXPIRED = 'DeadmanSwitchExpired',
  // WebRTC connection manager changed connection due to high latency and ban previous network interface
  BAN_NETWORK_INTERFACE = 'BanNetworkInterface',
  // Robot control session is started
  START_ROBOT_CONTROL = 'StartRobotControl',
  // Robot control session is stopped
  STOP_ROBOT_CONTROL = 'StopRobotControl',
  // Robot is assigned
  ROBOT_ASSIGNED = 'RobotAssigned',
  // Robot finalized
  ROBOT_FINALIZED = 'RobotFinalized',
  // Robot is blocked for too long, assignment countdown is started
  ROBOT_BLOCKED = 'RobotBlocked',
  // Indicate condition when the system shall start the countdown to unassign a blocked robot,
  // but it cannot since there are no slots available for the blocked robot
  NO_SLOTS_FOR_BLOCKED_ROBOT = 'NoSlotsForBlockedRobot',
  // Robot is unassigned, since user did not react to assignment countdown
  BLOCKED_ROBOT_UNASSIGNED = 'BlockedRobotUnassigned',
  UNSUPERVISED_AUTONOMY_SHOULD_BE_ACTIVATED = 'UnsupervisedAutonomyShouldBeActivated',
  SKIPPED_TO_ACTIVATE_UNSUPERVISED_AUTONOMY = 'SkippedToActivateUnsupervisedAutonomy',
  INCONSISTENT_ASSIGNMENT_STATE = 'InconsistentAssignmentState',
}

export type UserSessionSystemEventPayload = {
  [UserSessionSystemEventName.WEB_RTC_CONNECTION_STARTED]: {
    robotId: string;
  };
  [UserSessionSystemEventName.WEB_RTC_CONNECTED]: {
    // Duration is establishing WebRTC connection in seconds
    connectionDurationSeconds: number;

    robotId: string;
  };
  [UserSessionSystemEventName.WEB_RTC_CONNECTION_FAILED]: {
    // Duration is establishing WebRTC connection in seconds
    connectionDurationSeconds: number;

    robotId: string;
    // Error caused connection to fail
    errorMessage: string;
    // Number of failed connection attempts
    connectionAttemptCount: number;
  };
  [UserSessionSystemEventName.DEADMAN_SWITCH_TRIGGERED]: object;
  [UserSessionSystemEventName.DEADMAN_SWITCH_EXPIRED]: object;
  [UserSessionSystemEventName.LATENCY_WATCHDOG_TRIGGERED]: {
    robotId: string;
    // Number of times watchdog was triggered in a row
    triggerInRowCount: number;
  };
  [UserSessionSystemEventName.BAN_NETWORK_INTERFACE]: {
    // Old network interface that was banned
    oldNetworkInterface: string;
    // New network interface that is used
    newNetworkInterface: string;
    robotId: string;
  };
  [UserSessionSystemEventName.START_ROBOT_CONTROL]: object;
  [UserSessionSystemEventName.STOP_ROBOT_CONTROL]: object;
  [UserSessionSystemEventName.ROBOT_ASSIGNED]: {
    robotId: string;
  };
  [UserSessionSystemEventName.ROBOT_FINALIZED]: {
    robotId: string;
  };
  [UserSessionSystemEventName.ROBOT_BLOCKED]: {
    robotId: string;
  };
  [UserSessionSystemEventName.BLOCKED_ROBOT_UNASSIGNED]: {
    robotId: string;
  };
  [UserSessionSystemEventName.NO_SLOTS_FOR_BLOCKED_ROBOT]: {
    robotId: string;
    blockedForSeconds: number;
  };
  [UserSessionSystemEventName.SKIPPED_TO_ACTIVATE_UNSUPERVISED_AUTONOMY]: {
    robotId: string;
  };
  [UserSessionSystemEventName.UNSUPERVISED_AUTONOMY_SHOULD_BE_ACTIVATED]: {
    robotId: string;
  };
  [UserSessionSystemEventName.INCONSISTENT_ASSIGNMENT_STATE]: {
    spilloverRobotIds: string[];
    unexpectedlyUnassignedRobotIds: string[];
  };
};

export type UserSessionSystemEvent<Type extends UserSessionSystemEventName> = {
  type: Type;
  payload?: UserSessionSystemEventPayload[Type];
};
