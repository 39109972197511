import { IsoDateTime } from '@cartken/map-types';
import {
  RobotIssue,
  RobotIssueSeverity,
} from '@/app/core/robot-issues/robot-issue.types';
import { v } from '@/valibot';

export enum CompartmentState {
  OPEN = 'Open',
  OPEN_AND_PRIMED = 'OpenAndPrimed',
  CLOSED_AND_UNLOCKED = 'ClosedAndUnlocked',
  CLOSED_AND_LOCKED = 'ClosedAndLocked',
}

export type Location = v.InferOutput<typeof Location>;
export const Location = v.object({
  latitude: v.number(),
  longitude: v.number(),
});

export enum HazardLightsState {
  AUTO = 'Auto',
  OFF = 'Off',
  INTERSECTION = 'Intersection',
  WAITING = 'Waiting',
  DEFAULT = 'Default',
  HAZARD = 'Hazard',
  NOTIFY_LOW_INTENSITY = 'Notify_Low_Intensity',
  NOTIFY_HIGH_INTENSITY = 'Notify_High_Intensity',
  ALARM = 'Alarm',
}

export enum PowerMode {
  DEFAULT = 'Default',
  SAVING = 'Saving',
}

export enum LocalizationDataRecordingState {
  READY = 'Ready',
  RECORDING = 'Recording',
}

const AssignedOrderReference = v.object({
  id: v.string(),
  handoverToCompleteIndex: v.optional(v.number()),
});

export type ComponentVersion = v.InferOutput<typeof ComponentVersion>;
const ComponentVersion = v.object({
  componentName: v.optional(v.string()),
  majorVersion: v.optional(v.number()),
  minorVersion: v.optional(v.number()),
  patchVersion: v.optional(v.number()),
  versionAppendix: v.optional(v.string()),
});

const ScheduledStop = v.object({
  ...Location.entries,
  altitude: v.optional(v.number()),
  robotQueueId: v.optional(v.number()),
  infrastructureId: v.optional(v.number()),
  estimatedArrivalTime: IsoDateTime,
  estimatedWaitingDuration: v.number(),
  orders: v.array(AssignedOrderReference),
});

const UberProps = v.object({
  vehicleId: v.optional(v.string()),
  isEnabled: v.optional(v.boolean()),
});

const ConnectedRobotOperator = v.object({
  id: v.string(),
  displayName: v.optional(v.string()),
  connectedInterface: v.optional(v.string()),
});

export type Compartment = v.InferOutput<typeof Compartment>;
const Compartment = v.object({
  id: v.string(),
  displayName: v.optional(v.string()),
  state: v.optional(v.enum(CompartmentState)),
  orderDetected: v.optional(v.boolean()),
});

export enum RecordingStatus {
  IDLE = 'Idle',
  PREPARED_TO_START = 'PreparedToStart',
  STARTING = 'Starting',
  RECORDING = 'Recording',
  STOPPING = 'Stopping',
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed',
}

const MappingStatus = v.object({
  startDistance: v.optional(v.number()),
  startTime: v.optional(v.string()),
  minDistanceMeters: v.optional(v.number()),
  targetDistanceMeters: v.optional(v.number()),
  timeoutMillis: v.optional(v.number()),
  recordingStatus: v.enum(RecordingStatus),
});

export enum ControlType {
  AUTONOMY = 'Autonomy',
  GOVERNOR = 'Governor',
  RC = 'RC',
  SUPERVISOR = 'Supervisor',
  WEB_RTC = 'WebRTC',
}

export type UserClaim = v.InferOutput<typeof UserClaim>;
const UserClaim = v.object({
  claimedBy: v.string(),
  reason: v.nullish(v.string()),
  claimedUntil: v.nullish(IsoDateTime),
  claimedSince: v.nullish(IsoDateTime),
});

export enum InfrastructureTransactionStatus {
  ACCESS_REQUESTED = 'AccessRequested',
  WAITING_SPOT_ASSIGNED = 'WaitingSpotAssigned',
  ENTER_REQUESTED = 'EnterRequested',
  ENTERED = 'Entered',
  EXIT_REQUESTED = 'ExitRequested',
  ERROR = 'Error',
}

const InfrastructureTransaction = v.object({
  status: v.optional(v.enum(InfrastructureTransactionStatus)),
  infrastructureId: v.optional(v.number()),
  locationName: v.optional(v.string()),
  abortedWithError: v.optional(v.string()),
  updatedAt: v.optional(v.string()),
});

export type UnsupervisedAutonomyState = v.InferOutput<
  typeof UnsupervisedAutonomyState
>;
const UnsupervisedAutonomyState = v.object({
  available: v.optional(v.boolean()),
  active: v.optional(v.boolean()),
  remainingDistance: v.optional(v.number()),
  abortReport: v.optional(v.string()),
  abortStamp: v.optional(IsoDateTime),
});

const RobotRouteMatch = v.object({
  edgeId: v.optional(v.number()),
  distanceAlongRoute: v.optional(v.number()),
  distanceToCrossing: v.optional(v.number()),
  onCrossing: v.optional(v.boolean()),
  currentOrUpcomingCrossingId: v.optional(v.number()),
});

const StopState = v.object({
  isBlocked: v.boolean(),
  stoppedSince: v.optional(v.string()),
});

export type Robot = v.InferOutput<typeof Robot>;
export const Robot = v.object({
  id: v.string(),
  displayName: v.optional(v.string()),
  assignedOperationId: v.optional(v.string()),
  readyForOrders: v.optional(v.boolean()),
  accessGroups: v.optional(v.array(v.string()), []),
  pictureUrl: v.optional(v.string()),
  shortName: v.optional(v.string()),
  displayColor: v.optional(v.string()),
  priority: v.optional(v.number()),
  location: Location,
  arrivedAtStop: v.optional(v.boolean()),
  arrivedAtStopManuallySetByUserId: v.optional(v.string()),
  infrastructureTransaction: v.optional(InfrastructureTransaction),
  heading: v.number(),
  velocity: v.number(),
  absoluteTraveledDistance: v.optional(v.number()),
  hazardLightsState: v.optional(v.enum(HazardLightsState)),
  desiredOperatorHazardLightsState: v.optional(v.enum(HazardLightsState)),
  robotView: v.optional(v.string()),
  scheduledStops: v.optional(v.array(ScheduledStop), []),
  assignedOrderIds: v.array(v.string()),
  batteryVoltage: v.optional(v.number()),
  batteryPercentage: v.optional(v.number()),
  isCharging: v.optional(v.boolean()),
  isVirtualRobot: v.optional(v.boolean()),
  stateReport: v.optional(v.string()),
  warningReports: v.optional(v.array(v.string()), []),
  automaticPowerSaving: v.optional(v.boolean()),
  errorReports: v.optional(v.array(v.string()), []),
  attentionReports: v.optional(v.array(v.string()), []),
  orderDetected: v.optional(v.boolean()),
  powerMode: v.optional(v.enum(PowerMode)),
  componentVersions: v.optional(v.array(ComponentVersion), []),
  awxUpdateActive: v.optional(v.boolean()),
  lastAwxUpdateSuccessful: v.optional(v.boolean()),
  integrationUber: v.optional(UberProps),
  controllingRobotOperatorId: v.optional(v.string()),
  connectedRobotOperators: v.optional(v.array(ConnectedRobotOperator), []),
  controlType: v.optional(v.enum(ControlType)),
  serialNumber: v.number(),
  issues: v.optional(v.array(RobotIssue), []),
  highestIssueSeverity: v.optional(v.enum(RobotIssueSeverity)),
  compartments: v.optional(v.array(Compartment), []),
  localizationDataRecordingState: v.optional(
    v.enum(LocalizationDataRecordingState),
  ),
  userClaim: v.nullish(UserClaim),
  stopState: v.optional(StopState),
  gpsCovariance: v.nullish(v.number()),
  jetsonUptime: v.optional(v.number()),
  mappingStatus: v.optional(MappingStatus),
  unsupervisedAutonomyState: v.optional(UnsupervisedAutonomyState),
  operationEmergencyStopActive: v.optional(v.boolean()),
  routeMatch: v.optional(RobotRouteMatch),
  updatedAt: v.optional(IsoDateTime, new Date('1970-01-01')),
  autonomyEnabledUntil: v.optional(IsoDateTime),
  modem1Connected: v.optional(v.boolean()),
  modem2Connected: v.optional(v.boolean()),
  modem3Connected: v.optional(v.boolean()),
  wifiConnected: v.optional(v.boolean()),
});

export function getBatteryHours(batteryPercentage?: number): number {
  return ((batteryPercentage ?? 0) * 14) / 100;
}
