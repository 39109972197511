<div>
  <br />
  <b>Data Upload:</b>
  <br />

  <app-data-upload [robotCommunication]="robotCommunication" />

  <br />
  <br />
  <b>Trigger Service Configuration:</b>

  <app-trigger-service-config [robotCommunication]="robotCommunication">
    Loading...
  </app-trigger-service-config>
</div>
