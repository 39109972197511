import { getRobotVpnIp, InterfaceName } from '@/utils/robot-vpn-ip';
import { Component, inject, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-connectivity-status',
  templateUrl: './connectivity-status.component.html',
  styleUrl: './connectivity-status.component.sass',
  imports: [MatIcon, MatTooltip],
})
export class ConnectivityStatusComponent {
  private snackBar = inject(MatSnackBar);

  serialNumber = input.required<number>();
  modem1Connected = input.required<boolean>();
  modem2Connected = input.required<boolean>();
  modem3Connected = input.required<boolean>();
  wifiConnected = input.required<boolean>();

  readonly lteConnectedIcon = 'signal_cellular_4_bar';
  readonly lteNotConnectedIcon = 'signal_cellular_connected_no_internet_0_bar';
  readonly wifiConnectedIcon = 'wifi';
  readonly wifiNotConnectedIcon = 'wifi_off';

  getVpnIpTooltip(interfaceName: InterfaceName) {
    const ip = getRobotVpnIp(this.serialNumber(), interfaceName);
    return `${ip} (click to copy)`;
  }

  async copyToClipboard(interfaceName: InterfaceName) {
    const ip = getRobotVpnIp(this.serialNumber(), interfaceName);
    await navigator.clipboard.writeText(ip);
    this.snackBar.open('Copied to clipboard!', undefined, { duration: 1000 });
  }
}
