import { Component, inject, input } from '@angular/core';
import { SupervisionSettingsService } from '../supervision-settings/supervision-settings.service';
import { UserSessionEventTrackingService } from '@/app/core/user-session/user-session-event-tracking.service';
import { UserSessionInteractionEventName } from '@/app/core/user-session/user-session-interaction-events';
import { PlaceholderComponent } from '@/app/core/placeholder/placeholder.component';

@Component({
  selector: 'app-robot-placeholder',
  templateUrl: './robot-placeholder.component.html',
  styleUrl: './robot-placeholder.component.sass',
  imports: [PlaceholderComponent],
})
export class RobotPlaceholderComponent {
  private readonly supervisionSettingsService = inject(
    SupervisionSettingsService,
  );
  private readonly userInteractionsTrackingService = inject(
    UserSessionEventTrackingService,
  );

  isEnabled = input.required<boolean>();
  slotId = input.required<number>();

  toggleSlotState() {
    console.log(!this.isEnabled, this.slotId());
    this.supervisionSettingsService.setRobotSlotsByIndex(
      !this.isEnabled(),
      this.slotId(),
    );
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.SUPERVISION_ROBOT_SLOT_CHANGED,
    );
  }
}
