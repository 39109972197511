const networkInterfaceMapping = {
  wlan0: 4,
  modem1: 1,
  modem2: 2,
  modem3: 3,
};

export type InterfaceName = keyof typeof networkInterfaceMapping;

function integerToIp(x: number) {
  const part1 = x >>> 24;
  const part2 = (x >>> 16) & 0xff;
  const part3 = (x >>> 8) & 0xff;
  const part4 = x & 0xff;
  return `${part1}.${part2}.${part3}.${part4}`;
}

export function getRobotVpnIp(
  robot_id: number,
  interfaceName: InterfaceName,
): string {
  const IP_SUBRANGE_SIZE = 32;
  const ROBOT_IP_RANGE_START = 5;
  const part1 = 10;
  const part2 = IP_SUBRANGE_SIZE * (1 + networkInterfaceMapping[interfaceName]); // +1 due to K8S offset (see wiki for details)
  const startIp = (part1 << 24) + (part2 << 16) + (ROBOT_IP_RANGE_START << 8);
  const ip = startIp + robot_id - 1;
  return integerToIp(ip);
}
