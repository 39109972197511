import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ShortcutDialogComponent } from './shortcut-dialog.component';
import { AuthService } from '../auth.service';
import { MatToolbar } from '@angular/material/toolbar';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { ClockingComponent } from '../clocking/clocking.component';
import { AsyncPipe } from '@angular/common';
import { environment } from '@/environments/environment';
import Keycloak from 'keycloak-js';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.sass',
  imports: [
    MatToolbar,
    MatIconButton,
    MatIcon,
    MatMenu,
    MatMenuTrigger,
    MatMenuModule,
    ClockingComponent,
    AsyncPipe,
  ],
})
export class ToolbarComponent {
  private readonly keycloak = inject(Keycloak);
  env = environment;

  constructor(
    public auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  openHomePage() {
    this.router.navigate(['']);
  }

  openProfile() {
    if (this.env.keycloak_only) {
      this.keycloak.accountManagement();
    } else {
      this.router.navigate(['/login']);
    }
  }

  logout() {
    if (this.env.keycloak_only) {
      this.keycloak.logout();
    }
  }

  openShortcuts() {
    this.dialog.open(ShortcutDialogComponent);
  }
}
