<div class="schedule-dialog">
  <h1 mat-dialog-title>Weekly Schedule</h1>
  <div mat-dialog-content>
    <app-weekly-schedule-editor
      [timeZone]="timeZone"
      [maxRobotsInOps]="maxRobotsInOps"
      [(weeklySchedule)]="weeklySchedule"
    />
  </div>
  <div mat-dialog-actions class="schedule-dialog-actions">
    <button mat-button (click)="close()">Cancel</button>
    <button mat-flat-button (click)="save()">Save</button>
  </div>
</div>
