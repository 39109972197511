import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RobotIssueSeverity } from '../../core/robot-issues/robot-issue.types';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormField,
  MatLabel,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { SelectionDropboxComponent } from '../../core/selection-dropbox/selection-dropbox.component';

export type ChargingFilter = 'charging' | 'not-charging';
export type ReadyFilter = 'ready' | 'not-ready';
export type DrivingFilter = 'driving' | 'arrived';
export type OnlineFilter = 'online' | 'offline';
export type UpdatingFilter = 'updating' | 'not-updating';

export interface Filter {
  accessGroup: string | undefined;
  charging: ChargingFilter | undefined;
  driving: DrivingFilter | undefined;
  online: OnlineFilter | undefined;
  operationId: string | undefined;
  ready: ReadyFilter | undefined;
  severity: RobotIssueSeverity | 'NotBreaking' | undefined;
  text: string;
  updating: UpdatingFilter | undefined;
}

@Component({
  selector: 'app-robot-filters',
  templateUrl: './robot-filters.component.html',
  styleUrl: './robot-filters.component.sass',
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
      },
    },
  ],
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    NgClass,
    MatSelect,
    MatOption,
    SelectionDropboxComponent,
  ],
})
export class RobotFiltersComponent {
  @Input() accessGroups!: string[];
  @Input() operationIds!: string[];
  @Output() filterChange = new EventEmitter<Filter>();

  accessGroupFilter?: string;
  chargingFilter?: ChargingFilter;
  drivingFilter?: DrivingFilter;
  onlineFilter?: OnlineFilter;
  operationIdFilter?: string;
  readyFilter?: ReadyFilter;
  severityFilter?: RobotIssueSeverity;
  textFilter = '';
  updatingFilter?: UpdatingFilter;

  severityOptions = [...Object.values(RobotIssueSeverity), 'NotBreaking'];

  applyFilter() {
    this.filterChange.emit({
      accessGroup: this.accessGroupFilter,
      charging: this.chargingFilter,
      driving: this.drivingFilter,
      online: this.onlineFilter,
      operationId: this.operationIdFilter,
      ready: this.readyFilter,
      severity: this.severityFilter,
      text: this.textFilter.trim().toLowerCase(),
      updating: this.updatingFilter,
    });
  }
}
