import { Component, Inject, signal } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Schedule } from '../operation';
import { MatInput } from '@angular/material/input';
import { MatButton, MatFabButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import {
  DEFAULT_SCHEDULE,
  WeeklyScheduleEditorDialogComponent,
} from '../weekly-schedule-editor/weekly-schedule-editor-dialog.component';
import { firstValueFrom } from 'rxjs';
import { nextScheduleInitialName } from './edit-operation-dialog-utils';
import { PartialProperty } from '@/utils/partial-property';
import { freeFormToDashedIdString } from '@/utils/free-form-to-dasched-id-string';
import { adjust, set, lensProp, remove } from 'ramda';

type EditWeeklyScheduleDialogData = {
  timeZone: string;
  schedules?: Schedule[];
};

type ScheduleCandidate = PartialProperty<Schedule, 'id'>;

@Component({
  selector: 'app-edit-schedule-collection-dialog',
  templateUrl: './edit-schedule-collection-dialog.component.html',
  styleUrl: './edit-schedule-collection-dialog.component.sass',
  imports: [MatInput, MatFormField, MatFabButton, MatButton, MatIcon],
})
export class EditScheduleCollectionDialogComponent {
  schedules = signal<ScheduleCandidate[]>([]);
  editIndex = signal<number | undefined>(undefined);

  private currentInput = '';

  private readonly timeZone: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditWeeklyScheduleDialogData,
    private readonly dialogRef: MatDialogRef<EditScheduleCollectionDialogComponent>,
    private matDialog: MatDialog,
  ) {
    this.timeZone = data.timeZone;

    this.schedules.set(data.schedules ?? []);
  }

  save() {
    const schedules = this.schedules();
    this.dialogRef.close({
      schedules: schedules.map((schedule) => ({
        name: schedule.name,
        schedule: schedule.schedule,
        id: schedule.id ?? freeFormToDashedIdString(schedule.name),
      })),
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  appendSchedule() {
    const schedules = this.schedules();
    const name = nextScheduleInitialName(schedules);
    this.schedules.set([
      ...schedules,
      {
        name,
        schedule: structuredClone(DEFAULT_SCHEDULE),
      },
    ]);
    // this ref is updated after the set, so it is actually this.schedules().length - 1
    const editIndex = schedules.length;
    this.editIndex.set(editIndex);
    this.currentInput = name;
    // Add button should lose focus to make the input "autofocus" work
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  inputChange(event: Event) {
    this.currentInput = (event.target as HTMLInputElement).value;
  }

  finishEditing() {
    const editIndex = this.editIndex();
    if (editIndex === undefined) {
      return;
    }
    const schedules = this.schedules();
    const updatedSchedule = adjust(
      editIndex,
      set(lensProp('name'), this.currentInput),
      schedules,
    );

    this.schedules.set(updatedSchedule);
    this.editIndex.set(undefined);
  }

  editSchedule(index: number) {
    this.editIndex.set(index);
    const schedule = this.schedules();
    this.currentInput = schedule[index]?.name ?? '';
  }

  removeSchedule(index: number) {
    const schedules = this.schedules();
    this.schedules.set(remove(index, 1, schedules));
  }

  async editScheduleTimetable(index: number) {
    const schedules = this.schedules();
    const currentSchedule = schedules[index];

    if (!currentSchedule) {
      return;
    }

    const newWeeklyScheduled = await firstValueFrom(
      this.matDialog
        .open(WeeklyScheduleEditorDialogComponent, {
          data: {
            timeZone: this.timeZone,
            weeklySchedule: currentSchedule.schedule,
          },
        })
        .afterClosed(),
    );

    currentSchedule.schedule = newWeeklyScheduled.schedule;
  }
}
