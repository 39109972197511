import {
  Component,
  effect,
  ElementRef,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { MatSelectChange, MatSelect } from '@angular/material/select';

import Leaflet from 'leaflet';
import 'leaflet-providers';
import 'leaflet-rotate';
import {
  LeafletMapStateService,
  MAP_PROVIDERS,
  MapProvider,
} from './leaflet-map-state.service';

import { MatFormField } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';

const DEFAULT_MAP_SETTINGS: Leaflet.MapOptions = {
  attributionControl: false, // hides the provider notice
  center: [0, 0],
  rotateControl: false, // We add this outside of Leaflet
  rotate: true,
  touchRotate: true,
  bearing: 0,
  zoom: 18,
  zoomSnap: 0.25,
  touchZoom: true,
  zoomControl: false, // We add this manually
};

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrl: './leaflet-map.component.sass',
  imports: [MatFormField, MatSelect, MatOption],
})
export class LeafletMapComponent {
  private readonly mapStateService = inject(LeafletMapStateService);
  readonly mapProviders = Object.keys(MAP_PROVIDERS);
  readonly currentProvider = signal(this.mapStateService.getMapProvider());

  readonly shouldShowMapProviderSelection = input(true);
  readonly shouldShowZoomControl = input(true);
  readonly refreshTrigger = input();

  readonly panning = output<void>();
  readonly doubleClick = output<MouseEvent>();
  readonly mapReady = output<Leaflet.Map>();

  private readonly canvasRef =
    viewChild<ElementRef<HTMLDivElement>>('mapCanvas');
  private readonly mapCanvas = signal<Leaflet.Map | undefined>(undefined);

  constructor() {
    this.createMapCanvas();
    this.addMapProvider();
    this.showZoomControl();

    effect(() => {
      this.refreshTrigger();
      this.mapCanvas()?.invalidateSize();
    });
  }

  private createMapCanvas() {
    effect((onCleanup) => {
      const el = this.canvasRef()?.nativeElement;
      if (!el) {
        return;
      }

      const mapCanvas = Leaflet.map(el, DEFAULT_MAP_SETTINGS);
      this.mapCanvas.set(mapCanvas);
      this.mapReady.emit(mapCanvas);
      onCleanup(() => {
        mapCanvas.off();
        mapCanvas.remove();
      });
    });
  }

  private addMapProvider() {
    effect((onCleanup) => {
      const mapCanvas = this.mapCanvas();
      if (!mapCanvas) {
        return;
      }

      const provider = this.currentProvider();
      const providerLayer = Leaflet.tileLayer.provider(MAP_PROVIDERS[provider]);
      mapCanvas.addLayer(providerLayer);
      this.mapStateService.setMapProvider(provider);
      onCleanup(() => {
        mapCanvas.removeLayer(providerLayer);
      });
    });
  }

  private showZoomControl() {
    effect((onCleanup) => {
      const mapCanvas = this.mapCanvas();
      if (!mapCanvas) {
        return;
      }

      if (this.shouldShowZoomControl() === true) {
        const control = Leaflet.control.zoom({ position: 'bottomright' });
        mapCanvas.addControl(control);
        onCleanup(() => {
          mapCanvas.removeControl(control);
        });
      }
    });
  }

  switchProvider(event: MatSelectChange): void {
    const key = event.value as MapProvider;
    this.currentProvider.set(key);
  }

  emitPanning() {
    this.panning.emit();
    this.mapCanvas()?.invalidateSize();
  }

  emitDoubleClick(event: MouseEvent) {
    this.doubleClick.emit(event);
    this.mapCanvas()?.invalidateSize();
  }
}
