import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IntRange } from '@/utils/int-range';
import { UserSessionService } from '../../core/user-session/user-session.service';

const COMPONENT_LOCAL_STORAGE_PREFIX = 'robot-supervision-settings';

const ROBOT_SLOTS_TOKEN = `${COMPONENT_LOCAL_STORAGE_PREFIX}_robot-slots-token`;

export type RobotSlotsConfig = [boolean, boolean, boolean, boolean];
export type RobotSlotIndex = IntRange<0, RobotSlotsConfig['length']>;

const DEFAULT_DESIRED_ROBOT_SLOTS: RobotSlotsConfig = [
  true,
  true,
  false,
  false,
];

function isRobotSlotsConfig(value: unknown): value is RobotSlotsConfig {
  return (
    value instanceof Array &&
    value.length === 4 &&
    value.every((slot) => typeof slot === 'boolean')
  );
}

function countEnabledSlotCount(robotSlotsConfig: RobotSlotsConfig): number {
  return robotSlotsConfig.filter((slot) => slot).length;
}

@Injectable()
export class SupervisionSettingsService {
  private _robotSlots$ = new BehaviorSubject<RobotSlotsConfig>(
    this.getInitialRobotSlots(),
  );

  constructor(private readonly userSessionService: UserSessionService) {
    userSessionService.onConnected$.subscribe((isConnected) => {
      if (isConnected) {
        userSessionService.setEnabledSlotCount(
          countEnabledSlotCount(this._robotSlots$.getValue()),
        );
      }
    });
  }

  readonly robotSlots$ = this._robotSlots$.asObservable();

  setRobotSlotsByIndex(isEnabled: boolean, index: number) {
    const robotSlots = this._robotSlots$.getValue();
    robotSlots[index] = isEnabled;
    this.setRobotSlots(robotSlots);
  }

  private setRobotSlots(robotSlotsConfig: RobotSlotsConfig) {
    localStorage.setItem(ROBOT_SLOTS_TOKEN, JSON.stringify(robotSlotsConfig));
    this._robotSlots$.next(robotSlotsConfig);
    this.userSessionService.setEnabledSlotCount(
      countEnabledSlotCount(robotSlotsConfig),
    );
  }

  private getInitialRobotSlots(): RobotSlotsConfig {
    const robotSlotsConfigString = localStorage.getItem(ROBOT_SLOTS_TOKEN);

    if (robotSlotsConfigString === null) {
      return DEFAULT_DESIRED_ROBOT_SLOTS;
    }

    const robotSlotsConfig = JSON.parse(robotSlotsConfigString);

    if (isRobotSlotsConfig(robotSlotsConfig)) {
      return robotSlotsConfig;
    }

    return DEFAULT_DESIRED_ROBOT_SLOTS;
  }
}
