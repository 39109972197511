import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '@/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.auth.user$.pipe(
      take(1),
      map((user) => {
        const allowedRoles: string[] = route.data['allowedRoles'] || [];
        if (!user) {
          if (environment.keycloak_only) {
            this.auth.signInWithKeycloak();
          } else {
            this.router.navigate(['/login'], {
              queryParams: {
                returnUrl: state.url,
                requiredRoles: allowedRoles,
              },
            });
          }
          return false;
        }

        const hasAccess = allowedRoles.some(
          (allowedRole) =>
            user.roles.includes(allowedRole) || allowedRole == '*',
        );
        if (!hasAccess) {
          if (environment.keycloak_only) {
            this.auth.signInWithKeycloak();
            this.router.navigate(['/'], {
              queryParams: {
                returnUrl: state.url,
                requiredRoles: allowedRoles,
              },
            });
          } else {
            this.router.navigate(['/login'], {
              queryParams: {
                returnUrl: state.url,
                requiredRoles: allowedRoles,
              },
            });
          }
          return false;
        }
        return true;
      }),
    );
  }
}
