import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

export async function finalizeChildren(obj: object) {
  await Promise.all(
    Object.values(obj)
      .filter((child) => child instanceof Finalizable)
      .map((child) => child.finalize()),
  );
}

export abstract class Finalizable {
  private _finalized$ = new ReplaySubject();
  protected finalized = false;
  readonly finalized$ = this._finalized$.pipe(take(1));

  async finalize() {
    try {
      this.finalized = true;
      await this.onFinalize();
      this._finalized$.next(undefined);
    } catch (e) {
      console.error('Failed to finalize', e);
    }
  }

  protected abstract onFinalize(): Promise<void>;

  isFinalized() {
    return this.finalized;
  }
}
