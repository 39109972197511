@if (selectedItem) {
  @if (previewImage !== undefined) {
    <img [src]="previewImage" style="width: 100%" />
  }
  @if (previewVideo !== undefined) {
    <video class="video-preview" controls [src]="previewVideo" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  }
  <app-metadata [item]="metadataItem" />
  @if (snippetFrames.length > 0) {
    <div class="side-bar-img-grid">
      @for (item of snippetFrames; track item; let i = $index) {
        <app-grid-img-item
          [class.selected-img-item]="item.id === metadataItem?.id"
          itemType="frames"
          [item]="item"
          (click)="selectItem.emit(item)"
        />
      }
    </div>
  }
}
