import { BehaviorSubject, Observable, of } from 'rxjs';
import { Finalizable } from '@/utils/finalizable';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { visiblePageTimer } from '@/utils/page-visibility';
import { RtcSendDataChannels } from '../webrtc/rtc-send-data-channels';
import { completeAll } from '@/utils/complete-all';
import { TurnIndicatorState, TurnIndicatorDirection } from '../webrtc/types';
import { RtcStreamManager } from '../webrtc/rtc-stream-manager';

const TURN_INDICATOR_MESSAGE_INTERVAL_MS = 500;

export class LightsManager extends Finalizable {
  private readonly _desiredTurnIndicatorDirection$ =
    new BehaviorSubject<TurnIndicatorDirection>('off');
  readonly desiredTurnIndicatorDirection$ =
    this._desiredTurnIndicatorDirection$.asObservable();
  readonly turnIndicatorState$: Observable<TurnIndicatorState>;

  constructor(
    private readonly rtcStreams: RtcStreamManager,
    rtcSendDataChannels: RtcSendDataChannels,
  ) {
    super();

    this.turnIndicatorState$ = this.rtcStreams
      .getJsonStream<TurnIndicatorState>('turnIndicatorState')
      .pipe(takeUntil(this.finalized$));

    this._desiredTurnIndicatorDirection$
      .pipe(
        switchMap((direction) =>
          direction === 'off'
            ? of(direction)
            : visiblePageTimer(0, TURN_INDICATOR_MESSAGE_INTERVAL_MS).pipe(
                map(() => direction),
              ),
        ),
        takeUntil(this.finalized$),
      )
      .subscribe((direction) => {
        rtcSendDataChannels.sendReliable({
          label: 'turnIndicatorRequest',
          payload: direction,
        });
      });
  }

  requestTurnIndicator(value: TurnIndicatorDirection) {
    this._desiredTurnIndicatorDirection$.next(value);
  }

  protected async onFinalize(): Promise<void> {
    completeAll(this._desiredTurnIndicatorDirection$);
  }
}
