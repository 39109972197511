@if (isOpen()) {
  <app-status-tree [robotCommunication]="robotCommunication()" />
} @else {
  <app-placeholder
    class="optional-status-tree-placeholder"
    iconName="troubleshoot"
    text="Click to open the robot status"
    (click)="isOpen.set(true)"
  />
}
