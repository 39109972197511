<app-deadman-switch-overlay class="deadman-switch-overlay" />

<app-toolbar>
  <ng-content ngProjectAs="menu">
    <button mat-menu-item [routerLink]="''">Home</button>
    <button mat-menu-item (click)="openSupervisionSettings()">Setting</button>
  </ng-content>
</app-toolbar>

@if (!robotCommunicationInFocus) {
  <div class="robot-control-grid">
    @for (slot of robotSlots$ | async; track $index; let i = $index) {
      @if (slot.slotType === 'taken') {
        <app-mini-slot-robot-supervision
          [slotIndex]="i"
          [robotCommunication]="slot.robotCommunication"
          (focusChange)="focusRobotView(slot.robotCommunication)"
        />
      } @else {
        <app-robot-placeholder
          [isEnabled]="slot.slotType === 'empty'"
          [slotId]="i"
        />
      }
    }
  </div>
} @else {
  <app-focus-robot-supervision
    class="focused-robot-supervision"
    [robotCommunication]="robotCommunicationInFocus"
    (focusChange)="unfocusRobotView()"
  />
}
<div
  class="ping-indicator"
  [class.ping-indicator-scale]="((pingCount$ | async) ?? 0) % 2 === 0"
></div>
