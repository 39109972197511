import type { PageFetcher } from './page-fetcher';

export class InOrderPageFetcher<T> implements PageFetcher<T> {
  private page = 0;

  constructor(private fetch: (page: number) => Promise<T[]>) {}

  async step(): Promise<T[]> {
    const snippets = await this.fetch(this.page);
    this.page += 1;
    return snippets;
  }
}
