import { NestedTreeControl } from '@angular/cdk/tree';
import { Component } from '@angular/core';
import {
  MatTreeNestedDataSource,
  MatTree,
  MatTreeNodeDef,
  MatTreeNode,
  MatTreeNodeToggle,
  MatNestedTreeNode,
  MatTreeNodeOutlet,
} from '@angular/material/tree';
import { MatIconButton } from '@angular/material/button';

import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatIcon } from '@angular/material/icon';

export interface TriggerConfigNode {
  name: string;
  path: string;
  val: any;
  type?: string;
  onChange?: (new_value: any) => void;
  childs?: TriggerConfigNode[];
}

@Component({
  selector: 'app-trigger-service-config-tree',
  templateUrl: './trigger-service-config-tree.component.html',
  styleUrl: './trigger-service-config-tree.component.css',
  imports: [
    MatTree,
    MatTreeNodeDef,
    MatTreeNode,
    MatTreeNodeToggle,
    MatIconButton,
    MatFormField,
    MatLabel,
    MatInput,
    MatSlideToggle,
    MatNestedTreeNode,
    MatIcon,
    MatTreeNodeOutlet,
  ],
})
export class TriggerServiceConfigTreeComponent {
  treeControl = new NestedTreeControl<TriggerConfigNode>((node) => node.childs);
  treeDataSource = new MatTreeNestedDataSource<TriggerConfigNode>();
  private expansionMap = new Map<string, boolean>();

  hasChild = (_: number, node: TriggerConfigNode) => {
    return !!node.childs && node.childs.length > 0;
  };

  onChangeNumberField(value: string, node: TriggerConfigNode) {
    node.onChange?.(parseFloat(value));
  }
  onChangeStringField(value: string, node: TriggerConfigNode) {
    node.onChange?.(value);
  }
  onChangeBooleanField(checked: boolean, node: TriggerConfigNode) {
    node.onChange?.(checked);
  }

  loadConfig(config: any, onChange: () => void) {
    if (!config) {
      this.treeControl.dataNodes = [];
      this.treeDataSource.data = [];
      return;
    }

    const nodes = this.parseFields(config, onChange, '');

    this.treeControl.dataNodes = nodes;
    this.treeDataSource.data = nodes;

    for (const node of this.treeControl.dataNodes) {
      this.expandNode(node);
    }
  }

  clearTree() {
    this.treeControl.dataNodes = [];
    this.treeDataSource.data = [];
  }

  private parseFields(
    config: any,
    onChange: () => void,
    currentPath: string,
    ignoreFields: Set<string> = new Set<string>(),
  ): TriggerConfigNode[] {
    const res: TriggerConfigNode[] = [];
    for (const [key, val] of Object.entries(config)) {
      if (ignoreFields.has(key)) {
        continue;
      }
      if (typeof val == 'object' && val && Object.keys(val).length == 0) {
        continue;
      }
      const node: TriggerConfigNode = {
        name: key,
        path: currentPath + '.' + key,
        val: val,
        childs: undefined,
      };

      const type = typeof val;
      if (type == 'object' && !Array.isArray(val)) {
        node.childs = this.parseFields(val, onChange, currentPath + '.' + key);
      } else if (Array.isArray(val)) {
        node.type = 'display_only';
      } else if (type == 'string' || type == 'boolean' || type == 'number') {
        //Is leaf node
        node.type = type;
        node.onChange = (new_value: any) => {
          if (typeof new_value != type) {
            console.warn(
              'TriggerServiceConfig: Encountered assignment of wrong type.',
            );
            return;
          }
          config[key] = new_value;
          node.val = new_value;
          onChange();
        };
      } else {
        console.warn(
          'TriggerServiceConfig: encountered unexpected type: ' + type,
        );
        continue;
      }
      res.push(node);
    }
    return res;
  }

  toggleExpansion(node: TriggerConfigNode) {
    if (this.expansionMap.has(node.path)) {
      this.expansionMap.set(node.path, !this.expansionMap.get(node.path));
    } else {
      this.expansionMap.set(node.path, true);
    }
  }

  private expandNode(node: TriggerConfigNode) {
    if (this.expansionMap.has(node.path) && this.expansionMap.get(node.path)) {
      this.treeControl.expand(node);
      if (node.childs) {
        for (const subNode of node.childs) {
          this.expandNode(subNode);
        }
      }
    }
  }

  stringify(val: any): string {
    return JSON.stringify(val);
  }
}
