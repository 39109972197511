import { Pick, EditHandleFeature, EditHandleType, ModeProps } from './types';
import { Geometry, Point, FeatureWithProps } from './geojson-types';
import { TileLayer } from '@deck.gl/geo-layers';
import { BitmapLayer } from '@deck.gl/layers';
import { Layer, WebMercatorViewport } from '@deck.gl/core';
import { GeoPoint, MapElement, RobotEdge } from '@cartken/map-types';
import { lerp, Matrix4, vec4 } from '@math.gl/core';
import { lerpGeoPoint } from '@/utils/geo-tools';

export type Bounds = [minX: number, minY: number, maxX: number, maxY: number];

const { min, max } = Math;

export function getBounds(coordinates: [GeoPoint, ...GeoPoint[]]): Bounds {
  const bounds: Bounds = [
    coordinates[0][0],
    coordinates[0][1],
    coordinates[0][0],
    coordinates[0][1],
  ];
  for (let i = 1; i < coordinates.length; ++i) {
    const ci = coordinates[i]!;
    bounds[0] = min(bounds[0], ci[0]);
    bounds[1] = min(bounds[1], ci[1]);
    bounds[2] = max(bounds[2], ci[0]);
    bounds[3] = max(bounds[3], ci[1]);
  }

  return bounds;
}

export function createTileLayer(
  urlTemplate: string | string[],
  id: string,
  minZoom = 0,
  maxZoom = 19,
  tileSize = 256,
  opacity = 1,
  boundingPolygon?: [GeoPoint, ...GeoPoint[]],
): Layer {
  // zoom bounded is needed because TileLayer.maxZoom does not prevent
  // the layer from being drawn by itself, it only stops loading finer tiles.
  return new TileLayer({
    id,
    data: urlTemplate,
    minZoom,
    maxZoom,
    tileSize,
    maxRequests: 20, // Concurrent requests to make loading faster.
    extent: boundingPolygon ? getBounds(boundingPolygon) : undefined,
    onTileError() {
      // ignore tile loading errors
    },
    renderSubLayers: (props) => {
      const [min, max] = props.tile.boundingBox;
      return new BitmapLayer(props, {
        data: null as any,
        image: props.data,
        bounds: [min[0]!, min[1]!, max[0]!, max[1]!],
      });
    },
    opacity,
  });
}

export type NearestPointType = FeatureWithProps<
  Point,
  { dist: number; index: number }
>;

//
// a GeoJSON helper function that calls the provided function with
// an argument that is the most deeply-nested array having elements
// that are arrays of primitives as an argument, e.g.
//
// {
//   "type": "MultiPolygon",
//   "coordinates": [
//       [
//           [[30, 20], [45, 40], [10, 40], [30, 20]]
//       ],
//       [
//           [[15, 5], [40, 10], [10, 20], [5, 10], [15, 5]]
//       ]
//   ]
// }
//
// the function would be called on:
//
// [[30, 20], [45, 40], [10, 40], [30, 20]]
//
// and
//
// [[15, 5], [40, 10], [10, 20], [5, 10], [15, 5]]
//
export function recursivelyTraverseNestedArrays<T>(
  array: T[],
  prefix: number[],
  fn: (array: T[], prefix: number[]) => void,
) {
  if (!Array.isArray(array[0])) {
    return true;
  }
  for (let i = 0; i < array.length; i++) {
    // @ts-expect-error hard to explain to TS what's going on
    if (recursivelyTraverseNestedArrays(array[i], [...prefix, i], fn)) {
      fn(array, prefix);
      break;
    }
  }
  return false;
}

export function distance2d(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
): number {
  const dx = x1 - x2;
  const dy = y1 - y2;
  return Math.sqrt(dx * dx + dy * dy);
}

export function mix(a: number, b: number, ratio: number): number {
  return b * ratio + a * (1 - ratio);
}

/**
 * Like WebMercatorViewport.project, but it gives us
 * the W coordinate so we can do perspective
 * correct interpolation.
 */
function project(
  viewport: WebMercatorViewport,
  point: [number, number, number],
) {
  const worldPosition = viewport.projectPosition(point);
  const [x, y, z, w] = vec4.transformMat4(
    [],
    [...worldPosition, 1],
    viewport.pixelProjectionMatrix,
  ) as [number, number, number, number];
  return [x / w, y / w, z / w, w] as const;
}

export function nearestPointOnProjectedLine(
  lineString: GeoPoint[],
  screenCoords: [number, number],
  viewport: WebMercatorViewport,
  modelMatrix: Matrix4,
): NearestPointType | undefined {
  // Project the line to viewport, then find the nearest point
  const projectedCoords = lineString.map(([x, y, z = 0]) =>
    project(
      viewport,
      modelMatrix.transformAsPoint([x, y, z]) as [number, number, number],
    ),
  );

  let minSquaredDistance = Infinity;
  let minIndex = 0;
  let minRatio = 0;

  for (let index = 1; index < projectedCoords.length; ++index) {
    const [x1, y1, _z1, w1] = projectedCoords[index - 1]!;
    const [x2, y2, _z2, w2] = projectedCoords[index]!;
    const dx = x2 - x1;
    const dy = y2 - y1;
    const squaredLength = dx ** 2 + dy ** 2;
    if (squaredLength < 1e-5) {
      continue;
    }

    const [x, y] = screenCoords;
    const ratio = ((x - x1) * dx + (y - y1) * dy) / squaredLength;
    if (ratio < 0 || ratio > 1) {
      continue;
    }
    const perspectiveRatio =
      lerp(0 / w1, 1 / w2, ratio) / lerp(1 / w1, 1 / w2, ratio);

    const x0 = x1 + ratio * dx;
    const y0 = y1 + ratio * dy;
    const squaredDistance = (x - x0) ** 2 + (y - y0) ** 2;
    if (squaredDistance < minSquaredDistance) {
      minSquaredDistance = squaredDistance;
      minIndex = index;
      minRatio = perspectiveRatio;
    }
  }

  const p1 = lineString[minIndex - 1]!;
  const p2 = lineString[minIndex]!;
  const nearestPoint =
    minIndex >= 1 ? lerpGeoPoint(p1, p2, minRatio) : lineString[0];

  if (!nearestPoint) {
    return undefined;
  }

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: nearestPoint,
    },
    properties: {
      // TODO: calculate the distance in proper units
      dist: Math.sqrt(minSquaredDistance),
      index: minIndex - 1,
    },
  };
}

export function getHandlesForPick(pick?: Pick): EditHandleFeature[] {
  const object = pick?.object;
  if (!object || object.geometry.type === 'Point') {
    return [];
  }
  const handles = getEditHandlesForGeometry(
    object.geometry,
    pick.index,
    object.id,
  );
  if (object.geometry.type === 'LineString') {
    handles.splice(0, 1);
    handles.splice(handles.length - 1, 1);
  }
  return handles;
}

export function getUnderlyingFeaturePick(
  pick: Pick | undefined,
  props: ModeProps,
): Pick | undefined {
  if (!pick?.isGuide) {
    return pick;
  }
  const index = pick.object?.properties?.featureIndex;
  if (index === undefined) {
    return undefined;
  }
  const { features } = props.data;
  return { ...pick, object: features[index], index, isGuide: false };
}

export function getPickedEditHandle(
  picks: Pick[],
): EditHandleFeature | undefined {
  const handles = getPickedEditHandles(picks);
  return handles.length ? handles[0] : undefined;
}

export function getPickedExistingEditHandle(
  picks: Pick[],
): EditHandleFeature | undefined {
  const handles = getPickedEditHandles(picks);
  return handles.find(
    ({ properties }) =>
      properties.featureIndex !== undefined &&
      properties.editHandleType === 'existing',
  );
}

export function getPickedIntermediateEditHandle(
  picks: Pick[],
): EditHandleFeature | undefined {
  const handles = getPickedEditHandles(picks);
  return handles.find(
    ({ properties }) =>
      properties.featureIndex !== undefined &&
      properties.editHandleType === 'intermediate',
  );
}

export function getPickedEditHandles(picks: Pick[]): EditHandleFeature[] {
  const handles =
    picks
      .filter(
        (pick) =>
          pick.isGuide && pick.object.properties.guideType === 'editHandle',
      )
      .map((pick) => pick.object) || [];

  return handles;
}

function getEditHandlesForGeometry(
  geometry: Geometry,
  featureIndex: number,
  elementId: number,
  editHandleType: EditHandleType = 'existing',
): EditHandleFeature[] {
  switch (geometry.type) {
    case 'Point':
      // positions are not nested
      return [
        {
          type: 'Feature',
          properties: {
            guideType: 'editHandle',
            editHandleType,
            positionIndexes: [],
            featureIndex,
            elementId,
          },
          geometry: {
            type: 'Point',
            coordinates: geometry.coordinates,
          },
        },
      ];
    case 'MultiPoint':
    case 'LineString':
      // positions are nested 1 level
      return getEditHandlesForCoordinates(
        geometry.coordinates,
        [],
        featureIndex,
        elementId,
        editHandleType,
      );
    case 'Polygon':
    case 'MultiLineString': {
      let handles: EditHandleFeature[] = [];
      // positions are nested 2 levels
      for (let a = 0; a < geometry.coordinates.length; a++) {
        handles = handles.concat(
          getEditHandlesForCoordinates(
            geometry.coordinates[a]!,
            [a],
            featureIndex,
            elementId,
            editHandleType,
          ),
        );
        if (geometry.type === 'Polygon') {
          // Don't repeat the first/last handle for Polygons
          handles = handles.slice(0, -1);
        }
      }
      return handles;
    }
    case 'MultiPolygon': {
      let handles: EditHandleFeature[] = [];
      // positions are nested 3 levels
      for (let a = 0; a < geometry.coordinates.length; a++) {
        for (let b = 0; b < geometry.coordinates[a]!.length; b++) {
          handles = handles.concat(
            getEditHandlesForCoordinates(
              geometry.coordinates[a]![b]!,
              [a, b],
              featureIndex,
              elementId,
              editHandleType,
            ),
          );
          // Don't repeat the first/last handle for Polygons
          handles = handles.slice(0, -1);
        }
      }
      return handles;
    }
    default:
      throw Error(`Unhandled geometry type: ${(geometry as any).type}`);
  }
}

function getEditHandlesForCoordinates(
  coordinates: GeoPoint[],
  positionIndexPrefix: number[],
  featureIndex: number,
  elementId: number,
  editHandleType: EditHandleType = 'existing',
): EditHandleFeature[] {
  const editHandles: EditHandleFeature[] = [];
  for (let i = 0; i < coordinates.length; i++) {
    const position = coordinates[i]!;
    editHandles.push({
      type: 'Feature',
      properties: {
        guideType: 'editHandle',
        positionIndexes: [...positionIndexPrefix, i],
        featureIndex,
        elementId,
        editHandleType,
      },
      geometry: {
        type: 'Point',
        coordinates: position,
      },
    });
  }
  return editHandles;
}

export function isEdgeBlocked(mapElement: MapElement) {
  if (
    !mapElement.properties ||
    !('blockedAt' in mapElement.properties) ||
    mapElement.properties.blockedAt === undefined
  ) {
    return false;
  }
  if (
    !('blockedUntil' in mapElement.properties) ||
    mapElement.properties.blockedUntil === undefined
  ) {
    return true;
  }
  return new Date(mapElement.properties.blockedUntil).getTime() > Date.now();
}

export function isBlockedUntilExpired(
  mapElement: MapElement,
): mapElement is RobotEdge {
  if (
    !mapElement.properties ||
    !('blockedAt' in mapElement.properties) ||
    !mapElement.properties.blockedAt ||
    !('blockedUntil' in mapElement.properties)
  ) {
    return false;
  }
  return new Date(mapElement.properties.blockedUntil!).getTime() <= Date.now();
}
