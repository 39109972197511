<mat-icon
  class="connectivity-icon"
  [matTooltip]="getVpnIpTooltip('modem1')"
  (click)="copyToClipboard('modem1')"
>
  {{ modem1Connected() ? lteConnectedIcon : lteNotConnectedIcon }}
</mat-icon>
<mat-icon
  class="connectivity-icon"
  [matTooltip]="getVpnIpTooltip('modem2')"
  (click)="copyToClipboard('modem2')"
>
  {{ modem2Connected() ? lteConnectedIcon : lteNotConnectedIcon }}
</mat-icon>
<mat-icon
  class="connectivity-icon"
  [matTooltip]="getVpnIpTooltip('modem3')"
  (click)="copyToClipboard('modem3')"
>
  {{ modem3Connected() ? lteConnectedIcon : lteNotConnectedIcon }}
</mat-icon>
<mat-icon
  class="connectivity-icon"
  [matTooltip]="getVpnIpTooltip('wlan0')"
  (click)="copyToClipboard('wlan0')"
>
  {{ wifiConnected() ? wifiConnectedIcon : wifiNotConnectedIcon }}
</mat-icon>
