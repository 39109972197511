import { Subject } from 'rxjs';

import { SignalingConnection } from './signaling-connection';
import { Finalizable } from '@/utils/finalizable';
import { visiblePageTimer } from '@/utils/page-visibility';
import { takeUntil } from 'rxjs/operators';
import { completeAll } from '@/utils/complete-all';
import { SignalingMessage } from './signaling-server-messages';

const STATS_SAMPLING_INTERVAL_MILLIS = 1000;

export class WebRtcPeerConnection extends Finalizable {
  private peerConnectionId = Date.now() % 2e9;
  private negotiationIndex = 0;

  private readonly _dataChannel$ = new Subject<RTCDataChannel>();
  private readonly _videoStream$ = new Subject<MediaStreamTrack>();
  private readonly _robotAudioTrack = new Subject<MediaStreamTrack>();
  private readonly _operatorAudioStream$ = new Subject<MediaStreamTrack>();

  private readonly _stats$ = new Subject<RTCStatsReport>();
  private peerConnection?: RTCPeerConnection;

  // Observables.
  readonly dataChannel$ = this._dataChannel$.asObservable();
  readonly videoStream$ = this._videoStream$.asObservable();
  readonly robotAudioTrack$ = this._robotAudioTrack.asObservable();
  readonly operatorAudioTrack$ = this._operatorAudioStream$.asObservable();

  readonly stats$ = this._stats$.asObservable();

  constructor(readonly signalingConnection: SignalingConnection) {
    super();
    // Setup stats querying every second.
    visiblePageTimer(0, STATS_SAMPLING_INTERVAL_MILLIS)
      .pipe(takeUntil(this.finalized$))
      .subscribe(async (_) => {
        if (
          !this.peerConnection ||
          this.peerConnection.iceConnectionState === 'closed'
        ) {
          return;
        }
        try {
          this._stats$.next(await this.peerConnection.getStats());
        } catch (e) {
          console.warn('Failed to get peer connection stats', e);
        }
      });
  }

  close() {
    this.peerConnection?.close();
    this.peerConnection = undefined;
    this.peerConnectionId = Date.now() % 2e9;
    this.negotiationIndex = 0;
  }

  async startSignaling(
    forceConnectionSwitch: boolean,
    isNewConnection: boolean,
  ) {
    const peerConnection = this.peerConnection;
    if (peerConnection === undefined) {
      return;
    }
    const offer = await peerConnection.createOffer({
      iceRestart: true,
    });
    await peerConnection.setLocalDescription(offer);
    await this.signalingConnection.sendSessionDescription(
      new RTCSessionDescription(offer),
      this.peerConnectionId,
      this.negotiationIndex,
      isNewConnection,
      forceConnectionSwitch,
    );
  }

  async initPeerConnection(): Promise<boolean> {
    this.negotiationIndex++;
    if (this.peerConnection !== undefined) {
      return false;
    }

    const iceServers = await this.signalingConnection.getIceServers();
    this.peerConnection = new RTCPeerConnection({
      iceServers,
      bundlePolicy: 'max-bundle' as RTCBundlePolicy,
      sdpSemantics: 'unified-plan',
    } as RTCConfiguration);
    // Add a dummy data channel so a data channel section is added to the
    // session description (necessary even if the remote creates all the actual
    // data channels).
    this.peerConnection.createDataChannel('');
    // Add video transceivers to accomodate for them in the session description.
    // Having fewer video streams in the end is no problem.
    this.peerConnection.addTransceiver('video');

    this.peerConnection.addTransceiver('audio');

    this.peerConnection.onicecandidate = (
      iceEvent: RTCPeerConnectionIceEvent,
    ) => {
      if (iceEvent.candidate) {
        this.signalingConnection.sendIceCandidate(
          iceEvent.candidate,
          this.peerConnectionId,
          this.negotiationIndex,
        );
      }
    };

    navigator.mediaDevices
      .getUserMedia({
        audio: { echoCancellation: true },
        video: false,
      })
      .then((stream) => {
        const operatorAudioTrack = stream.getTracks()[0];
        if (operatorAudioTrack) {
          operatorAudioTrack.enabled = false;
          this.peerConnection?.addTrack(operatorAudioTrack);
          this._operatorAudioStream$.next(operatorAudioTrack);
        }
      })
      .catch((e) => {
        console.error('Could not get microphone audio', e);
      });

    this.peerConnection.ontrack = (event: RTCTrackEvent) => {
      if (event.track.kind === 'video') {
        this._videoStream$.next(event.track);
      } else if (event.track.kind === 'audio') {
        event.track.enabled = false;
        this._robotAudioTrack.next(event.track);
      }
    };

    this.peerConnection.ondatachannel = (event: RTCDataChannelEvent) => {
      this._dataChannel$.next(event.channel);
    };

    return true;
  }

  async setRemoteSessionDescription(sessionDescription: RTCSessionDescription) {
    if (!this.peerConnection) {
      console.error(
        'Entered onRemoteSessionDescription with undefined peerConnection - should never happen',
      );
      return;
    }
    console.info(`Got ${sessionDescription.type}`);
    await this.peerConnection.setRemoteDescription(sessionDescription);
    if (sessionDescription.type === 'offer') {
      const answer = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(answer);
      await this.signalingConnection.sendSessionDescription(
        new RTCSessionDescription(answer),
        this.peerConnectionId,
        this.negotiationIndex,
        /*createNewRemotePeerConnection=*/ false,
        /*forceConnectionSwitch=*/ false,
      );
      console.info('Sending answer');
    }
  }

  protected async onFinalize(): Promise<void> {
    completeAll(
      this._dataChannel$,
      this._videoStream$,
      this._robotAudioTrack,
      this._stats$,
    );

    this.close();
  }

  async addIceCandidate(iceCandidate: RTCIceCandidate) {
    await this.peerConnection?.addIceCandidate(iceCandidate);
  }

  isPeerConnectionMatch(message: SignalingMessage) {
    return message.peerConnectionId === this.peerConnectionId;
  }

  isNegotiationIndexMatch(message: SignalingMessage) {
    return message.negotiationIndex === this.negotiationIndex;
  }
}
