import { Component } from '@angular/core';
import {
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MapComponent } from '../map/map.component';
import { MatButton } from '@angular/material/button';

@Component({
  templateUrl: './custom-gps-dialog.component.html',
  styleUrl: './custom-gps-dialog.component.sass',
  imports: [MapComponent, MatDialogActions, MatButton, MatDialogClose],
})
export class CustomGpsDialogComponent {
  private map?: google.maps.Map;
  readonly mapOptions: google.maps.MapOptions = {
    center: new google.maps.LatLng(49, 29),
    zoom: 1,
    mapTypeId: google.maps.MapTypeId.HYBRID,
    gestureHandling: 'greedy',
  };
  locationMarker = new google.maps.Marker();
  locationToSend?: google.maps.LatLng;

  constructor(
    private readonly dialogRef: MatDialogRef<CustomGpsDialogComponent>,
  ) {}

  onCancelClick() {
    this.dialogRef.close(undefined);
  }

  onMap(map: google.maps.Map) {
    this.map = map;
    this.map.addListener('click', (e: google.maps.MapMouseEvent) =>
      this.placeMarker(e.latLng, this.map!),
    );
  }

  private placeMarker(latLng: google.maps.LatLng | null, map: google.maps.Map) {
    if (!latLng) {
      return;
    }
    this.locationMarker.setPosition(latLng);
    this.locationMarker.setMap(map);
    this.locationMarker.setDraggable(true);
    map.panTo(latLng);
    this.locationToSend = latLng;
    this.locationMarker.addListener('dragend', () => {
      this.locationToSend = this.locationMarker.getPosition() ?? undefined;
    });
  }
}
