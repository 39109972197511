<app-robot-control
  [robotCommunication]="robotCommunication"
  [highResolution]="active"
  [activeControl]="active"
  [isKeyboardEnabled]="true"
  [isMaintenanceMode]="true"
  [style.aspect-ratio]="'8 / 3'"
/>
@if (active) {
  <app-robot-control-panel
    [robotCommunication]="robotCommunication"
    [active]="active"
  >
    <a mat-raised-button href="/supervise-robots"> Robot Supervision</a>
  </app-robot-control-panel>
}

@if (active && (robotCommunication.robotState$ | async); as robotState) {
  @if (robotState.issues) {
    <app-robot-issue-panel [issues]="robotState.issues" />
  }
}
