<mat-toolbar>
  <div class="button-group">
    <button mat-icon-button (click)="openHomePage()">
      <mat-icon>home</mat-icon>
    </button>

    <mat-menu #appMenu="matMenu">
      <ng-content select="menu" />
    </mat-menu>

    <button mat-icon-button [matMenuTriggerFor]="appMenu">
      <mat-icon>menu</mat-icon>
    </button>

    <ng-content />
  </div>
  <div class="button-group">
    <app-clocking />

    <button mat-icon-button (click)="openShortcuts()">
      <mat-icon>help_outline</mat-icon>
    </button>

    @if (env.keycloak_only) {
      <mat-menu #profileMenu="matMenu">
        <button mat-menu-item (click)="openProfile()">
          <mat-icon>account_circle</mat-icon>
          <span>Profile</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
      <button mat-icon-button [matMenuTriggerFor]="profileMenu">
        <img src="{{ (auth.user$ | async)?.pictureUrl }}" />
      </button>
    } @else {
      <img
        src="{{ (auth.user$ | async)?.pictureUrl }}"
        (click)="openProfile()"
      />
    }
  </div>
</mat-toolbar>
