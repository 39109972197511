import { millisBetween } from '../utils/millis-between';

type ThrottleConfig = {
  minCallIntervalMillis: number;
};

export function Throttle(config: ThrottleConfig) {
  let lastCallTimeDate: Date | undefined;
  return function (
    _target: unknown,
    _key: string | symbol,
    descriptor: PropertyDescriptor,
  ) {
    const method: unknown = descriptor.value;

    if (method instanceof Function) {
      descriptor.value = function (...args: unknown[]) {
        const now = new Date();
        if (
          lastCallTimeDate === undefined ||
          millisBetween(lastCallTimeDate, now) > config.minCallIntervalMillis
        ) {
          method.call(this, ...args);
          lastCallTimeDate = now;
        }
      };
    }
  };
}
