<div class="controls">
  <div class="controls-group">
    <div class="flex-row">
      <mat-form-field>
        <div>
          <mat-label>Assign to</mat-label>

          <mat-select
            (selectionChange)="setAssignedRobot($event.value)"
            [compareWith]="isAssignedRobot"
            [value]="selectedRobot"
          >
            @for (robot of robotUIItems$ | async; track robot) {
              <mat-option [value]="robot">
                {{ robot.displayName }}
              </mat-option>
            }
          </mat-select>
        </div>
      </mat-form-field>
      <mat-slide-toggle [(ngModel)]="isAsapSchedulerMode">
        ASAP
      </mat-slide-toggle>
      <button
        mat-raised-button
        color="primary"
        (click)="reschedule()"
        [disabled]="((order$ | async)?.currentHandoverIndex ?? 0) > 0"
      >
        Reschedule
      </button>
    </div>
  </div>

  <div class="controls-group">
    <div class="flex-row">
      <mat-form-field class="grow" appearance="fill">
        <mat-label>Reason for intervention</mat-label>
        <input
          matInput
          placeholder="Reason for intervention"
          [(ngModel)]="reason"
        />
      </mat-form-field>
    </div>

    <div class="flex-row">
      <button
        mat-raised-button
        color="primary"
        class="grow"
        [disabled]="isFinished$ | async"
        (click)="abort()"
      >
        Abort
      </button>
      <button
        mat-raised-button
        color="primary"
        class="grow"
        [disabled]="isNotYetStarted$ | async"
        (click)="rollbackCurrentState(0)"
      >
        Rollback to pickup
      </button>
      <button
        class="grow"
        mat-raised-button
        color="primary"
        [disabled]="(order$ | async)?.currentHandoverIndex === 0"
        (click)="rollbackCurrentState(1)"
      >
        Rollback to dropoff
      </button>
    </div>
  </div>

  <div class="controls-group">
    <div class="flex-row">
      <button
        class="grow"
        mat-raised-button
        color="primary"
        [disabled]="(isDrivingToHandover$ | async) !== true"
        (click)="arrivedAtHandover()"
      >
        Arrived at handover
      </button>
    </div>

    <div class="flex-row">
      <button
        class="grow"
        mat-raised-button
        color="primary"
        [disabled]="(isWaitingForHandover$ | async) !== true"
        (click)="completeCurrentHandover()"
      >
        Complete handover
      </button>
    </div>

    <div class="flex-row">
      <button
        class="grow"
        mat-raised-button
        color="primary"
        [disabled]="
          (isFinished$ | async) ||
          (order$ | async)?.currentHandoverIndex !== 0 ||
          !(order$ | async)?.assignedRobotId
        "
        (click)="unassign()"
      >
        Unassign
      </button>
    </div>
  </div>
</div>
