@let edge_ = edge();
@let mapElementManager_ = mapElementManager();

<div>
  length:
  {{ edge_.properties.length | number: '1.1-1' }}m &nbsp; nodes:
  <span
    class="select-node-link"
    (click)="mapElementManager_.selectElementById(edge_.properties.startNodeId)"
  >
    {{ edge_.properties.startNodeId }}
  </span>
  :
  <span
    class="select-node-link"
    (click)="mapElementManager_.selectElementById(edge_.properties.endNodeId)"
  >
    {{ edge_.properties.endNodeId }}
  </span>
</div>
<mat-form-field appearance="fill">
  <mat-label>Edge Type</mat-label>
  <mat-select
    [value]="edge_.elementType"
    (selectionChange)="changeElementType(edge_, $event.value)"
    [disabled]="edge_.elementType === 'CachedRoadEdge'"
  >
    @for (edgeType of edgeTypes; track edgeType) {
      <mat-option
        [value]="edgeType.elementType"
        [disabled]="!edgeType.selectable"
      >
        {{ edgeType.displayName }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>

@if (
  (edge_.elementType === 'RoadEdge' ||
    edge_.elementType === 'CachedRoadEdge') &&
  edge_.properties.estimatedDuration
) {
  <span class="mat-body">
    Estimated Duration:
    {{ edge_.properties.estimatedDuration / 60 | number: '1.1-1' }}
    minutes
  </span>
}

@if (
  edge_.elementType === 'RobotEdge' ||
  edge_.elementType === 'InfrastructureEdge' ||
  edge_.elementType === 'InfrastructureWaitingEdge'
) {
  <mat-slide-toggle
    [checked]="edge_.properties.allowUnsupervisedAutonomy"
    (change)="setUnsupervisedAutonomy(edge_, $event.checked)"
  >
    Allow Unsupervised Autonomy
  </mat-slide-toggle>

  <mat-slide-toggle
    [checked]="edge_.properties.blockedAt !== undefined"
    (change)="updateBlockage(edge_, $event.checked)"
  >
    Blocked
    @if (edge_.properties.blockedAt) {
      <span>
        at <app-print-datetime [value]="edge_.properties.blockedAt" />
      </span>
    }
  </mat-slide-toggle>

  <div>
    @if (edge_.properties.blockedUntil) {
      <span>
        Blocked until
        <app-print-datetime [value]="edge_.properties.blockedUntil" />
      </span>
    }
    @if (!edge_.properties.blockedUntil) {
      <span> Block for </span>
    }
  </div>

  <div class="blocked-until-input">
    <mat-form-field appearance="fill">
      <mat-label>Months</mat-label>
      <input
        matInput
        type="number"
        min="0"
        max="2000"
        step="1"
        [value]="blockedFor().months"
        (change)="setBlockedMonths(edge_, $any($event.target).value)"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Days</mat-label>
      <input
        matInput
        type="number"
        min="0"
        max="2000"
        step="1"
        [value]="blockedFor().days"
        (change)="setBlockedDays(edge_, $any($event.target).value)"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Hours</mat-label>
      <input
        matInput
        type="number"
        min="0"
        max="2000"
        step="1"
        [value]="blockedFor().hours"
        (change)="setBlockedHours(edge_, $any($event.target).value)"
      />
    </mat-form-field>
  </div>

  <mat-form-field appearance="fill">
    <mat-label>Slowdown Factor</mat-label>
    <input
      matInput
      type="number"
      placeholder="1"
      min="1"
      [value]="edge_.properties.slowDownFactor"
      (change)="setSlowDownFactor(edge_, $any($event.target).value)"
    />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Max Speed</mat-label>
    <input
      matInput
      type="number"
      placeholder="1.7"
      min="0.1"
      max="2.5"
      step="0.1"
      [value]="edge_.properties.maxSpeed"
      (change)="setMaxSpeed(edge_, $any($event.target).value)"
    />
  </mat-form-field>

  <mat-slide-toggle
    [checked]="edge_.properties.requiresHazardLights"
    (change)="setRequiresHazardLights(edge_, $event.checked)"
  >
    Requires Hazard Lights
  </mat-slide-toggle>

  <mat-slide-toggle
    [checked]="edge_.properties.oneway"
    (change)="setOneway(edge_, $event.checked)"
  >
    Oneway
  </mat-slide-toggle>

  <mat-form-field
    appearance="fill"
    (wheel)="setStartWidthWithWheel(edge_, $event)"
    (pointerleave)="commit()"
  >
    <mat-label>Start Width [m]</mat-label>
    <input
      matInput
      type="number"
      placeholder="1"
      min="0.5"
      step="0.1"
      [value]="edge_.properties.startWidth"
      (input)="setStartWidth(edge_, $any($event.target).value)"
      (change)="commit()"
    />
  </mat-form-field>

  <mat-form-field
    appearance="fill"
    (wheel)="setEndWidthWithWheel(edge_, $event)"
    (pointerleave)="commit()"
  >
    <mat-label>End Width [m]</mat-label>
    <input
      matInput
      type="number"
      placeholder="1"
      min="0.5"
      step="0.1"
      [value]="edge_.properties.endWidth"
      (input)="setEndWidth(edge_, $any($event.target).value)"
      (change)="commit()"
    />
  </mat-form-field>

  <mat-slide-toggle
    [checked]="edge_.properties.crossing !== undefined"
    (change)="setCrossing(edge_, $event.checked)"
  >
    Crossing
  </mat-slide-toggle>

  @if (edge_.properties.crossing) {
    <mat-form-field appearance="fill">
      <mat-label>Crossing Type</mat-label>
      <mat-select
        placeholder="No crossing"
        [value]="edge_.properties.crossing.crosses"
        (selectionChange)="setCrossingType(edge_, $event.value)"
      >
        @for (crossingType of crossingTypes; track crossingType) {
          <mat-option [value]="crossingType.value">
            {{ crossingType.displayName }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <div>
      <mat-slide-toggle
        [checked]="edge_.properties.crossing.buttonPressRequired"
        (change)="setButtonPressRequired(edge_, $event.checked)"
      >
        Button Press Required
      </mat-slide-toggle>
    </div>
  }

  <mat-form-field appearance="fill">
    <mat-label>Mutex Ids</mat-label>
    <mat-select
      [value]="edge_.properties.mutexIds"
      (selectionChange)="setMutexIds(edge_, $event.value)"
      multiple
    >
      @for (mutex of mapElementManager_.mutexes(); track mutex) {
        <mat-option [value]="mutex.id">
          {{ mutex.id }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
}

@if (
  edge_.elementType === 'InfrastructureEdge' ||
  edge_.elementType === 'InfrastructureWaitingEdge'
) {
  <mat-form-field appearance="fill">
    <mat-label>Infrastructure ID</mat-label>
    <mat-select
      [value]="edge_.properties.infrastructureId"
      (selectionChange)="setInfrastructureId(edge_, $event.value)"
    >
      @for (
        infrastructure of mapElementManager_.infrastructure();
        track infrastructure.id
      ) {
        <mat-option [value]="infrastructure.id">
          {{ infrastructure.properties.infrastructureType }}:
          {{ infrastructure.id }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Infrastructure Location Name</mat-label>
    <input
      matInput
      type="text"
      placeholder=""
      [value]="edge_.properties.infrastructureLocationName"
      (input)="setInfrastructureLocationName(edge_, $any($event.target).value)"
    />
  </mat-form-field>
}

@if (edge_.elementType === 'RobotQueueEdge') {
  <div>
    <h3>Slot Priorities</h3>
    <button mat-mini-fab color="primary" (click)="addQueueSlotPriority(edge_)">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  @if (edge_.properties.queueSlotPriorities.length > 0) {
    <div class="robot-queue-edge-priority-note">
      Priority 0 means: robots queue here last.
    </div>
  }
  <ol>
    @for (
      priority of edge_.properties.queueSlotPriorities;
      track $index;
      let i = $index
    ) {
      <li>
        <div class="robot-queue-edge-display-names-list">
          <mat-form-field appearance="fill">
            <mat-label>Add Slot Priority</mat-label>
            <input
              name="a{{ i }}"
              matInput
              type="number"
              placeholder="0"
              min="0"
              [value]="edge_.properties.queueSlotPriorities[i]"
              (change)="
                setQueueSlotPriority(edge_, i, $any($event.target).value)
              "
            />
          </mat-form-field>
          <button
            mat-mini-fab
            color="primary"
            (click)="removeQueueSlotPriority(edge_, i)"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </li>
    }
  </ol>

  <div>
    <h3>Names</h3>
    <input type="text" [(ngModel)]="robotQueueEdgeNameTextInput" />
    <button mat-mini-fab color="primary" (click)="addQueueEdgeName(edge_)">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <ul style="list-style-type: none">
    @for (name of edge_.properties.names; track name; let i = $index) {
      <li>
        <div class="robot-queue-edge-display-names-list">
          <label>{{ name }}</label>
          <button
            mat-mini-fab
            color="primary"
            (click)="removeQueueEdgeName(edge_, i)"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </li>
    }
  </ul>

  <div>
    <h3>Display Names</h3>
    <input type="text" [(ngModel)]="robotQueueEdgeDisplayNameTextInput" />
    <button
      mat-mini-fab
      color="primary"
      (click)="addQueueEdgeDisplayName(edge_)"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <ul style="list-style-type: none">
    @for (
      displayName of edge_.properties.displayNames;
      track displayName;
      let i = $index
    ) {
      <li>
        <div class="robot-queue-edge-display-names-list">
          <label>{{ displayName }}</label>
          <button
            mat-mini-fab
            color="primary"
            (click)="removeQueueEdgeDisplayName(edge_, i)"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </li>
    }
  </ul>
}
