import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { environment } from '../../environments/environment';
import { ReplaySubject } from 'rxjs';
import { routes } from '../app-routing.module';
import type { DisplayableRoute } from '../app-routing.module';
import { User } from '../core/user';
import { ToolbarComponent } from '../core/toolbar/toolbar.component';
import { AsyncPipe } from '@angular/common';
import { MatMenuItem } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatCard, MatCardContent } from '@angular/material/card';

export interface SiteSwitch {
  name: string;
  url: string;
}

function getAccessibleRoutes(
  routes: DisplayableRoute[],
  user?: User,
): DisplayableRoute[] {
  return routes.filter((route) => {
    if (route.displayName === undefined) {
      return false;
    }
    return route.data?.allowedRoles.some(
      (allowedRole) => user?.roles.includes(allowedRole) || allowedRole == '*',
    );
  });
}

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.sass',
  imports: [
    ToolbarComponent,
    MatMenuItem,
    RouterLink,
    MatButton,
    MatIcon,
    MatCard,
    MatCardContent,
    AsyncPipe,
  ],
})
export class LandingPageComponent {
  private auth = inject(AuthService);
  private router = inject(Router);

  switchSiteName = environment.siteSwitch.name;
  accessibleRoutes = new ReplaySubject<DisplayableRoute[]>(1);

  constructor() {
    this.auth.user$.subscribe((user) => {
      this.accessibleRoutes.next(getAccessibleRoutes(routes, user));
      if (user && user.landingPage) {
        this.router.navigate([user.landingPage]);
      }
    });
  }

  switchSite() {
    window.location.href = environment.siteSwitch.url;
  }
}
