<table
  mat-table
  [dataSource]="dataSource"
  [hidden]="dataSource.data.length === 0"
  class="mat-elevation-z8"
  matSort
  matSortActive="start"
  matSortDirection="desc"
  matSortDisableClear
  (matSortChange)="sortData($event)"
>
  <ng-container matColumnDef="robotName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Robot</th>
    <td mat-cell *matCellDef="let element">{{ element.robotName }}</td>
  </ng-container>

  <ng-container matColumnDef="startAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc">
      Start
    </th>
    <td mat-cell *matCellDef="let element">
      <app-print-datetime precision="minutes" [value]="element.startAt" />
    </td>
  </ng-container>

  <ng-container matColumnDef="durationMillis">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
    <td mat-cell *matCellDef="let element">
      {{ element.durationMillis | duration }}
    </td>
  </ng-container>

  <ng-container matColumnDef="trajectoryLength">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Length</th>
    <td mat-cell *matCellDef="let element">{{ element.trajectoryLength }} m</td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        color="primary"
        mat-mini-fab
        (click)="mapRecordingsDataService.downloadContent(element)"
      >
        <mat-icon>download</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    class="element-row"
    [class.empty-polygon]="row.polyline === undefined"
    (click)="emitShowTrajectory(row)"
  ></tr>
</table>
<mat-paginator
  #paginator
  [length]="mapRecordingsDataService.resultsLength$ | async"
  [pageSizeOptions]="pageSizeOptions"
  [pageSize]="mapRecordingsDataService.pageSize$ | async"
  (page)="page($event)"
  aria-label="Select page"
>
</mat-paginator>
