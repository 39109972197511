import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BackendService } from '@/app/core/backend.service';
import { PrettyTimePipe } from '@/app/core/pipes/pretty-time.pipe';
import { HandoverType, Order, OrderStatus } from '@/app/core/order/order';
import { Compartment } from '@/app/core/robots-service/backend/robot.dto';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogData,
  ConfirmationDialogComponent,
} from '../../core/confirmation-dialog/confirmation-dialog.component';
import { retry } from 'rxjs/operators';

import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

const SNACK_BAR_MSG_TIMEOUT = 3000;

export type RobotWithOrders = {
  displayName: string;
  pictureUrl?: string;
  hasArrived: boolean;
  arrivingAtMillis?: number;
  orders: Order[];
  compartments?: Compartment[];
  isBlocked: boolean;
};

const START_ARRIVAL_ALERT_BEFORE_ARRIVAL_MILLIS = 15 * 1000;

@Component({
  selector: 'app-robot-card',
  templateUrl: './robot-card.component.html',
  styleUrl: './robot-card.component.sass',
  imports: [MatIcon, MatButton],
})
export class RobotCardComponent implements OnChanges {
  @Input()
  robot!: RobotWithOrders;

  @Input()
  locationId?: string;

  @Output()
  triggerRefresh = new EventEmitter();

  prettyTimePipe = new PrettyTimePipe();
  arrivalText = '';

  constructor(
    private backendService: BackendService,
    private snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
  ) {}

  ngOnChanges(): void {
    this.updateArrivalStatus();
  }

  private updateArrivalStatus() {
    const compartments = this.robot.compartments ?? [];
    const hasOnlyOneCompartment = compartments.length === 1;
    // Hydrate compartment display names and sort by compartment name if robot has more than one compartment.
    this.robot.orders.forEach(
      (order) =>
        (order.assignedCompartmentId = hasOnlyOneCompartment
          ? ''
          : order.assignedCompartmentId),
    );
    this.robot.orders.sort(
      (a, b) =>
        a.assignedCompartmentId?.localeCompare(b.assignedCompartmentId ?? '') ??
        0,
    );
    this.arrivalText = this.robot.arrivingAtMillis
      ? this.prettyTimePipe.transform(this.robot.arrivingAtMillis)
      : '';
  }

  startArrivingAlert() {
    if (!this.robot.arrivingAtMillis) {
      return false;
    }
    const startAlertTime =
      Date.now() + START_ARRIVAL_ALERT_BEFORE_ARRIVAL_MILLIS;
    return this.robot.arrivingAtMillis < startAlertTime;
  }

  getHandoverAction(order?: Order) {
    const handover = order?.handovers[order.currentHandoverIndex];
    switch (handover?.handoverType) {
      case HandoverType.PICKUP:
        return 'Load';
      case HandoverType.DROPOFF:
        return 'Unload';
      case HandoverType.DISPOSAL:
        return 'Dispose';
      default:
        return 'Complete';
    }
  }

  async completeHandover(orderId: string, requestConfirmation = true) {
    if (requestConfirmation) {
      const confirmed = await this.dialog
        .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(
          ConfirmationDialogComponent,
          {
            data: {
              message: `Order handover completed & compartment securely closed?`,
            },
          },
        )
        .afterClosed()
        .toPromise();

      if (!confirmed) {
        return;
      }
    }
    try {
      await this.backendService
        .post(`/orders/${orderId}/complete-current-handover`, {})
        .toPromise();

      this.triggerRefresh.emit();
    } catch (error) {
      if (error instanceof Error) {
        const message =
          error.message ?? 'Failed to complete handover due to unknown error';
        this.snackBar.open(message, 'hide');
      } else {
        this.snackBar.open(
          'Failed to complete handover due to unknown error',
          'hide',
        );
        console.error(error);
      }
    }
  }

  async completeAllHandovers() {
    if (!this.robot.hasArrived) {
      return;
    }
    const confirmed = await this.dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(
        ConfirmationDialogComponent,
        {
          data: {
            message: `Order handover(s) completed & compartment(s) securely closed?`,
          },
        },
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }

    for (const order of this.robot.orders) {
      if (order.status === OrderStatus.WAITING_FOR_HANDOVER) {
        this.completeHandover(order.id, false).catch(() => {
          // pass?
        });
      }
    }
  }

  completeAllEnabled() {
    return this.robot.orders
      .filter((order) => order.status === OrderStatus.WAITING_FOR_HANDOVER)
      .every((order) => order.compartmentClosed);
  }

  async unlockAllCompartments() {
    if (!this.robot.hasArrived) {
      return;
    }
    await Promise.all(
      this.robot.orders.map((order) => this.openCompartment(order.id)),
    );
  }

  async returnToHandover(orderId: string) {
    const confirmed = await this.dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(
        ConfirmationDialogComponent,
        { data: { message: `Confirm return to handover request.` } },
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }
    this.backendService
      .post(`/orders/${orderId}/return-to-handover`, {})
      .subscribe(
        () => {
          this.triggerRefresh.emit();
        },
        (e) => {
          const message =
            e.error.message ??
            'Failed to return to handover due to unknown error';
          this.snackBar.open(message, 'hide');
        },
      );
  }

  async openCompartment(orderId: string) {
    try {
      await this.backendService
        .post(`/orders/${orderId}/open-compartment`, {})
        .toPromise();
      this.triggerRefresh.emit();
    } catch (error) {
      if (error instanceof Error) {
        const message =
          error.message ?? 'Failed to open compartment due to unknown error';
        this.snackBar.open(message, 'hide');
      } else {
        this.snackBar.open(
          'Failed to open compartment due to unknown error',
          'hide',
        );
        console.error(error);
      }
    }
  }

  closeCompartment(orderId: string) {
    this.backendService
      .post(`/orders/${orderId}/close-compartment`, {})
      .subscribe(
        async () => {
          this.triggerRefresh.emit();
          const order = this.robot.orders.find((order) => order.id === orderId);
          if (order) {
            order.compartmentLocked = true;
          }
          await this.allCompartmentsClosedDialog();
        },
        (e) => {
          const message =
            e.error.message ??
            'Failed to close compartment due to unknown error';
          this.snackBar.open(message, 'hide');
        },
      );
  }

  async allCompartmentsClosedDialog() {
    const compartments = this.robot.compartments ?? [];
    if (
      compartments.length <= 1 ||
      !this.robot.orders.every((order) => order.compartmentLocked)
    ) {
      return;
    }
    await this.dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(
        ConfirmationDialogComponent,
        { data: { message: `Are all compartment securely closed?` } },
      )
      .afterClosed()
      .toPromise();
  }

  async cancelOrder(orderId: string) {
    const confirmationResult = await this.dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(
        ConfirmationDialogComponent,
        { data: { message: `Really cancel order?` } },
      )
      .afterClosed()
      .toPromise();
    if (confirmationResult) {
      this.backendService
        .post(`/orders/${orderId}/cancel`, {
          reason: 'Canceled via tablet UI',
        })
        .pipe(retry(5))
        .subscribe(
          () => {
            this.snackBar.open(`Order ${orderId} is canceled`, undefined, {
              verticalPosition: 'top',
              duration: SNACK_BAR_MSG_TIMEOUT,
            });
            this.triggerRefresh.emit();
          },
          () => {
            this.snackBar.open(`Failed to cancel order ${orderId}`, undefined, {
              verticalPosition: 'top',
              duration: SNACK_BAR_MSG_TIMEOUT,
            });
          },
        );
    }
  }
}
