import { CustomError } from 'ts-custom-error';
import type { RobotOperatorViewObj } from './robot-operators-data';
import { UserEvent } from './user-events.service';

export const PONG_INTERVAL_WATCHDOG_MILLIS = 750;
export const PONG_DEATH_WATCHDOG_MILLIS = PONG_INTERVAL_WATCHDOG_MILLIS * 3;
export const WEBSOCKET_CONNECT_LIMIT = PONG_INTERVAL_WATCHDOG_MILLIS * 5;
export const ROBOT_CONTROL_SESSION_ID_MISMATCH_COUNT_THRESHOLD = 3;
export const SOCKET_CONNECTION_FAILURE_BACKOFF_MILLIS = 25;
export const MAX_RECONNECT_DEBOUNCE_MILLIS = 5000;

export const LOST_CONNECTION_MSG =
  'Lost connection due to a network error, you will not be visible as online';
export const FAILED_ESTABLISH_CONNECTION_MSG =
  'Failed to establish connection, you will not be visible as online';
export const ROBOT_OPERATOR_DATA_FAILED_MSG =
  'Robot Operator data is failed, please contact Cartken dev team';
export const ROBOT_CONTROL_SESSION_ID_MISMATCH_MSG =
  'This connection is stopped since there is newer one. Please, refresh the page to reestablish connection';

export class WebSocketCreationError extends CustomError {
  constructor(message: string) {
    super(`Failed to establish connection due ${message}`);
  }
}

export enum BackendMessageType {
  CHECK_IN = 'CheckIn',
  ROBOTS_ASSIGNED = 'RobotsAssigned',
  PING = 'Ping',
  ROBOT_OPERATOR_DATA = 'RobotOperatorData',
  RESOURCE_UPDATED = 'ResourceUpdated',
  ROBOT_SWAP_REQUESTS = 'RobotSwapRequests',
}

type RobotsAssignedMessage = {
  type: BackendMessageType.ROBOTS_ASSIGNED;
  assignedRobots: string[];
};

type CheckInMessage = {
  type: BackendMessageType.CHECK_IN;
};

export type PingMessage = {
  type: BackendMessageType.PING;
  sessionId: string;
  requestEpoch: number;
};

type RobotOperatorDataMessage = {
  type: BackendMessageType.ROBOT_OPERATOR_DATA;
} & RobotOperatorViewObj;

export type ResourceUpdatedMessage = {
  type: BackendMessageType.RESOURCE_UPDATED;
  resourceType: string;
  resourceId: string;
};

export type RobotSwapRequest = {
  currentRobotId: string;
  newRobotId: string;
};

type RobotSwapRequestsMessage = {
  type: BackendMessageType.ROBOT_SWAP_REQUESTS;
  robotSwapRequests: RobotSwapRequest[];
};

export type BackendMessage =
  | RobotsAssignedMessage
  | CheckInMessage
  | PingMessage
  | RobotOperatorDataMessage
  | ResourceUpdatedMessage
  | RobotSwapRequestsMessage;

export enum ViewName {
  CLASSIC_SUPERVISION = 'ClassicSupervision',
  MANAGED_SUPERVISION = 'ManagedSupervision',
  FOCUSED_SUPERVISION = 'FocusedSupervision',
  IDLE = 'Idle',
}

export enum UserMessageType {
  CHECK_IN = 'CheckIn',
  EVENT = 'Event',
  STATE_UPDATE = 'StateUpdate',
  UNASSIGN_ROBOT = 'UnassignRobot',
  UNASSIGN_OPERATOR = 'UnassignOperator',
  APPLY_ROBOT_SWAP_REQUEST = 'ApplyRobotSwapRequest',
  ACKNOWLEDGE_ROBOTS = 'AcknowledgeRobots',
  ROBOT_CONNECTION_ESTABLISHED = 'RobotConnectionEstablished',
  PONG = 'Pong',
  START_ROBOT_CONTROL = 'StartRobotControl',
  STOP_ROBOT_CONTROL = 'StopRobotControl',
  SELECT_OPERATOR_ACCESS_GROUPS = 'SelectOperatorAccessGroups',
  START_LISTENING_ROBOT_OPERATORS_DATA = 'StartListeningRobotOperatorsData',
  STOP_LISTENING_ROBOT_OPERATORS_DATA = 'StopListeningRobotOperatorsData',
  UPDATE_SUBSCRIBED_ORDERS = 'UpdateSubscribedOrders',
}

export const ROBOT_CONTROL_VIEW_NAME = new Set([
  ViewName.CLASSIC_SUPERVISION,
  ViewName.MANAGED_SUPERVISION,
  ViewName.FOCUSED_SUPERVISION,
]);

type UserLogCheckInResponse = {
  type?: UserMessageType.CHECK_IN;
  robotCount?: number;
  deltaTraveledDistance?: number;
  maxControllingRobotLatencyMillis?: number;
  minControllingRobotLatencyMillis?: number;
  meanControllingRobotLatencyMillis?: number;
  medianControllingRobotLatencyMillis?: number;
  p95ControllingRobotLatencyMillis?: number;
  countControllingRobotLatencyMillis?: number;
};

type UserStateUpdate = {
  viewName?: ViewName;
  enabledRobotSlotCount?: number;
  selectedAccessGroups?: string[];
};

type UserStateUpdateMessage = {
  type: UserMessageType.STATE_UPDATE;
} & UserStateUpdate;

type UserEventMessage = {
  type: UserMessageType.EVENT;
} & UserEvent;

type UnassignRobotUpdate = {
  robotIds: string[];
};

type UnassignRobotMessage = {
  type: UserMessageType.UNASSIGN_ROBOT;
} & UnassignRobotUpdate;

export type UnassignOperatorUpdate = {
  operatorId: string;
  robotIds: string[];
};

export type ApplyRobotSwapRequestUpdate = {
  currentRobotId: string;
  newRobotId: string;
};

export type ApplyRobotSwapRequestMessage = {
  type: UserMessageType.APPLY_ROBOT_SWAP_REQUEST;
} & ApplyRobotSwapRequestUpdate;

export type UnassignOperatorMessage = {
  type: UserMessageType.UNASSIGN_OPERATOR;
} & UnassignOperatorUpdate;

type AcknowledgeRobotsMessage = {
  type: UserMessageType.ACKNOWLEDGE_ROBOTS;
  robotIds: string[];
};

export type RobotConnectionEstablishedMessage = {
  type: UserMessageType.ROBOT_CONNECTION_ESTABLISHED;
  robotId: string;
};

type PongMessage = {
  type: UserMessageType.PONG;
  requestEpoch: number;
};

export type StartRobotControlMessage = {
  type: UserMessageType.START_ROBOT_CONTROL;
};

export type StopRobotControlMessage = {
  type: UserMessageType.STOP_ROBOT_CONTROL;
};

export type SelectOperatorAccessGroupsUpdate = {
  operatorId: string;
  selectedAccessGroups: string[];
};

export type SelectOperatorAccessGroupsMessage = {
  type: UserMessageType.SELECT_OPERATOR_ACCESS_GROUPS;
} & SelectOperatorAccessGroupsUpdate;

export type StartListeningRobotOperatorsDataMessage = {
  type: UserMessageType.START_LISTENING_ROBOT_OPERATORS_DATA;
};

export type StopListeningRobotOperatorsDataMessage = {
  type: UserMessageType.STOP_LISTENING_ROBOT_OPERATORS_DATA;
};

export type UpdateSubscribedOrdersMessage = {
  type: UserMessageType.UPDATE_SUBSCRIBED_ORDERS;
  subscribedOrders: string[];
};

export type UserMessage =
  | UserEventMessage
  | UserLogCheckInResponse
  | UserStateUpdateMessage
  | UnassignRobotMessage
  | ApplyRobotSwapRequestMessage
  | AcknowledgeRobotsMessage
  | RobotConnectionEstablishedMessage
  | PongMessage
  | UnassignOperatorMessage
  | StartRobotControlMessage
  | StopRobotControlMessage
  | SelectOperatorAccessGroupsMessage
  | StartListeningRobotOperatorsDataMessage
  | StopListeningRobotOperatorsDataMessage
  | UpdateSubscribedOrdersMessage;
