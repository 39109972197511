<app-placeholder
  class="robot-slot"
  [class.enabled]="isEnabled()"
  (click)="toggleSlotState()"
  [iconName]="''"
  [text]="
    isEnabled()
      ? 'Looking for a robot to supervise'
      : 'Supervision slot is disabled'
  "
/>
