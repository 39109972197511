import {
  Component,
  EventEmitter,
  inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';
import { isDefined } from '@/utils/typeGuards';
import type { Geometry } from './mapping.component';
import { firstValueFrom } from 'rxjs';
import { MatMiniFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { PrintDatetimeComponent } from '@/app/core/print-datetime/print-datetime.component';
import { DurationPipe } from '@/app/core/pipes/duration.pipe';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  MapRecordingsDataService,
  MapRecordingMetadataDto,
  MapRecordingMetadataDtoSortableKeys,
} from './map-recordings-data.service';
import { AsyncPipe } from '@angular/common';
@Component({
  selector: 'app-map-recordings',
  templateUrl: './map-recordings.component.html',
  styleUrl: './map-recordings.component.sass',
  imports: [
    DurationPipe,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatMiniFabButton,
    MatRow,
    MatRowDef,
    MatSort,
    MatSortHeader,
    MatTable,
    PrintDatetimeComponent,
    MatPaginator,
    AsyncPipe,
  ],
  providers: [MapRecordingsDataService],
})
export class MapRecordingsComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  displayedColumns: string[] = [
    'robotName',
    'startAt',
    'durationMillis',
    'trajectoryLength',
    'buttons',
  ];

  pageSizeOptions: number[] = [10, 25, 100, 250];
  dataSource = new MatTableDataSource<MapRecordingMetadataDto>([]);

  @Output()
  showTrajectory = new EventEmitter<Geometry>();

  readonly mapRecordingsDataService = inject(MapRecordingsDataService);

  ngOnInit(): void {
    this.mapRecordingsDataService.metadataFiles$.subscribe((metadataFiles) => {
      this.dataSource.data = metadataFiles;
      this.showAll();
    });

    this.dataSource.sort = this.sort;
  }

  sortData(sort: Sort) {
    this.mapRecordingsDataService.sortBy$.next(
      sort.active as MapRecordingMetadataDtoSortableKeys,
    );
    this.mapRecordingsDataService.sortDirection$.next(sort.direction || 'desc');
  }

  page(event: PageEvent) {
    this.mapRecordingsDataService.pageIndex$.next(event.pageIndex);
    this.mapRecordingsDataService.pageSize$.next(event.pageSize);
  }

  async showAll() {
    const metadataFiles = await firstValueFrom(
      this.mapRecordingsDataService.metadataFiles$,
    );
    this.showTrajectory.emit({
      polylines: metadataFiles
        .map((file) => file.polyline)
        .filter(isDefined)
        .map((polyline) => ({ polyline, color: 'black' })),
    });
  }

  emitShowTrajectory(file: MapRecordingMetadataDto) {
    if (file.polyline !== undefined) {
      this.showTrajectory.emit({
        polylines: [
          {
            polyline: file.polyline,
            color: 'black',
          },
        ],
      });
    }
  }
}
