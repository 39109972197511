import { BehaviorSubject, Observable, identity, merge, of } from 'rxjs';
import { VideoChannel } from '@/app/core/robots-service/webrtc/types';
import { filter, map, switchMap } from 'rxjs/operators';
import { keyPressed, keyPressedRepeat } from '@/utils/key-pressed';

export class KeyboardManualRobotControl {
  private active$ = new BehaviorSubject<boolean>(false);

  setActive(active: boolean) {
    this.active$.next(active);
  }

  private whileActive<T>(obs: Observable<T>) {
    return this.active$.pipe(switchMap((active) => (active ? obs : of(false))));
  }

  readonly toggleHazardLights$ = this.whileActive(merge(keyPressed('KeyH')));
  readonly zoomControl$ = this.whileActive(keyPressed('KeyZ'));
  readonly enableMicrophone$ = this.whileActive(keyPressed('KeyV'));
  readonly brake$ = this.whileActive(keyPressedRepeat('Space', 100));
  readonly obstacleOverrideControl$ = this.whileActive(
    keyPressedRepeat('KeyO', 100),
  );
  readonly videoChannel$ = this.whileActive(
    // prettier-ignore
    merge(
      keyPressed('Digit1').pipe(filter(identity), map(() => VideoChannel.Default)),
      keyPressed('Digit2').pipe(filter(identity), map(() => VideoChannel.Surround)),
      keyPressed('Digit3').pipe(filter(identity), map(() => VideoChannel.Reverse)),
      keyPressed('Digit4').pipe(filter(identity), map(() => VideoChannel.Front)),
      keyPressed('Digit5').pipe(filter(identity), map(() => VideoChannel._LocalizationDebugColor)),
      keyPressed('Digit6').pipe(filter(identity), map(() => VideoChannel._SemanticSegmentationEncodingFrontImage)),
      keyPressed('Digit7').pipe(filter(identity), map(() => VideoChannel._SemanticSegmentationEncodingLeftImage)),
      keyPressed('Digit8').pipe(filter(identity), map(() => VideoChannel._SemanticSegmentationEncodingRightImage)),
      keyPressed('Digit9').pipe(filter(identity), map(() => VideoChannel._SemanticSegmentationEncodingRearImage)),
    ),
  ).pipe(filter((channel) => typeof channel !== 'boolean'));
}
