<div class="grid-container">
  @for (
    robotCommunication of robotCommunications;
    track $index;
    let i = $index
  ) {
    <div
      (click)="setActiveConnection(i)"
      (mouseenter)="mouseOverConnectionIndex = i"
      (mouseleave)="mouseOverConnectionIndex = undefined"
      [@connectionState]="getConnectionState(i, mouseOverConnectionIndex === i)"
    >
      <app-robot-operator-control
        class="max-100vh"
        [robotCommunication]="robotCommunication"
        [active]="i === activeConnectionIndex && !inhibitActive"
      />
    </div>
  }
</div>

@let activeRobotCommunication_ = activeRobotCommunication();

@if (activeRobotCommunication_) {
  @let isNotInControl =
    (activeRobotCommunication_.isInControl$ | async) !== true;

  <mat-expansion-panel
    (opened)="routingPanelOpen = true"
    (closed)="routingPanelOpen = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Routing</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="flex-container">
        <app-mini-map
          class="map"
          [robotCommunication]="
            routingPanelOpen ? activeRobotCommunication_ : undefined
          "
          (directionsDialogOpen)="onDialogStateChange($event)"
        />
      </div>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="statusPanelOpen = true"
    (closed)="statusPanelOpen = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Robot Status</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div>
        <button
          mat-raised-button
          (click)="activeRobotCommunication_.forceConnectionSwitch()"
        >
          Force Connection Switch
        </button>
        <button
          mat-raised-button
          (click)="activeRobotCommunication_.resetConnection()"
        >
          Reset Connection
        </button>
        <mat-form-field>
          <mat-label>Switch Video Stream</mat-label>
          <mat-select
            [value]="activeRobotCommunication_.videoChannel$ | async"
            (selectionChange)="setVideoChannel($event.value)"
          >
            @for (
              videoChannel of videoChannels | keyvalue;
              track videoChannel.key
            ) {
              <mat-option [value]="videoChannel.value">
                {{ videoChannel.key }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-slide-toggle
          [ngModel]="activeRobotCommunication_.isPowerSaving$ | async"
          (change)="activeRobotCommunication_.powerSaving($event.checked)"
          [disabled]="isNotInControl"
          >Power saving
        </mat-slide-toggle>

        <mat-slide-toggle
          [ngModel]="activeRobotCommunication_.instructorModeActive$ | async"
          (change)="
            activeRobotCommunication_.setInstructorModeActive($event.checked)
          "
          >Instructor Mode
        </mat-slide-toggle>
      </div>
      <div>
        <button
          mat-raised-button
          [disabled]="isNotInControl"
          (click)="activeRobotCommunication_.relocalize()"
        >
          Relocalize
        </button>
        @if (
          activeRobotCommunication_.freeRobotDiskSpace$ | async;
          as freeDiskSpace
        ) {
          <span>
            <button
              mat-raised-button
              [disabled]="toGB(freeDiskSpace) < 5"
              (click)="activeRobotCommunication_.triggerSnapshot()"
            >
              Trigger Snapshot [s] ({{ toGB(freeDiskSpace) | number: '1.1-1' }}
              GB free)
            </button>
          </span>
        }

        @if (isAdmin$ | async) {
          <button
            mat-raised-button
            [disabled]="isNotInControl"
            (click)="activeRobotCommunication_.sendDrivetrainResetRequest()"
          >
            Drivetrain Reset
          </button>
        }

        <button
          mat-raised-button
          [disabled]="isNotInControl"
          (click)="activeRobotCommunication_.sendEstopReleaseRequest()"
        >
          Release Estop
        </button>

        <!-- 'hubReset' label is deprecated, only here for backwards compatability -->
        <button
          mat-raised-button
          [disabled]="isNotInControl"
          (click)="powerCycle()"
        >
          Remote Power Cycle
        </button>

        <button
          mat-raised-button
          [disabled]="isNotInControl"
          (click)="calibrateEndstop()"
        >
          Calibrate Endstops / IMU
        </button>

        @if (
          (activeRobotCommunication_.robotState$ | async)?.orderDetected !==
          undefined
        ) {
          <button
            mat-raised-button
            [disabled]="isNotInControl"
            (click)="activeRobotCommunication_.sendTareScale(true)"
          >
            Tare Scale
          </button>
        }
        <mat-slide-toggle
          [disabled]="isNotInControl"
          (change)="
            activeRobotCommunication_.enableSirenAndAlarm($event.checked)
          "
          color="warn"
          >Siren
        </mat-slide-toggle>

        <mat-slide-toggle
          [disabled]="isNotInControl"
          (change)="activeRobotCommunication_.turnUpHighBeams($event.checked)"
        >
          Turn Up High Beams
        </mat-slide-toggle>
      </div>
      <div>
        <app-status-tree
          fxFlex="100%"
          [robotCommunication]="
            statusPanelOpen ? activeRobotCommunication_ : undefined
          "
        >
          Loading...
        </app-status-tree>
      </div>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="dataCollectionPanelOpen = true"
    (closed)="dataCollectionPanelOpen = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>Data Collection</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      @if (activeRobotCommunication_.freeRobotDiskSpace$ | async) {
        <div>
          @if (
            activeRobotCommunication_.mapDataPreprocessorState$ | async;
            as mapDataPreprocessorState
          ) {
            @if (mapDataPreprocessorState === 'mapping') {
              <button
                mat-raised-button
                (click)="
                  activeRobotCommunication_.mapDataPreprocessorRequest('finish')
                "
              >
                Finish Map Data Collection'
              </button>
            } @else {
              <button
                mat-raised-button
                [disabled]="mapDataPreprocessorState !== 'ready'"
                (click)="
                  activeRobotCommunication_.mapDataPreprocessorRequest('start')
                "
              >
                Record Mapping Data
              </button>
            }
          }
          @if (
            activeRobotCommunication_.freeRobotDiskSpace$ | async;
            as freeDiskSpace
          ) {
            <button
              mat-raised-button
              [disabled]="toGB(freeDiskSpace) < 30"
              (click)="
                activeRobotCommunication_.triggerDataCollectionSnapshot()
              "
            >
              Trigger Data Collection Snapshot [s] ({{
                toGB(freeDiskSpace) | number: '1.1-1'
              }}
              GB free)
            </button>
          }
        </div>
      }

      <br />
      @if (isUserAdmin$ | async) {
        <button
          mat-raised-button
          (click)="
            openDataCollectionConfigurationDialog(activeRobotCommunication_)
          "
        >
          Configure data collection
        </button>
      }
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="joystickPanelOpen = true"
    (closed)="joystickPanelOpen = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Virtual Joystick</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <app-joystick
        fxFlex="auto"
        [robotCommunication]="
          joystickPanelOpen ? activeRobotCommunication_ : undefined
        "
      />
    </ng-template>
  </mat-expansion-panel>
}

@if (!isAnyRobotActive()) {
  <button class="add-button" mat-icon-button (click)="openAddRobotsDialog()">
    <mat-icon>add_task</mat-icon>
  </button>
}
