import { Component, computed, input } from '@angular/core';
import {
  dateToLocalISOStringWithMinutes,
  dateToLocalISOStringWithSeconds,
} from '@/utils/dateToLocalISOString';

type PrintDatetimePrecision = 'seconds' | 'minutes';

const formatFn: Record<PrintDatetimePrecision, (value: Date) => string> = {
  seconds: dateToLocalISOStringWithSeconds,
  minutes: dateToLocalISOStringWithMinutes,
};

/**
 * This component exists because there is no easy way to format
 * datetime using the OS format other than rendering an
 * ```
 * <input type="datetime-local" disabled />
 * ```
 * which uses a native control,
 * then styling it to look like normal text.
 */
@Component({
  selector: 'app-print-datetime',
  standalone: true,
  template: `
    <input
      type="datetime-local"
      disabled
      [value]="formattedValue()"
      [step]="precision() === 'seconds' ? 1 : ''"
    />
  `,
  styles: `
    :host
      display: contents

    input[type="datetime-local"]
      appearance: none
      padding: 0
      border: none
      font: inherit
      color: inherit
      background: none
      overflow: visible
      pointer-events: none

    input[type="datetime-local"]::-webkit-calendar-picker-indicator
      display: none
  `,
})
export class PrintDatetimeComponent {
  value = input.required<Date>();
  precision = input<PrintDatetimePrecision>('seconds');
  formattedValue = computed(() => formatFn[this.precision()](this.value()));
}
